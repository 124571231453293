import {
  createStyles,
  Button,
  FileInput,
  Modal,
  UnstyledButton,
  Group,
  ActionIcon,
  Text,
  Title,
} from "@mantine/core";
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useImperativeHandle,
} from "react";
import {
  IconCirclePlus,
  IconEdit,
  IconExclamationCircle,
} from "@tabler/icons-react";
import toast from "react-hot-toast";
import _ from "lodash";

import { useServerApi } from "../hooks/useServerApi";
import { useFormRender } from "../hooks/useFormRender";
import PageListHeader from "./pageListHeader";
import DataTable from "./dataTable";

import { Page } from "../layout/page";
import { useLocalStorage } from "@mantine/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import JsonViewer from "./common/jsonViewer";

export const ENUM_FORM_DISPLAY = {
  DRAWER: 0,
  MODAL: 1,
  FULLSCREEN: 2,
  INSIDE: 3,
  PAGE: 4,
  LINK: 5,
};

export const ENUM_FORM_LAYOUT_CONTAINER = {
  GRID: 0,
  TABS: 1,
  SECTION: 2,
  WIZARD: 3,
  BOX: 4,
  EMPTY: 5,
};

const handleRegexSpecialChar = (text) => {
  const specialTxt = [
    "\\",
    "^",
    "$",
    ".",
    "|",
    "?",
    "*",
    "+",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
  ];

  let result = text;
  specialTxt.forEach((special) => {
    result = result.replace(special, `\\${special}`);
  });
  return result;
};

export const buildSearchQuery = (searchText, searchFields) => {
  const txt = handleRegexSpecialChar(searchText)?.trim();

  let searchArr = searchFields?.map((field) => ({
    [field]: { $regex: txt, $options: "i" },
  }));
  return { $or: searchArr };
};

const AddActionButton = ({ handleActionClick }) => {
  return (
    <Group justify="right" mb="xl">
      <Button size="xs" variant="default" onClick={() => handleActionClick()}>
        <Group>
          <IconCirclePlus size={"1rem"} />
          Add
        </Group>
      </Button>
      {/* <UnstyledButton onClick={() => handleActionClick()} ml="md">
        <Group gap={"xs"}>
          <ActionIcon>
            <IconCirclePlus size={"1rem"} />
          </ActionIcon>
          <Text size={"xs"}>Add</Text>
        </Group>
      </UnstyledButton> */}
    </Group>
  );
};

export const PageList = React.forwardRef(
  (
    {
      apiEntity,
      title,
      formSchema,
      tableSchema,
      showAddButton = true,
      debug = false,
      hideActionButton = false,
      hideSearchBar = false,
      hidePagination = false,
      showMinAddButton = false,
      stats = null,
      filter = null,
      filterQuery = null,
      customActions = null,
      header = null,
      showImport = false,
      showGoBack = false,
      backRoute = null,
      reloadAfterSave = false,
      preQueryNotEmpty = false,
      preQuery = null,
      onSubmit,
      postSubmit = () => {},
      postDelete = () => {},
      handleCustomAction = (action, data) => {},
      onChange,
      defaultPageSize = "10",
      readOnly = false,
      shadow = 0,
      withPaper = true,
      noDataText = null,
      pt = "xl",
      px = "xl",
      noDataMessage = "No records found.",

      // searchByTextField = false,
      // initSort = { by: "createdAt", order: "desc" },

      tablePadding = "xl",
      tableHeader,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      reload: () => {
        fetchData();
      },
    }));

    const tableRef = useRef();
    const onSubmitSuccess = () => {
      fetchData();
      postSubmit();
    };
    const navigate = useNavigate();
    const [renderForm, formAction, formStatus, formSetting] = useFormRender(
      formSchema,
      onSubmitSuccess,
      onSubmit,
      onChange
    );

    const [rows, setRows] = useState([]);
    const [pagination, setPagination] = useState({
      totalPage: 1,
      page: 1,
      total: 0,
    });

    const location = useLocation();
    const query = new URLSearchParams(useLocation().search);
    const linkId = query.get("id");
    const linkMode = query.get("mode");
    const linkPath = useLocation().pathname;

    const initSort = tableSchema?.initSort ?? {
      by: "createdAt",
      order: "desc",
    };
    const searchByTextField = tableSchema?.searchByTextField ?? false;

    const [pageSize, setPageSize] = useLocalStorage({
      key: "one2work-page-size",
      defaultValue: defaultPageSize,
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [searchQuery, setSearchQuery] = useState({});
    const [openedImport, setOpenedImport] = useState(false);
    const [importFile, setImportFile] = useState(null);
    const [importing, setImporting] = useState(false);
    const [sort, setSort] = useState(initSort);
    const [api] = useServerApi();

    const buildQuery = () => {
      //Have SearchQuery Only
      const queries = [
        tableSchema?.preQuery,
        preQuery,
        searchQuery,
        filterQuery,
      ].filter((f) => !_.isEmpty(f));
      // console.log("buildQuery", queries);
      if (_.isEmpty(queries)) return {};
      return { $and: queries };
    };

    //Fetch Remote Data function
    const fetchData = useCallback(async () => {
      try {
        if (preQueryNotEmpty && _.isEmpty(tableSchema?.preQuery || preQuery)) {
          setRows([]);
          setPagination({
            totalPage: 0,
            page: 1,
            total: 0,
          });
          return;
        }

        const q = buildQuery();
        let data = await api.search({
          apiEntity,
          pageSize: +pageSize,
          currentPage,
          sort: sort.by ? sort : initSort,
          select: tableSchema.select,
          byAggregation: tableSchema.searchByAggregation ?? false,
          searchQuery: q,
          searchText,
          searchByTextField,
          preQuery: tableSchema?.preQuery || preQuery,
        });
        // if (apiEntity === "client") console.log("Page List Fetch Data", q);
        setPagination({
          ...pagination,
          total: data.total,
          totalPage: Math.ceil(data.total / data.limit),
        });
        setRows(data.docs ?? []);
      } catch (error) {
        setRows([]);
        toast.error(`Fetch Error`);
      }
    });

    const onPageChange = useCallback((page) => {
      setCurrentPage(page);
      setPagination({ ...pagination, page });
    });

    const onPageSizeChange = useCallback((pageSize) => {
      console.log("onPageSizeChange", typeof pageSize);
      setCurrentPage(1);
      setPageSize(pageSize);
      setPagination({ ...pagination, page: 1 });
    });

    const onSortChange = useCallback((by, reverseSortDirection) => {
      if (!by) return;
      setSort({ by, order: reverseSortDirection ? "desc" : "asc" });
    });

    const onSearchChange = useCallback((searchText) => {
      let text = searchText;
      if (currentPage > 1) {
        setCurrentPage(1);
        setPagination({ ...pagination, page: 1 });
      }
      setSearchText(text);
    });

    useEffect(() => {
      let query =
        searchText === ""
          ? {}
          : buildSearchQuery(searchText, tableSchema.searchableFields);
      // console.log("query", searchText, tableSchema);
      setSearchQuery(query);
    }, [searchText]);

    useEffect(() => {
      // console.log(sort);
      fetchData();
    }, [
      searchQuery,
      pageSize,
      sort,
      currentPage,
      tableSchema?.preQuery,
      filterQuery,
    ]);

    const copyToNew = async (data) => {
      const newData = { ...data };
      delete newData._id;
      delete newData.createdAt;
      if (newData.code) {
        newData.code += "(copy)";
      }
      let result = await api.add({ apiEntity, values: newData });
      return result?.data;
    };

    const onActionBtnClick = useCallback(async ({ action, data }) => {
      const { name: actionName, linkPath: actionLinkPath } = action;

      switch (actionName) {
        case "show":
          formAction.open({ mode: "show", id: data._id });
          break;
        case "edit":
          // console.log("location edit", `${location.pathname}?id=${data._id}`);
          // formAction.openIdByLink({ mode: "edit", id: data._id });
          formAction.open({ mode: "edit", id: data._id });
          break;
        case "copy":
          const newRecord = await copyToNew(data);
          formAction.open({ mode: "edit", id: newRecord._id });
          break;

        case "openEdit":
          // formAction.open({ mode: "edit", id: data._id });
          window.open(`${actionLinkPath}?id=${data._id}&mode=edit`, "_blank");
          break;

        case "delete":
          // console.log("delete", data._id);
          const confirm = window.confirm(
            `Are you sure to delete the data ${data.code}?`
          );
          if (confirm) {
            await api.removeById({ apiEntity, id: data._id });
            fetchData();
            postDelete();
          }
          break;

        default:
          handleCustomAction(action, data);
      }
    });

    const getTitle = () => {
      if (
        formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
        formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
      )
        return title;
      if (!formStatus.isOpen) return title;
      return formAction.getTitle();
    };

    const showTable = () => {
      // if (formStatus.mode == "add" || formStatus.mode == "edit") return false
      if (!formStatus.isOpen) return true;

      // if (formSetting.displayMode === ENUM_FORM_DISPLAY.MODAL) return false
      if (
        formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
        formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
      )
        return true;

      return false;
    };

    const getPageListAction = () => {
      const actions = [];
      if (showAddButton && !readOnly)
        actions.push({
          label: "Add New",
          onClick: () => formAction.open({ mode: "add" }),
        });

      if (showImport)
        actions.push({
          label: "Import",
          onClick: () => setOpenedImport(true),
          variant: "default",
        });

      if (showGoBack || backRoute) {
        actions.push({
          label: "Close",
          onClick: () => {
            navigate(backRoute ? backRoute : -1);
            formAction.load();
          },
          variant: "default",
        });
      }

      actions.push({
        label: "Refresh",
        onClick: () => fetchData(),
        variant: "default",
      });
      // console.log("actions", actions);
      return actions;
    };

    const getPageFormAction = () => {
      const actions = [];
      if (!readOnly)
        actions.push({ label: "Save", onClick: () => formAction.save() });

      actions.push({
        label: "Close",
        onClick: () => formAction.close(),
        variant: "default",
      });
      return actions;
    };

    const pageActionButtons = () => {
      if (!formStatus.isOpen) return getPageListAction();
      if (
        formSetting.displayMode !== ENUM_FORM_DISPLAY.INSIDE &&
        formSetting.displayMode !== ENUM_FORM_DISPLAY.LINK
      )
        return getPageListAction();

      return getPageFormAction();
    };

    const handleImport = async () => {
      // console.log("handleIMport", importFile);
      try {
        if (!importFile) return;
        setImporting(true);
        const result = await api.importBatch({ apiEntity, file: importFile });
        console.log("Import Result", result);
        if (!result.success) {
          console.log(result);
          throw result.error;
        }
        showNotification({
          title: `${importFile.name} Import successfully`,
          message: "Import Success",
        });
        setOpenedImport(false);
        setImporting(false);

        fetchData();
      } catch (error) {
        console.log(error);
        showNotification({
          title: `${importFile.name} Import fail`,
          icon: <IconExclamationCircle size={18} />,
          color: "red",
          message: error,
        });
        setOpenedImport(false);
        setImporting(false);
        console.log(error);
      }
    };

    // console.log("Page List Render")

    return (
      <>
        {debug && <JsonViewer src={rows} />}
        {/* formode: {formStatus.mode} */}
        <Page shadow={shadow} pt={pt} px={px}>
          <div>
            {showTable() && header && <header.component {...header.props} />}
            {hideActionButton ? null : (
              <PageListHeader
                title={getTitle()}
                actionButtons={pageActionButtons()}
              />
            )}
            {showMinAddButton && (
              <AddActionButton
                handleActionClick={() => {
                  formAction.open({ mode: "add" });
                }}
              />
            )}
            {showTable() && stats && <stats.component {...stats.props} />}
            {showTable() && filter && <filter.component {...filter.props} />}

            {showTable() && (
              <DataTable
                ref={tableRef}
                data={rows}
                columns={tableSchema?.columns}
                pagination={pagination}
                hideSearchBar={hideSearchBar}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onSortChange={onSortChange}
                onSearchChange={onSearchChange}
                onActionBtnClick={onActionBtnClick}
                searchText={searchText}
                padding={tablePadding}
                striped={true}
                stickyHeader={true}
                tableHeader={tableHeader}
                withPaper={withPaper}
                emptyNotShow={true}
                hidePagination={hidePagination}
                // noDataText={noDataText}
              />
            )}
            <Modal
              opened={openedImport}
              onClose={() => setOpenedImport(false)}
              title="Import Data"
              size={"xl"}
            >
              <FileInput
                placeholder="Pick file"
                label="Data File"
                withAsterisk
                onChange={setImportFile}
                disabled={importing}
              />
              <Button
                onClick={handleImport}
                mt="xl"
                size="xs"
                loading={importing}
              >
                Import
              </Button>
            </Modal>
            {renderForm()}
          </div>
        </Page>
      </>
    );
  }
);
