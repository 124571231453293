import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import {
  Menu,
  Group,
  ActionIcon,
  Text,
  Button,
  Checkbox,
  Indicator,
} from "@mantine/core";
import {
  IconDots,
  IconSettings,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconArrowsLeftRight,
  IconCheck,
  IconTrash,
  IconPencil,
} from "@tabler/icons-react";
// const ActionCellRender = (column, rowIndex, data, onActionBtnClick) => {
//   const defaultActionButtons = [
//     {
//       name: "edit",
//       icon: IconPencil,
//       props: {
//         //   color:"red"
//       },
//     },
//     {
//       name: "delete",
//       icon: IconTrash,
//       props: {
//         //   color:"red"
//       },
//     },
//   ];
//   const actionButtons = column.actions ?? defaultActionButtons;

//   return (
//     <Group gap={2}>
//       {/* { JSON.stringify(column)} */}
//       {renderActionButtons(actionButtons, data, onActionBtnClick, rowIndex)}
//       {renderActionMenu(column.actionMenu, data, onActionBtnClick, rowIndex)}
//     </Group>
//   );
// };

export const getEventColor = (type) => {
  switch (type) {
    case "companyEvent":
      return "red";
    case "inTraining":
      return "yellow";
    case "meeting":
      return "orange";
    case "birthday":
      return "cyan";
    default:
      return "blue";
  }
};

export const CalendarEventTableSchema = (cellRender) => {
  return {
    // showActionColumn: true,
    searchableFields: ["title", "description"],

    columns: [
      {
        field: "date",
        sortable: true,
        cellRender: (col, data) => moment(data.date).format("LL"),
      },
      {
        field: "fromTime",
        sortable: false,
        headerName: "From",
        // cellRender: (col, data) =>
        //   !data.fromTime ? "--" : moment(data.fromTime).format("HH:mm"),
      },
      {
        field: "toTime",
        sortable: false,
        headerName: "To",
        // cellRender: (col, data) =>
        //   !data.toTime ? "--" : moment(data.toTime).format("HH:mm"),
      },

      {
        field: "Event",
        sortable: true,
        cellRender: (col, data) => (
          <div>
            <Group>
              <Indicator size={6} color={getEventColor(data.type)} />

              <div>
                <Text size={"xs"} lineClamp={1}>
                  {data.title}
                </Text>
                <Text size={"xs"} color="dimmed">
                  {data.venues}
                </Text>
              </div>
            </Group>
          </div>
        ),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete data",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };
};
