import React from "react";
import { PageList } from "../components/pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";

const Product = ({ title = "Product" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title={title}
      apiEntity={"product"}
      tableSchema={getTableSchema("product")}
      formSchema={getFormSchema("product")}
      showImport={false}
      initSort={{ by: "code", order: "desc" }}
      searchByTextField={false}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default Product;
