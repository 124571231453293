import React, { useEffect } from "react";

import {
  Text,
  Group,
  InputWrapper,
  NumberInput,
  Divider,
  Title,
} from "@mantine/core";
import _ from "lodash";
import { useCellRender } from "../hooks/useCellRender";

const OrderDetail = ({ form, name, showInput = true }) => {
  const { productSnapshot, product, discount, total, netTotal, payment } =
    form.values;
  const [cellRender] = useCellRender();

  useEffect(() => {
    if (!product || !productSnapshot) return;
    //Update total
    if (!discount) form.setFieldValue("discount", 0);
    form.setFieldValue("total", productSnapshot.price);
    form.setFieldValue("netTotal", productSnapshot.price - discount);
    form.setFieldValue("payment", productSnapshot.price - discount);
  }, [product, discount]);

  return (
    <>
      {/* <JsonViewer src={form.values} /> */}

      {_.isEmpty(productSnapshot) && (
        <Text size="md">Please select a product</Text>
      )}

      {!_.isEmpty(productSnapshot) && (
        <>
          <Title order={5} mb="md">
            {productSnapshot.name}
          </Title>

          <Group justify="space-between">
            <InputWrapper label="Price" />
            <Text size="md">{cellRender.Currency(total)}</Text>
          </Group>
          <Group justify="space-between">
            <InputWrapper label="Credit Need" />
            <Text size="md">{productSnapshot.creditNeed}</Text>
          </Group>
          <Divider my="xs" />

          <Group justify="space-between">
            <InputWrapper label="Discount" />
            <NumberInput
              {...form.getInputProps("discount")}
              size="xs"
              prefix="$"
              precision={2}
              thousandSeparator=","
            />
          </Group>
          <Group justify="space-between">
            <InputWrapper label="Total Payment" />
            <Text size="md">{cellRender.Currency(netTotal)}</Text>
          </Group>

          {/* </Card> */}
        </>
      )}
    </>
  );
};

export default OrderDetail;
