import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";

import {
  AppShell,
  Header,
  Footer,
  Aside,
  Text,
  ScrollArea,
  Burger,
  useMantineTheme,
  Group,
  TextInput,
  Box,
  Switch,
  useComputedColorScheme,
} from "@mantine/core";
import { useColorScheme, useDisclosure } from "@mantine/hooks";
import NavBar from "../components/navBar";

import React from "react";

export default function Layout() {
  const [opened, { toggle }] = useDisclosure();
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme();
  return (
    <AppShell
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
      bg={theme.background[colorScheme]}
    >
      <AppShell.Header hiddenFrom="sm" mb={"xl"}>
        <Group h={45}>
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar>
        <NavBar opened={opened} onBurgerClick={toggle} />
      </AppShell.Navbar>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
