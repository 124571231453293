import { Select, TextInput, Textarea } from "@mantine/core";
import { ENUM_FORM_DISPLAY } from "../../components/pageList";
import AutoCodeInput from "../../components/autoCodeInput";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";

export const ItemCategoryFormSchema = {
  title: "Item Category",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "itemCategory",

  initialValues: {
    code: "",
    name: "",
    cname: "",
    unitsOfMeasure: "",
  },

  layout: {
    fields: [
      //   {
      //     name: "code",
      //     component: AutoCodeInput,
      //     props: {
      //       required: true,
      //       label: "Code",
      //       placeholder: "",
      //     },
      //   },
      {
        name: "name",
        component: TextInput,
        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },
      {
        name: "unitsOfMeasure",
        component: Select,
        props: {
          required: true,
          label: "Unit of Measure",
          placeholder: "",
          data: DATA_UNIT_OF_MEASURE,
          searchable: true,
          clearable: true,
        },
      },

      {
        name: "description",
        component: Textarea,
        props: {
          // required: true,
          label: "Description",
          placeholder: "",
          mb: "xl",
        },
      },
    ],
  },
};
