import React from "react";
import { FormSectionCard } from "../layout/page";
import { Title, Group, Card, Divider, Text } from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import InfoRowList from "./infoRowList";
import InfoGridList from "./infoGridList";
import moment from "moment";
import { IconDotsVertical } from "@tabler/icons-react";
import _ from "lodash";

function QuotationProjectDetail({ form, title, shadow = "md" }) {
  const [cellRender] = useCellRender();

  const project = _.get(form.values, "project") || "";

  const fields = [
    {
      title: "Code",
      value: project?.code,
    },
    { title: "Client", value: project?.client?.name },
    { title: "Project Status", value: project?.status },
    { title: "Event Type", value: project?.projectType?.name },
    // { title: "Date", value: moment(project?.duration.start).format("ll") },
    // { title: "Time", value: moment(project?.duration.start).format("LT") },
  ];
  return (
    <>
      <Text size="xs" tt={"uppercase"} mb={"xs"}>
        {title}
      </Text>
      <Card shadow={shadow}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group justify="space-between">
            <Text size="sm" fw={500}>
              {project.name}
            </Text>
            <IconDotsVertical size={12} />
          </Group>
        </Card.Section>
        <Card.Section inheritPadding py="md">
          <InfoRowList fields={fields} values={project} cols={2} />
        </Card.Section>
      </Card>
    </>
  );
}

export default QuotationProjectDetail;
