import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
  Menu,
  Popover,
  Modal,
  ScrollArea,
} from "@mantine/core";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import {
  IconDeviceFloppy,
  IconDownload,
  IconSeparatorVertical,
} from "@tabler/icons-react";
import { IconDotsVertical } from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import TemplateSaveLoadButtons from "./templateSaveLoadButtons";

const calculateTotal = (charges) => {
  if (_.isEmpty(charges)) return 0;
  charges.forEach((c) => {
    c.price = (c.qty ?? 1) * c.unitPrice;
  });
  const total = charges.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0);
  return +total.toFixed(2);
};

const QuotationCostItems = ({ form, name }) => {
  const costItems = _.get(form.values, "costItems");
  const [total, setTotal] = useState(0);
  const [formatter] = useFormatter();

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: Textarea,
      props: {
        autosize: true,
        minRows: 1,
      },
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },

    {
      header: "Unit Price",
      headerWidth: "20%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
    {
      header: "Price",
      headerWidth: "20%",
      fieldName: "price",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const total = calculateTotal(charges);
    setTotal(total);
  };

  useEffect(() => {
    onChargeChange(costItems);
  }, [costItems]);

  useEffect(() => {
    form.setFieldValue("costTotal", total);
  }, [total]);

  const loadFromTemplate = (template) => {
    // console.log("loadFromTemplate", template?.content);
    form.setFieldValue("costItems", template?.content);
  };

  const loadProuction = () => {};

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}

      <Group justify="right" mb="xl" gap={5}>
        <TemplateSaveLoadButtons
          templateCategory="costItem"
          onLoadTemplate={loadFromTemplate}
          currentContent={costItems}
        />
        <Button variant="default" size="xs" onClick={loadProuction}>
          Load Production
        </Button>
      </Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
      <Divider my="xl" />
      <Group justify="right">
        <div>
          <Title order={3}>{formatter.currency(total)}</Title>
          <Text size="xs" c={"dimmed"}>
            Total Cost
          </Text>
        </div>
      </Group>
    </>
  );
};

export default QuotationCostItems;
