import { Button, Select } from "@mantine/core";
const themeObject = {
  /** Put your mantine theme override here */
  // fontFamily: "Open Sans, sans-serif",
  primaryColor: "cyan",
  black: "#000",
  white: "#fff",
  background: {
    dark: "#101114",
    light: "#f7f8f8",
  },
  navbar: {
    background: {
      dark: "black",
      light: "white",
    },
  },

  components: {
    Select: Select.extend({
      defaultProps: {
        checkIconPosition: "right",
        // variant: "outline",
      },
    }),
  },

  //   components: {
  //     Button: Button.extend({
  //       defaultProps: {
  //         color: "orange",
  //         // variant: "outline",
  //       },
  //     }),
  //   },
};

export default themeObject;
