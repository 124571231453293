import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../hooks/useServerApi";
import { DatePicker, DateInput } from "@mantine/dates";

import { DATA_REPORT_DATE_TYPE } from "../data/options";

const ReportOrder = ({}) => {
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "month").toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [dateType, setDateType] = useState("submissionDate");
  const [loading, setLoading] = useState(false);

  const [api] = useServerApi();

  const downloadReport = async () => {
    try {
      setLoading(true);
      // console.log(fromDate, toDate);
      const result = await api.Order.getReport({ fromDate, toDate });
      setLoading(false);
      // console.log(result);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Text size="xs">Please select the period of records</Text>
      <Group mt="xs">
        <DateInput
          title="From"
          size="xs"
          allowFreeInput
          placeholder="From"
          onChange={(d) => setFromDate(d)}
          value={fromDate}
        />
        -
        <DateInput
          title="To"
          size="xs"
          allowFreeInput
          placeholder="To"
          onChange={(d) => setToDate(d)}
          value={toDate}
        />
        {/* <Text size="xs">by</Text> */}
        {/* <Select
          size="xs"
          defaultValue={"submissionDate"}
          data={DATA_REPORT_DATE_TYPE}
          onChange={(type) => setDateType(type)}
        /> */}
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        Download
      </Button>
      <></>
    </div>
  );
};

export default ReportOrder;
