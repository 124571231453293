import React from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  Container,
  Group,
  Grid,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useServerApi } from "../hooks/useServerApi";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconCircleCheck } from "@tabler/icons-react";
import {
  useSignIn,
  useIsAuthenticated,
  useSignOut,
  useAuthUser,
} from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { FormPage, FormSectionCard, useFormNotification } from "../layout/page";

import _ from "lodash";

const ResetPassword = () => {
  const title = "Settings > Password";
  const [api] = useServerApi();
  const signOut = useSignOut();
  const auth = useAuthUser();
  const [notifyFormSuccess, notifyFormError] = useFormNotification();

  const form = useForm({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
    },

    validate: {
      oldPassword: (value) =>
        value === "" ? "Please fill in existing password" : null,

      newPassword: (value) =>
        value?.length < 5 ? "Password length must larger than 5" : null,

      newPassword2: (value, values) =>
        value !== values.newPassword ? "Confirm password not match" : null,
    },
  });

  const handleSubmit = async () => {
    // console.log("Submit", form.values);
    try {
      const validation = form.validate();
      if (validation.hasErrors) return;

      const result = await api.User.changePassword({
        ...form.values,
        id: auth().id,
      });

      const { success, error } = result;

      if (!success) {
        return notifyFormError({
          title: "Change Password Fail",
          message: error,
        });
      }

      signOut();
      notifyFormSuccess({
        title: "Change Password Success",
        message: "Your account will be logout.",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const actions = () => {
    return (
      <Group>
        <Button onClick={handleSubmit}>Save</Button>
      </Group>
    );
  };

  return (
    <FormPage title={title} actions={actions}>
      <FormSectionCard title={"Reset your password"}>
        <PasswordInput
          label="Old Password"
          placeholder="Your old password"
          required
          mt="md"
          variant="filled"
          sx={{ border: 0 }}
          {...form.getInputProps("oldPassword")}
        />

        <PasswordInput
          label="New Password"
          placeholder="Your new password"
          required
          mt="md"
          variant="filled"
          {...form.getInputProps("newPassword")}
        />
        <PasswordInput
          label="Confirm Password "
          placeholder="Your new password"
          required
          mt="md"
          mb="md"
          variant="filled"
          {...form.getInputProps("newPassword2")}
        />
      </FormSectionCard>
    </FormPage>
  );
};

export default ResetPassword;
