import _ from "lodash";
import { Group, Select, NumberInput, TextInput, Checkbox } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";

const OrderOperationRow = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();

  const d = _.get(form.values, `${name}.${index}.date`);
  const checked = _.get(form.values, `${name}.${index}.showToClient`);
  return (
    <Group wrap="noWrap" grow>
      <TextInput
        {...form.getInputProps(`${name}.${index}.content`)}
        placeholder="Operation Content"
      />
      <DateInput
        {...form.getInputProps(`${name}.${index}.date`)}
        value={d ? new Date(d) : null}
        placeholder="Date"
        type="dateInput"
      />
      <Checkbox
        {...form.getInputProps(`${name}.${index}.showToClient`)}
        checked={checked}
        onChange={(event) =>
          form.setFieldValue(
            `${name}.${index}.showToClient`,
            event.currentTarget.checked
          )
        }
        label="Show to Client"
      />
    </Group>
  );
};

export default OrderOperationRow;
