import _ from "lodash";
import { ActionIcon } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { useServerApi } from "../hooks/useServerApi";
import { useFormSchema } from "../hooks/useFormSchema";
import { useCellRender } from "../hooks/useCellRender";
import { IconExternalLink } from "@tabler/icons-react";
import { PageList } from "../components/pageList";
import ActionCellRender from "../components/actionCellRender";

const Credit = ({}) => {
  const [cellRender] = useCellRender();
  const tableSchema = {
    showActionColumn: false,
    searchableFields: ["name", "code"],

    columns: [
      {
        field: "status",
        headerName: "Status",
        sortable: true,
      },
      {
        field: "orderCode",
        headerName: "Order Code",
        sortable: true,
        // cellRender: (col, row) => cellRender.Order.code(row),
      },
      {
        field: "client",
        headerName: "To",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.client),
      },
      {
        field: "type",
        headerName: "Type",
        sortable: true,
      },
      {
        field: "order.client",
        headerName: "From",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.order?.client),
      },
      {
        field: "credit",
        headerName: "Credit",
        sortable: true,
        cellRender: (col, row) => row.amount,
      },
      {
        field: "level",
        headerName: "Level",
        sortable: true,
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title="Credit"
      apiEntity="credit"
      tableSchema={tableSchema}
      formSchema={getFormSchema("credit")}
      hideSearchBar={false}
      hideActionButton={false}
      withPaper={true}
    />
  );
};

export default Credit;
