import React from "react";
import _ from "lodash";
import { Group, Select, NumberInput } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";

const PromotionRuleRow = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();

  return (
    <Group wrap="noWrap" grow>
      <RemoteSelect2
        label="To Position"
        apiEntity="position"
        labelField="name"
        valueField="_id"
        labelRender={(data) => `${data.name}`}
        multiple={false}
        searchFields={["code name cname"]}
        placeholder="Next Position"
        clearable={true}
        value={item.content["toPosition"]}
        onDataChange={(data) => {
          item.content["toPosition"] = data?._id;
          forceUpdate();
        }}
        sort={{
          by: "name",
          order: "asc",
        }}
      />
      <Select
        {...form.getInputProps(`${name}.${index}.type`)}
        label="Type"
        placeholder="Select Rule Type"
        data={[
          { value: "ReferalByCount", label: "Total Referal (Count)" },
          { value: "PurchaseByAmount", label: "Purchased Amount" },
        ]}
        // required
      />
      <RemoteSelect2
        label="Product"
        apiEntity="product"
        labelField="name"
        valueField="_id"
        labelRender={(data) => `${data.name}`}
        multiple={false}
        searchFields={["code name cname"]}
        placeholder="Product"
        clearable={true}
        value={item.content["product"]}
        onDataChange={(data) => {
          item.content["product"] = data?._id;
          forceUpdate();
        }}
        sort={{
          by: "name",
          order: "asc",
        }}
      />
      <NumberInput
        {...form.getInputProps(`${name}.${index}.count`)}
        label="Count"
        placeholder="Enter Quantity"
        required
      />
    </Group>
  );
};

export default PromotionRuleRow;
