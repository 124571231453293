import React from "react";
import ReactJson from "react-json-view";

const JsonViewer = ({ src, name, collpased = true }) => {
  return (
    <ReactJson
      name={name}
      src={src}
      style={{ background: "white" }}
      collapsed={collpased}
    ></ReactJson>
  );
};

export default JsonViewer;
