import { createTheme, MantineProvider, Button, Paper } from "@mantine/core";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import { Notifications } from "@mantine/notifications";

import { routes } from "./data/routes";
import { APIProvider, Map } from "@vis.gl/react-google-maps";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

import themeObject from "./themeObject";

const theme = createTheme(themeObject);
const authName = `${process.env.REACT_APP_AUTH_NAME}`;

const App = () => {
  //Public
  const publicRoutes = routes.filter((l) => !l.private);

  //Backend
  const privateRoutes = routes.filter((l) => l.private && !l.isClientRoute);
  const defaultHomeRoute = routes.find(
    (l) => l.isDefaultHome && !l.isClientRoute
  );
  const loginRoute = routes.find((l) => l.isLoginRoute && !l.isClientRoute);

  return (
    <MantineProvider theme={theme} defaultColorScheme="light">
      <AuthProvider authType={"localstorage"} authName={authName}>
        <Notifications />
        <BrowserRouter>
          <Routes>
            {
              //Public Routes
              publicRoutes.map((r, index) => (
                <Route
                  key={"route-public" + index}
                  index
                  path={r.link}
                  element={r.element}
                />
              ))
            }

            <Route
              path="/"
              key="route-home"
              element={
                <RequireAuth loginPath={loginRoute.link}>
                  <Layout />
                </RequireAuth>
              }
            >
              {
                //Default Home Page
                <Route
                  key="route-default"
                  index
                  path="/"
                  element={defaultHomeRoute?.element}
                />
              }
              {
                //Private Route
                privateRoutes.map((r, index) => (
                  <Route
                    key={"route-private" + index}
                    index
                    path={r.link}
                    element={r.element}
                  />
                ))
              }
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </MantineProvider>
  );
};

export default App;
