import React, { useState, useEffect } from "react";
import { useServerApi } from "../hooks/useServerApi";
import UsersAvatar from "./usersAvatar";
import { IconCirclePlus, IconSettings } from "@tabler/icons-react";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { Textarea } from "@mantine/core";
import FunctionList from "./APIRouteList";
import {
  UnstyledButton,
  TextInput,
  Paper,
  Group,
  Title,
  Text,
  Container,
  Button,
  Stack,
  SimpleGrid,
} from "@mantine/core";

import FormConfirmation from "./formConfirmation";
import { useFormSchema } from "../hooks/useFormSchema";

const UserRoleCard = ({ role, handleEditUserRole }) => {
  return (
    <UnstyledButton>
      <Paper
        radius="md"
        p="md"
        key={role._id}
        shadow="md"
        style={{ minHeight: 40 }}
      >
        <Stack align="flex-start" justify="space-between">
          <Group justify="space-between" style={{ width: "100%" }}>
            <div>
              <Text color={"dimmed"} size="xs">
                Total {role.count} users
              </Text>
              <Text weight={500} size="md">
                {role.name}
              </Text>
            </div>
            <UsersAvatar users={role.users} maxDisplayUser={4} size={35} />
          </Group>

          <Group justify="space-between" style={{ width: "100%" }}>
            <Button
              size="xs"
              variant="subtle"
              p={0}
              onClick={() => handleEditUserRole(role._id)}
            >
              Edit Role
            </Button>
          </Group>
        </Stack>
      </Paper>
    </UnstyledButton>
  );
};

const RoleList = ({ roles, width, handleEditUserRole }) => {
  return (
    <SimpleGrid
      cols={4}
      spacing="lg"
      breakpoints={[
        { maxWidth: "md", cols: 3, spacing: "md" },
        { maxWidth: "sm", cols: 2, spacing: "sm" },
        { maxWidth: "xs", cols: 1, spacing: "sm" },
      ]}
    >
      {roles.map((role) => (
        <UserRoleCard
          key={role._id}
          role={role}
          handleEditUserRole={handleEditUserRole}
        />
      ))}
      {/* <AddCard /> */}
    </SimpleGrid>
  );
};

const fectchAllUserRoles = async (api) => {
  let result = await api.search({
    apiEntity: "userRole",
    pageSize: 100,
    currentPage: 1,
    sort: { by: "name", order: "asc" },
  });
  return result?.docs ?? [];
};

const UserRoleList = ({ width = 300, title, ...props }) => {
  const [api] = useServerApi();
  const [roles, setRoles] = useState([]);
  const [getFormSchema] = useFormSchema();
  const apiEntity = "userRole";

  const description =
    "A role provide access to predefined menus and features so that depending on assigned role an administrator can have access to what he need";

  const fetchData = async () => {
    try {
      let userRoles = await fectchAllUserRoles(api);
      console.log("userRoles", userRoles);
      let data = await api.User.getListGroupByRole();
      // console.log("User Role List", data);
      userRoles.forEach((role) => {
        let r = data.find((dr) => dr._id === role._id);
        role.count = r ? r.count : 0;
        role.users = r ? r.users : [];
      });
      setRoles(userRoles);
    } catch (error) {
      console.log(error);
    }

    // console.log("User Role List", userRoles);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmitSuccess = ({ values }) => {
    fetchData();
  };

  const onSubmit = async (data) => {
    console.log("onSubmit userRole list ", data);
    const { formMode, values } = data;
    if (formMode == "add") await api.add({ apiEntity, values });
    else await api.updateById({ apiEntity, values, id: values._id });
    formAction.close();
    fetchData();
  };

  const formSchema = getFormSchema("userRole");
  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess,
    onSubmit
  );

  const handleAddUser = () => {
    formAction.open({ mode: "add" });
  };

  const handleEditUserRole = (id) => {
    formAction.open({ mode: "edit", id });
  };

  return (
    <>
      {renderForm()}
      <div>
        <div style={{ marginBottom: 20 }}>
          <Title>{title}</Title>
          <Text color={"dimmed"} size="xs">
            {description}
          </Text>
        </div>

        {roles && (
          <RoleList
            roles={roles}
            width={width}
            handleEditUserRole={handleEditUserRole}
          />
        )}

        <Button size="xs" variant="subtle" onClick={handleAddUser}>
          <IconCirclePlus size={15} />
          New User Role
        </Button>
      </div>
    </>
  );
};

export default UserRoleList;
