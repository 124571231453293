import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Switch,
  InputWrapper,
} from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import remoteSelect2 from "../../components/remoteSelect2";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";
import ActivityLog from "../../components/activityLog";
import FileList from "../../components/fileList";
import FormConfirmation from "../../components/formConfirmation";
import { IconSettings } from "@tabler/icons-react";
import PayrollStat from "../../components/payrollStat";
import PayrollTable from "../../components/payrollTable";

const apiEntity = "payroll";
const initialValues = {
  status: "PENDING",
  type: "NONPAYBACK",
};

const addFormSchema = {
  title: "New Payroll",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
  },
  showSaveBar: false,

  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          {
            key: "t1-1",
            label: "Basic Info",
            icon: IconSettings,
          },
          // { key: "t1-2", label: "Import Data", icon: IconSettings },

          { key: "t1-3", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-info",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Payroll Info",
          withPaper: false,
          description: "Please fill in the basic information of the payroll",
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Confirm",
          description: "Please confirm the statement details",
          withPaper: false,
          withBorder: false,
          ml: 20,
          mt: 0,
        },
      },
    ],

    fields: [
      {
        name: "code",
        component: AutoCodeInput,
        parent: "section-info",
        props: {
          required: true,
          placeholder: "PR-202212",
          label: "Patch ID",
        },
      },

      {
        name: "importRemark",
        component: Textarea,
        parent: "section-info",
        props: {
          placeholder: "",
          label: "Remark",
        },
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {
          successText: ["Please confirm if to create the new payroll."],
        },
      },

      // {
      //     name: "rows",
      //     component: PayrollImporter,
      //     parent: "section-import",
      //     props: {},
      // },
    ],
  },
};

const editFormSchema = {
  title: "Payroll",
  titleField: "code",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Payroll Details",
        },
        tab: [
          {
            key: "tab1",
            label: "Payroll Details",
            icon: IconSettings,
          },

          {
            key: "tab2",
            label: "Files",
            icon: IconSettings,
          },

          {
            key: "tab3",
            label: "Logs",
            icon: IconSettings,
          },
        ],
      },
      {
        key: "section-files",
        parent: "tab2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-payroll",
        parent: "tab1",
        type: ENUM_FORM_LAYOUT_CONTAINER.BOX,
        props: {
          mt: 50,
        },
      },

      {
        key: "section-log",
        parent: "tab3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Activity Log",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: 0,

          mt: 20,
          mb: 20,
        },
      },
    ],

    fields: [
      {
        name: "payrollStat",
        parent: "section-payroll",
        component: PayrollStat,
        props: {
          cols: 3,
        },
      },
      {
        name: "commissions",
        parent: "section-payroll",
        component: PayrollTable,
        props: {
          payrollType: "NONPAYBACK",
        },
      },

      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },

      {
        name: "logs",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
    ],
  },
};

export const PayrollFormSchema = {
  add: addFormSchema,
  edit: editFormSchema,
};
