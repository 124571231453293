import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const PositionTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "name",
        headerName: "Name",
        sortable: true,
      },
      {
        field: "level",
        headerName: "Level",
        sortable: true,
      },
      // {
      //   field: "upperPosition",
      //   headerName: "Upper Position",
      //   sortable: true,

      //   cellRender: (col, row) => {
      //     return row.upperPosition ? row.upperPosition.name : "";
      //   },
      // },
      // {
      //   field: "description",
      //   headerName: "Description",
      //   sortable: true,
      // },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
    searchableFields: ["name"],
  };
};
