import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import RemarkList from "../../components/remarkList";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog";
import QuotationProjectDetail from "../../components/quotationProjectDetail";
import Terms from "../../components/terms";
import { DateInput } from "@mantine/dates";
import remoteSelect2 from "../../components/remoteSelect2";
import {
  InputWrapper,
  TextInput,
  Textarea,
  Text,
  Box,
  Group,
} from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import QuotationProjectSection from "../../components/quotationProjectSection";
import InvoiceActionList from "../../components/invoiceActionList";
import DummyWidget from "../../components/dummyWidget";
import { useCellRender } from "../../hooks/useCellRender";
import InvoiceItems from "../../components/invoiceItems";
import moment from "moment";
import InvoicePaymentHistory from "../../components/invoicePaymentHistory";
import PaymentHistory from "../../components/paymentHistory";
const initialValues = {};

const apiEntity = "invoice";

export const InvoiceAddForm = {
  name: "addSchema",
  title: "New Invoice",
  description: "Please follow the step to create a New Invoice",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
  },

  apiEntity,
  initialValues,

  layout: {
    containers: [],
    fields: [],
  },
};

export const InvoiceEditForm = {
  name: "editSchema",
  title: "Edit Invoice",
  titleField: "code",
  description: "Please follow the step to edit invoice",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },

  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "main-g",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "main-g1",
            props: {
              span: 3,
            },
          },
          {
            key: "main-g2",
            props: {
              span: 9,
            },
          },
        ],
      },
      {
        key: "main-tab",
        parent: "main-g2",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
          },
          {
            key: "tab-file",
            label: "Files",
          },
          {
            key: "tab-log",
            label: "Logs",
          },
        ],
      },

      {
        key: "section-quotation-basic",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Baisc Information",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-project-info",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Project Information",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-client-info",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Client Information",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "section-detail",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Details",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "section-payments",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Payments",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "detail-tab",
        parent: "section-quotation-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basic-g1",
            props: {
              span: 4,
            },
          },
          {
            key: "basic-g2",
            props: {
              span: 4,
            },
          },
          {
            key: "basic-g3",
            props: {
              span: 4,
            },
          },
        ],
      },
      {
        key: "client-tab",
        parent: "section-client-info",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "client-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "client-g2",
            props: {
              span: 6,
            },
          },
        ],
      },
      {
        key: "section-term",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Terms",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-remarks",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Remarks",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-file",
        parent: "tab-file",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "project",
        component: QuotationProjectDetail,
        parent: "main-g1",
        props: {
          title: "Project Details",
        },
      },
      {
        name: "code",
        component: DummyWidget,
        parent: "basic-g1",
        props: {
          dummyRender: (data, cellRender) => {
            return (
              <InputWrapper label="Status">
                <Group align="center">
                  <Text>{data.code}</Text>
                  <Box>{cellRender.Invoice.status(data?.status)}</Box>
                </Group>
              </InputWrapper>
            );
          },
        },
      },
      {
        name: "invDate",
        component: DateInput,
        parent: "basic-g2",
        type: "dateInput",
        props: {
          label: "Invoice Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: new Date(),
        },
      },
      {
        name: "dueDate",
        component: DateInput,
        parent: "basic-g3",
        type: "dateInput",
        props: {
          label: "Due Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: moment().add(14, "days").toDate(),
        },
      },
      {
        name: "client",
        component: remoteSelect2,
        parent: "client-g1",
        props: {
          label: "Client",
          apiEntity: "client",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "cname", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
      {
        name: "attn",
        component: TextInput,
        parent: "client-g2",
        props: {
          label: "Attn",
        },
      },
      {
        name: "tel",
        component: TextInput,
        parent: "client-g1",
        props: {
          label: "Tel",
        },
      },
      {
        name: "email",
        component: TextInput,
        parent: "client-g2",
        props: {
          label: "Email",
        },
      },
      {
        name: "fax",
        component: TextInput,
        parent: "client-g1",
        props: {
          label: "Fax",
        },
      },
      {
        name: "address",
        component: Textarea,
        parent: "client-g2",
        props: {
          label: "Address",
          autosize: true,
          minRows: 1,
        },
      },

      {
        name: "project",
        component: QuotationProjectSection,
        parent: "section-project-info",
        props: {},
      },
      {
        name: "rows",
        component: InvoiceItems,
        parent: "section-detail",
        props: {},
      },
      {
        name: "payments",
        component: PaymentHistory,
        parent: "section-payments",
        props: {},
      },

      {
        name: "terms",
        component: Terms,
        parent: "section-term",
        props: {
          templateCategory: "Invoice Terms",
        },
      },
      {
        name: "remarks",
        component: RemarkList,
        parent: "section-remarks",
      },

      {
        name: "files",
        component: FileList,
        parent: "section-file",
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
      },
      {
        name: "actions",
        component: InvoiceActionList,
        parent: "main-g1",
        props: {},
      },
    ],
  },
};
