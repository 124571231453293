import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const ProductTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },
      {
        field: "category",
        headerName: "Category",
        sortable: true,
        cellRender: (col, row) => {
          return row.category ? row.category.name : "";
        },
      },

      {
        field: "name",
        headerName: "Name",
        sortable: true,
      },

      {
        field: "price",
        headerName: "Price",
        sortable: true,
        cellRender: (col, row) => cellRender.Currency(row.price),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, row) => cellRender.Status(row.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],

    searchableFields: ["name", "code", "category.name", "cname"],
  };
};
