import React, { useState } from "react";
import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useServerApi } from "../hooks/useServerApi";
import { Button, Group } from "@mantine/core";
import ProductionItemListRow from "./productionItemListRow";
import OrderList from "./orderList";

const getProductionItems = (sections) => {
  const items = [];
  sections?.forEach((section) => {
    section.itemGroups.forEach((group) => {
      items.push(...group.itemList);
    });
  });
  return items;
};

const ItemSummaryList = ({ form }) => {
  const itemList = _.get(form.values, "itemList") || [];

  const copyItemsFromProduction = () => {
    const productionSections = form.values.productionSections || [];
    //Group Items with _id and sum up quantity
    const groupedItems = _.chain(getProductionItems(productionSections))
      .groupBy("item")
      .map((items, _id) => ({
        item: _id,
        quantity: _.sumBy(items, "quantity"),
        isProduction: true,
      }))
      .value();
    form.setFieldValue("itemList", [...groupedItems]);
  };

  return (
    <>
      {/* <JsonViewer src={form.values} /> */}
      <Group justify="right" mb="xl">
        <Button
          onClick={() => copyItemsFromProduction()}
          size="xs"
          variant="default"
        >
          Copy Production
        </Button>
      </Group>

      <OrderList
        form={form}
        name={`itemList`}
        row={{ component: ProductionItemListRow }}
        addLabel="Add Item"
      />
    </>
  );
};

export default ItemSummaryList;
