import React, { useEffect, useState } from "react";

import {
  Text,
  TextInput,
  Select,
  Checkbox,
  Button,
  Group,
  InputWrapper,
  NumberInput,
  Divider,
  Title,
  Card,
  Textarea,
} from "@mantine/core";

import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import { useCellRender } from "../hooks/useCellRender";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import { DATA_PAYMENT_METHODS } from "../data/options";

const OrderPayment = ({
  form,
  name,
  showInput = true,
  title = "Payment Details",
  showDivider = true,
  showPayLater = true,
  showDiscount = false,
  showStatus = false,
  rowMb = "0",
}) => {
  const {
    productSnapshot,
    product,
    discount,
    total,
    netTotal,
    payment,
    payLater,
    client,
    clientSnapshot,
  } = form.values;
  const [cellRender] = useCellRender();

  useEffect(() => {
    if (!product || !productSnapshot) return;
    //Update total
    if (!discount) form.setFieldValue("discount", 0);
    form.setFieldValue("total", productSnapshot.price);
    form.setFieldValue("netTotal", productSnapshot.price - discount);
    // form.setFieldValue("payment", productSnapshot.price - discount);
    if (payLater) {
      form.setFieldValue("payment", 0);
      form.setFieldValue("payLater", true);
    } else {
      form.setFieldValue("payment", productSnapshot.price - discount);
      form.setFieldValue("payLater", false);
    }
  }, [product, discount, payLater]);

  const bundleProducts =
    product?.bundleProducts ?? productSnapshot?.bundleProducts ?? [];

  const activeCredit =
    form.values.client?.activeCredit ??
    form.values.clientSnapshot?.activeCredit ??
    0;
  return (
    <>
      {/* <JsonViewer src={form.values} /> */}

      {_.isEmpty(productSnapshot) && (
        <Text size="md">Please select a product</Text>
      )}

      {!_.isEmpty(productSnapshot) && (
        <>
          {title && (
            <Title order={5} mb="md">
              Payment Detail
            </Title>
          )}

          <Group justify="space-between" mb={rowMb}>
            <InputWrapper label="Product" />
            <Text size="md"> {productSnapshot.name}</Text>
          </Group>

          {bundleProducts?.map((p, index) => (
            <Group justify="space-between" mb={rowMb}>
              <InputWrapper label={`\t- Bundle ${index + 1}`} />
              <Text size="md" c="dimmed">
                {p.product.name} x {p.quantity}
              </Text>
            </Group>
          ))}

          {showDiscount && (
            <Group justify="space-between" mb={rowMb}>
              <InputWrapper label="Total" />
              <Text>{cellRender.Currency(total)}</Text>
            </Group>
          )}

          {showDiscount && (
            <Group justify="space-between" mb={rowMb}>
              <InputWrapper label="Discount" />
              <NumberInput
                {...form.getInputProps("discount")}
                size="xs"
                prefix="$"
                precision={2}
                thousandSeparator=","
              />
            </Group>
          )}

          {showDivider && <Divider my="md" />}
          <Group justify="space-between" mb={rowMb}>
            <InputWrapper label="Net Total" />
            <Text size="md">{cellRender.Currency(netTotal)}</Text>
          </Group>
          {productSnapshot?.creditNeed > 0 && (
            <Group justify="space-between" mb={rowMb}>
              <InputWrapper label="Credit Need" />
              <Text
                size="md"
                c={activeCredit < productSnapshot?.creditNeed ? "red" : ""}
              >
                {` ${cellRender.NumberFormat(
                  productSnapshot?.creditNeed
                )} / ${cellRender.NumberFormat(activeCredit)}`}
              </Text>
            </Group>
          )}
          {/* <Card title="Payment" bg="#FDFDFE"> */}
          <Group justify="space-between" mb={rowMb}>
            <InputWrapper label="Payment Received" />
            <Text size="md">{cellRender.Currency(payment)}</Text>
          </Group>

          <Group justify="space-between" mb={rowMb}>
            <InputWrapper label="Payment Method" />
            <Select
              {...form.getInputProps("paymentMethod")}
              size="xs"
              data={DATA_PAYMENT_METHODS}
            />
          </Group>

          <Group justify="space-between" mb={rowMb} mt="5">
            <InputWrapper label="Payment Date" />
            <DateInput
              type="dateInput"
              size="xs"
              value={new Date(form.values?.paymentDate ?? null)}
              onChange={(date) => {
                form.setFieldValue("paymentDate", date);
              }}
              // {...form.getInputProps("paymentDate")}
            />
          </Group>

          {showPayLater && (
            <Group justify="right" mb={rowMb}>
              <Checkbox
                label="Pay Later"
                mt="md"
                checked={form.values.payLater}
                onChange={(e) => {
                  form.setFieldValue("payLater", e.target.checked);
                }}
              />
            </Group>
          )}
          {/* </Card> */}
        </>
      )}
    </>
  );
};

export default OrderPayment;
