import React, { useRef } from "react";
import {
  TextInput,
  Grid,
  Select,
  Group,
  Checkbox,
  NumberInput,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { DATA_ITEM_ASSIGN_METHOD } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

export const RundownRow = ({ item }) => {
  const forceUpdate = useForceUpdate();
  const remoteRef = useRef(null);
  useDeepCompareEffect(() => {
    // console.log("item changed", item);
    forceUpdate();
    remoteRef.current?.refresh();
  }, [item]);
  return (
    <Group>
      <TimeInput
        placeholder="Input placeholder"
        value={item.content["start"]}
        onChange={(e) => {
          item.content["start"] = e.target.value;
          forceUpdate();
        }}
      />
      <TimeInput
        placeholder="Input placeholder"
        value={item.content["end"]}
        onChange={(e) => {
          item.content["end"] = e.target.value;
          forceUpdate();
        }}
      />
      <TextInput
        value={item.content["title"]}
        placeholder="Title"
        miw={275}
        onChange={(e) => {
          item.content["title"] = e.currentTarget.value;
          forceUpdate();
        }}
      />
      <RemoteSelect2
        ref={remoteRef}
        apiEntity="user"
        labelField="name"
        valueField="_id"
        labelRender={(data) => `${data.aliasName} (${data.staffId ?? " -- "})`}
        multiple={false}
        searchFields={["name", "aliasName", "staffId"]}
        placeholder="Staff"
        clearable={true}
        value={item.content["user"]}
        onDataChange={(data) => {
          console.log("data", data);
          item.content["user"] = data ?? [];
          forceUpdate();
        }}
        sort={{
          by: "name",
          order: "asc",
        }}
      />
    </Group>
  );
};
