import React from "react";
import { PageList } from "../components/pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";

const Payroll = ({ title = "Payroll" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title={title}
      apiEntity={"payroll"}
      tableSchema={getTableSchema("payroll")}
      formSchema={getFormSchema("payroll")}
      preQuery={{ type: "NONPAYBACK" }}
      showImport={false}
      initSort={{ by: "code", order: "desc" }}
      searchByTextField={false}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default Payroll;
