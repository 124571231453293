import React, { useState, useEffect } from "react";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";

import {
  Button,
  Table,
  Group,
  Text,
  Chip,
  Space,
  Title,
  Grid,
  Card,
  Divider,
  Tabs,
  Badge,
  LoadingOverlay,
  Menu,
  Switch,
  Stack,
  Center,
  useComputedColorScheme,
} from "@mantine/core";

import { DateInput } from "@mantine/dates";

import { useCellRender } from "../hooks/useCellRender";

import {
  IconPhoto,
  IconPrinter,
  IconCameraSelfie,
  IconFile,
  IconUser,
  IconBuildingSkyscraper,
  IconAlertCircle,
  IconAlertTriangle,
  IconExclamationMark,
  IconCoin,
  IconRefresh,
} from "@tabler/icons-react";
import { Accordion, useMantineTheme } from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import { useServerApi } from "../hooks/useServerApi";
import { useForm } from "@mantine/form";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import JsonViewer from "./common/jsonViewer";

const RowHeader = ({ payment }) => {
  const { commissions, client, total } = payment ?? {};

  const [cellRender] = useCellRender();
  const [formatter] = useFormatter();

  return (
    <>
      {/* <JsonViewer src={payment} /> */}
      {payment && (
        <Grid justify="space-between">
          <Grid.Col span={3}>
            <>{client && <div>{cellRender.Client(client)}</div>}</>
          </Grid.Col>
          <Grid.Col span={3}>
            <Stack align={"flex-start"} spacing="xs">
              <Title order={3}>{commissions.length}</Title>
              <Text size={"xs"} color="dimmed">
                Total Commissions
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={3}>
            {client && (
              <div>
                <Title order={3}>
                  {(client?.bankInfo?.bankName ?? "") +
                    "- " +
                    cellRender.Mask(client?.bankInfo?.bankAccount, "*", 3, 4)}
                </Title>
                <Text size={"xs"} color="dimmed">
                  Bank Account
                </Text>
              </div>
            )}
          </Grid.Col>
          <Grid.Col span={3}>
            <div>
              <Title order={3}>{formatter.currency(total)}</Title>

              <Text size={"xs"} color="dimmed">
                Total Payoll Amount
              </Text>
            </div>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

const RowContent = ({ payment }) => {
  const { commissions } = payment;
  const [cellRender] = useCellRender();
  return (
    <>
      <Divider
        mt={"xl"}
        mb={"xl"}
        my="xs"
        label="Commission Details"
        labelPosition="center"
        variant="dashed"
      />
      <Table fontSize={"sm"} striped highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Order Code</Table.Th>
            <Table.Th>Commission Code</Table.Th>
            <Table.Th>Level</Table.Th>
            <Table.Th>Amount</Table.Th>
            <Table.Th>Status</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {commissions &&
            commissions.map((b, index) => (
              <Table.Tr key={index}>
                <Table.Td>{index + 1}</Table.Td>

                <Table.Td>{b.type}</Table.Td>
                <Table.Td>{b.orderCode}</Table.Td>
                <Table.Td>{b.code}</Table.Td>
                <Table.Td>{b.level}</Table.Td>
                <Table.Td>{cellRender.Currency(b.amount)}</Table.Td>
                <Table.Td>{cellRender.Status(b.status)}</Table.Td>
              </Table.Tr>
            ))}
        </Table.Tbody>
      </Table>
    </>
  );
};

export const ReferalRow = ({ referal }) => {
  return (
    referal && (
      <Accordion.Item value={referal._id}>
        <Accordion.Control>
          <RowHeader payment={referal} />
        </Accordion.Control>
        <Accordion.Panel>
          <RowContent payment={referal} />
        </Accordion.Panel>
      </Accordion.Item>
    )
  );
};

export const PaymentRow = ({ paymentId }) => {
  const [payment, setPayment] = useState(null);
  const [api] = useServerApi();
  const fetch = async () => {
    const data = await api.Payroll.getPaymentDetail(paymentId);
    console.log(data);

    if (!data) return setPayment(null);
    setPayment(data.payment);
  };
  const colorScheme = useComputedColorScheme();
  const theme = useMantineTheme();

  useEffect(() => {
    fetch();
  }, [paymentId]);

  const getColor = () =>
    colorScheme === "dark" ? theme.colors.dark[8] : "white";

  return (
    payment && (
      <>
        <Accordion.Item value={payment._id}>
          <Accordion.Control bg={getColor()}>
            <RowHeader payment={payment} />
          </Accordion.Control>
          <Accordion.Panel bg={getColor()}>
            <RowContent payment={payment} />
          </Accordion.Panel>
        </Accordion.Item>
      </>
    )
  );
};

const PaymentList = ({ payments }) => {
  // const rows = payments?.filter(({ wallet: w }) => w.type === type) ?? [];
  return (
    <>
      {payments && (
        <>
          {payments.length === 0 && (
            <Card>
              <Title order={6}>{`There are no payments`}</Title>
            </Card>
          )}
          {payments.map((payment, index) => (
            <PaymentRow paymentId={payment} />
          ))}
        </>
      )}
    </>
  );
};

const PaymentTab = ({ payments }) => {
  const sorted = payments; //?.sort((a, b) => a.bids.length - b.bids.length);

  return (
    <Tabs defaultValue="pending">
      <Tabs.List>
        <Tabs.Tab value="pending" icon={<IconUser size={14} />}>
          Payments
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="pending" pt="xl">
        <Accordion variant="separated">
          <PaymentList payments={sorted} />
        </Accordion>
      </Tabs.Panel>
    </Tabs>
  );
};
const PayrollTable = ({ form, name, payrollType = "NONPAYBACK" }) => {
  const { payments, _id, status } = form.values;
  const [exporting, setExporting] = useState(false);
  const [gettingPayment, setGettingPayment] = useState(false);
  const [exportingDetail, setExportingDetail] = useState(false);
  const [exportingBankFile, setExportingBankFile] = useState(false);

  const [api] = useServerApi();

  const handleComplete = async () => {
    if (!_id || exporting) return;
    try {
      const ans = window.confirm(
        "Are you sure to export the bank file? Once exported, all related commission will be settled and the operation can't undo. Please Cancel if you are not sure."
      );
      if (!ans) return;
      setExporting(true);
      let result = await api.Payroll.complete(_id);
      setExporting(false);

      form.reload();
    } catch (error) {
      //   console.log(error);
      setExporting(false);
    }
  };

  const handlePrintDetail = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      let result = await api.Payroll.printDetail(_id);
      window.open(result.data.url, "_blank");
      setExporting(false);
    } catch (error) {
      //   console.log(error);
      setExporting(false);
    }
  };

  const handleGetPending = async () => {
    if (!_id || exporting) return;
    try {
      setExporting(true);
      setGettingPayment(true);
      let result = await api.Payroll.getPendingCommission(_id, payrollType);
      //   console.log(result.payroll);
      form.setValues(result.payroll);

      setExporting(false);
      setGettingPayment(false);
    } catch (error) {
      //   console.log(error);
      setExporting(false);
      setGettingPayment(false);
    }
  };

  const isGetPendingDisable = () => status === "COMPLETED";

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}

      <Group justify="space-between" mt="xl" mb={"xl"}>
        <DateInput
          label="Complete Date"
          {...form.getInputProps("completeDate")}
        />
        <Group position="right">
          <Button
            disabled={isGetPendingDisable() || exporting}
            loading={gettingPayment}
            onClick={() => handleGetPending()}
            leftIcon={<IconRefresh size={14} />}
          >
            Get Pending Payment
          </Button>
          <Button
            // color={"green"}
            disabled={exporting}
            loading={exportingDetail}
            onClick={() => handlePrintDetail()}
            leftIcon={<IconFile size={14} />}
          >
            Print Detail
          </Button>

          <Button
            color={"red"}
            disabled={exporting || status === "COMPLETED"}
            loading={exportingBankFile}
            onClick={() => handleComplete()}
            leftIcon={<IconFile size={14} />}
          >
            Complete
          </Button>
        </Group>
      </Group>
      <PaymentTab payments={payments} />
    </>
  );
};

export default PayrollTable;
