import {
  IconCalendarStats,
  IconUser,
  IconFileText,
  IconHome,
  IconUsers,
  IconFileInvoice,
  IconCalendarEvent,
  IconLayoutDashboard,
  IconSettings2,
  IconReport,
  IconCoinMonero,
  IconBox,
  IconSubtask,
  IconReportAnalytics,
  IconBrandProducthunt,
  IconMenuOrder,
  IconBuildingStore,
  IconCube,
  IconUserDollar,
  IconHierarchy3,
  IconWallet,
  IconArrowBigUpLine,
  IconCategory2,
  IconGiftCard,
  IconMoneybag,
  IconBackspace,
  IconNumber1,
} from "@tabler/icons-react";

import Login from "../pages/login";
import ClientLogin from "../pages/clientLogin";
import ForgetPassword from "../pages/forgetPassword";
import ResetPassword from "../pages/resetPassword";
import Home from "../pages/home";
import Report from "../pages/report";
import DashBoard from "../pages/dashboard";
import Calendars from "../pages/calendars";
import Project from "../pages/project";
import Client from "../pages/client";
import Item from "../pages/item";
import Claim from "../pages/claim";
import Task from "../pages/task";
import Quotation from "../pages/quotation";
import Invoice from "../pages/invoice";
import Statement from "../pages/statement";
import User from "../pages/user";
import Setting from "../pages/setting";
import ItemCategory from "../pages/itemCategory";
import ItemGroup from "../pages/itemGroup";
import Organization from "../pages/organization";
import ClaimType from "../pages/claimType";
import ProjectType from "../pages/projectType";
import Payroll from "../pages/payroll";
import TopRanking from "../pages/topRanking";
import TeamTree from "../pages/teamTree";

///////
import Product from "../pages/product";
import ProductCategory from "../pages/productCategory";
import Position from "../pages/position";
import Order from "../pages/order";
import PendingOrder from "../pages/pendingOrder";
import Promotion from "../pages/promotion";
import { IconCategory } from "@tabler/icons-react";
import ClientHome from "../pages/clientHome";
import Commission from "../pages/commission";
import Credit from "../pages/credit";
import Payback from "../pages/payback";
import CalendarEvent from "../pages/calendarEvent";

export const routes = [
  {
    link: "/login",
    id: 0, //Public
    showOnNavBar: false,
    description: "Login Page",
    private: false,
    element: <Login />,
    isLoginRoute: true,
  },
  {
    link: "/client/login",
    id: 0, //Public
    showOnNavBar: false,
    description: "Client Login Page",
    private: false,
    element: <ClientLogin />,
    isLoginRoute: true,
    isClientRoute: true,
  },
  {
    link: "/client/home",
    id: "c1", //Public
    private: true,
    element: <ClientHome />,
    isClientRoute: true,
    isDefaultHome: true,
  },
  {
    link: "/resetPassword",
    id: 0, //Public
    showOnNavBar: false,
    description: "Forget Password Page",
    private: false,
    element: <ForgetPassword />,
  },
  {
    link: "/forgetPassword",
    id: 0, //Public
    showOnNavBar: false,
    description: "Forget Password Page",
    private: false,
    element: <ForgetPassword />,
  },

  {
    icon: IconHome,
    label: "Home",
    link: "/home",
    id: "1",
    showOnNavBar: true,
    description: "Home page",
    private: true,
    element: <Home />,
    isDefaultHome: true,
  },

  // {
  //   icon: IconLayoutDashboard,
  //   label: "Dashboard",
  //   link: "/dashboard",
  //   id: "2",
  //   showOnNavBar: true,
  //   description: "Dashboard Page",
  //   private: true,
  //   element: <DashBoard />,
  // },

  {
    icon: IconCalendarEvent,
    label: "Calendar Events",
    link: "/calendarEvent",
    id: "3",
    showOnNavBar: true,
    description: "Calendar Page",
    private: true,
    element: <CalendarEvent />,
  },
  {
    icon: IconHierarchy3,
    label: "Team Tree",
    link: "/teamTree",
    id: "5",
    showOnNavBar: true,
    description: "Team Tree Page",
    private: true,
    element: <TeamTree />,
  },
  {
    icon: IconCube,
    label: "Product",
    link: "/product",
    id: "4",
    showOnNavBar: true,
    description: "Product Page",
    private: true,
    element: <Product />,
  },
  {
    icon: IconCategory,
    label: "Product Category",
    link: "/setting/productCategory",
    id: "21",
    showOnNavBar: true,
    description: "product Category",
    private: true,
    element: <ProductCategory />,
  },
  {
    icon: IconUserDollar,
    label: "Client",
    link: "/client",
    id: "5",
    showOnNavBar: true,
    description: "Client Page",
    private: true,
    element: <Client />,
  },

  {
    icon: IconHierarchy3,
    label: "Position",
    link: "/position",
    id: "5",
    showOnNavBar: true,
    description: "Position Setting Page",
    private: true,
    element: <Position />,
  },
  {
    icon: IconFileInvoice,
    label: "Order",
    link: "/order",
    id: "23",
    showOnNavBar: true,
    description: "Order Page",
    private: true,
    element: <Order />,
  },
  {
    icon: IconFileInvoice,
    label: "Pending Order",
    link: "/pendingOrder",
    id: "23",
    showOnNavBar: true,
    description: "Order Page",
    private: true,
    element: <PendingOrder />,
  },

  {
    icon: IconMoneybag,
    label: "Commission",
    link: "/commission",
    id: "26",
    showOnNavBar: true,
    description: "Commission Page",
    private: true,
    element: <Commission />,
  },
  {
    icon: IconGiftCard,
    label: "Credit",
    link: "/credit",
    id: "27",
    showOnNavBar: true,
    description: "Credit Page",
    private: true,
    element: <Credit />,
  },
  {
    icon: IconWallet,
    label: "Payroll",
    link: "/payroll",
    id: "24",
    showOnNavBar: true,
    description: "Payroll Page",
    private: true,
    element: <Payroll />,
  },
  {
    icon: IconBackspace,
    label: "Payback",
    link: "/payback",
    id: "28",
    showOnNavBar: true,
    description: "PayBack Page",
    private: true,
    element: <Payback />,
  },
  {
    icon: IconNumber1,
    label: "Ranking Setting",
    link: "/ranking",
    id: "29",
    showOnNavBar: true,
    description: "Ranking",
    private: true,
    element: <TopRanking />,
  },
  {
    icon: IconArrowBigUpLine,
    label: "Promotion",
    link: "/promotion",
    id: "25",
    showOnNavBar: true,
    description: "Promotion Page",
    private: true,
    element: <Promotion />,
  },

  {
    icon: IconReport,
    label: "Report",
    link: "/report",
    id: "12",
    showOnNavBar: true,
    description: "Report Page",
    private: true,
    element: <Report />,
  },

  {
    icon: IconUser,
    label: "User",
    link: "/user",
    id: "13",
    showOnNavBar: true,
    description: "User Setting Page",
    private: true,
    element: <User />,
  },

  {
    icon: IconSettings2,
    label: "Settings",
    link: "/setting",
    id: "14",
    showOnNavBar: true,
    description: "Setting Page",
    private: true,
    element: <Setting />,
  },

  {
    link: "/resetPassword",
    id: "15",
    showOnNavBar: false,
    description: "Reset Password Page",
    private: true,
    element: <ResetPassword />,
  },

  {
    link: "/setting/organization",
    id: "18",
    showOnNavBar: false,
    description: "Orgranaiztion Setting ",
    private: true,
    element: <Organization />,
  },
];
