import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const ProjectCategoryTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },
      {
        field: "name",
        headerName: "Name",
        sortable: true,
      },
      {
        field: "cname",
        headerName: "CName",
        sortable: true,
      },
      {
        field: "description",
        headerName: "Description",
        sortable: true,
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],

    searchableFields: ["name"],
  };
};
