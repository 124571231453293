import _ from "lodash";
import { ActionIcon } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { useServerApi } from "../hooks/useServerApi";
import { PageList } from "./pageList";
import { useFormSchema } from "../hooks/useFormSchema";
import { useCellRender } from "../hooks/useCellRender";
import { IconExternalLink } from "@tabler/icons-react";
import ActionCellRender from "./actionCellRender";

const ClientOrderList = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();
  const clientId = _.get(form.values, "_id");
  const [api] = useServerApi();

  const [cellRender] = useCellRender();
  // const fetch = async () => {
  //   try{
  //     const result = await api.sear

  //   }catch(error){
  //     console.log("Error in ClientOrderList fetch", error);

  //   }
  // }

  const tableSchema = {
    showActionColumn: true,
    searchableFields: ["name", "code"],

    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        cellRender: (col, row) => cellRender.Order.code(row),
      },
      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, row) => row.product.name,
      },

      {
        field: "netTotal",
        headerName: "Net Total",
        sortable: true,
        cellRender: (col, row) => cellRender.Currency(row.netTotal),
      },

      {
        field: "status",
        headerName: "Payment Status",
        sortable: true,
        cellRender: (col, row) => cellRender.Order.paymentStatus(row),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "openEdit",
            linkPath: "order",
            icon: IconExternalLink,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
  };

  return (
    clientId && (
      <PageList
        apiEntity="order"
        preQuery={{ client: clientId }}
        preQueryNotEmpty={true}
        tableSchema={tableSchema}
        hideSearchBar={true}
        hideActionButton={true}
        withPaper={false}
        pt={0}
        px={0}
      />
    )
  );
};

export default ClientOrderList;
