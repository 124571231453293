import {
  Button,
  Container,
  Group,
  Modal,
  NumberInput,
  Stack,
  Flex,
  Text,
  Checkbox,
  Tabs,
  Badge,
  Card,
} from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/useServerApi";

import { FormSectionCard } from "../layout/page";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import { DateInput, DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import JsonViewer from "./common/jsonViewer";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useFormatter } from "../hooks/useFomatter";
import FileList from "./fileList";
// import ReactJson from "react-json-view";

const InvoiceAddPaymentButton = ({ mainForm }) => {
  const invoice = mainForm.values;
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [api] = useServerApi();
  const agreeLabel = "I agree to confirm";
  const [checked, setChecked] = useState(false);

  const [error, setError] = useState(null);
  const [formatter] = useFormatter();
  const [activeTab, setActiveTab] = useState("Payment");
  const forceUpdate = useForceUpdate();

  const form = useForm({
    initialValues: {
      invoiceId: invoice._id,
      amount: 0,
      paymentDate: new Date(),
      paymentMethod: "",
      files: [],
    },

    validate: {
      amount: (v) => {
        // console.log("validate amount", v, invoice.balance);
        if (v <= 0) return "Amount must be greater than 0";
        if (v != invoice.balance) return "Amount is different form balance.";
      },
    },
  });

  const changeAmount = (v) => {
    setError(null);

    if (v <= 0) setError("Amount must be greater than 0");
    if (v != invoice.balance)
      setError(`Received amount is different from the due amount. `);
    form.setFieldValue("amount", v);
  };

  const confirmPayment = async () => {
    try {
      // console.log("confirmPayment=>", form.values, invoice._id);
      const result = await api.Invoice.addPayment({
        ...form.values,
        invoiceId: invoice._id,
      });
      close();
      window.location.reload(false);

      // mainForm.reload();
      // forceUpdate();
      //Page refresh
    } catch (error) {
      console.log(error);
      close();
    }
  };

  const valid = form?.values?.amount > 0 && checked;
  return (
    <>
      <Modal
        opened={modalOpened}
        title="Add Payment"
        size={"lg"}
        onClose={close}
      >
        {/* <JsonViewer src={form.values} /> */}
        <Card>
          <Card.Section inheritPadding py="md">
            <Stack justify="space-between">
              <Flex direction="column" mb={"md"} gap={"md"}>
                <Group grow>
                  <DateInput
                    label="Payment Date"
                    {...form.getInputProps("paymentDate")}
                  />

                  <NumberInput
                    label="Received Amount"
                    prefix="$"
                    value={_.get(form?.values, "amount")}
                    thousandSeparator=","
                    onChange={changeAmount}
                    decimalScale={2}
                  />
                </Group>
              </Flex>

              <Group>
                <Badge radius={0}>Overdue</Badge>
                <Text>{formatter.currency(invoice.balance)}</Text>
              </Group>
              {error && (
                <Group>
                  <IconAlertTriangle size="14" />
                  <Text size="md">{error}</Text>
                </Group>
              )}
            </Stack>
          </Card.Section>
        </Card>

        <Group justify="space-between">
          <Checkbox
            my="xl"
            label={agreeLabel}
            size="md"
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
          <Group justify="right" gap={5}>
            <Button size="xs" variant="default" onClick={close}>
              Cancel
            </Button>
            <Button size="xs" onClick={confirmPayment} disabled={!valid}>
              Confirm
            </Button>
          </Group>
        </Group>
      </Modal>

      <Button fullWidth onClick={open} variant="default">
        Add Payment
      </Button>
    </>
  );
};

export default InvoiceAddPaymentButton;
