import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";

export const InvoiceTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "project.name",
        headerName: "Project",
        sortable: true,
      },

      {
        field: "project.client.name",
        headerName: "Client",
        sortable: true,
      },

      {
        field: "invDate",
        headerName: "Inv Date",
        sortable: true,
        cellRender: (col, data) => moment(data.invDate).format("LL"),
      },

      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};
