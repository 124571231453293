import React from "react";
import { PageList } from "../components/pageList";
import { ClientAddForm, ClientEditForm } from "../schema/form/client";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
const PendingOrder = ({ title = "Pending Order" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  return (
    <>
      <PageList
        title={title}
        apiEntity={"order"}
        preQuery={{ operationStatus: "PENDING" }}
        preQueryNotEmpty={true}
        tableSchema={getTableSchema("order")}
        formSchema={getFormSchema("order")}
        showImport={true}
        initSort={{ by: "code", order: "desc" }}

        // stats={{
        //   component: ProjecPageStats,
        //   props: {
        //     mb: 35,

        //   },
        // }}
      />
    </>
  );
};

export default PendingOrder;
