import React from "react";
import { Timeline, Card, Text, Group } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import _ from "lodash";
import JsonViewer from "./common/jsonViewer";
import moment from "moment";

const ProjectTimeline = ({ form, name }) => {
  const timeline = _.get(form.values, "timeline") || [];

  const getActive = () => {
    const now = moment();
    const timelineStarts = timeline.map((t) => moment(t.start));

    for (let i = 0; i < timelineStarts.length; i++) {
      if (now.isBetween(timelineStarts[i], timelineStarts[i + 1])) {
        return i;
      }
    }

    if (now.isAfter(timelineStarts[timelineStarts.length - 1])) {
      return timelineStarts.length - 1;
    }

    return 0;
  };

  const getDuration = (start, end) => {
    const hrs = moment(end).diff(moment(start), "hours");
    if (hrs < 24) {
      return `${hrs} hours`;
    } else {
      return `${moment(end).diff(moment(start), "days")} days`;
    }
  };
  return (
    <Card>
      {/* <JsonViewer data={timeline} /> */}
      <Card.Section withBorder inheritPadding py={"xs"}>
        <Group justify="space-between">
          <Text size="sm" fw={500}>
            Timeline
          </Text>
          <IconDotsVertical size={12} />
        </Group>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Timeline active={getActive()} bulletSize={10} lineWidth={2}>
          {timeline.map((t, index) => (
            <Timeline.Item title={t.title} key={index}>
              <Text c="dimmed" size="sm">
                {t.description}
              </Text>
              <Text size="xs" mt={4}>
                {moment(t.start).format("lll, dddd")}
              </Text>
              {t.end && (
                <Text size="xs" mt={4}>
                  {moment(t.end).format("lll, dddd")}
                </Text>
              )}
            </Timeline.Item>
          ))}
        </Timeline>
      </Card.Section>
    </Card>
  );
};

export default ProjectTimeline;
