import React from "react";
import { PageList } from "../components/pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import UserRoleList from "../components/userRoleList";
const User = ({ title = "User" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();
  const apiEntity = "user";

  const tableSchema = getTableSchema(apiEntity);
  const formSchema = getFormSchema(apiEntity);

  return (
    <PageList
      title={""}
      apiEntity={apiEntity}
      tableSchema={tableSchema}
      formSchema={formSchema}
      header={{
        component: UserRoleList,
        props: {
          title: "User Management",
        },
      }}
    />
  );
};

export default User;
