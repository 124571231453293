import { Card, useComputedColorScheme } from "@mantine/core";
import { FormSectionCard } from "../layout/page";
import { Title, Group, Text } from "@mantine/core";
import _ from "lodash";
import JsonViewer from "./common/jsonViewer";
import { ReactP5Wrapper } from "@p5-wrapper/react";
import { on } from "events";
import { useServerApi } from "../hooks/useServerApi";
import { useRef } from "react";

const ObjViewer = ({ file, key, files, canvasSize = { w: 650, h: 400 } }) => {
  let obj;
  let texture;
  let normalMapImage;
  let isMouseInside = false;

  const [api] = useServerApi();
  const computedColorScheme = useComputedColorScheme("dark");

  const handleMouseEnter = () => {
    isMouseInside = true;
  };
  const handleMouseLeave = () => {
    isMouseInside = false;
  };

  const onLoad = (model) => {
    console.log("On load success", model);
  };
  const onError = (error) => {
    console.log("On load error", error);
  };

  const loadTexture = (p5) => {
    //get texture file name
    const filname = file.name.split(".")[0];
    let textureFiles = files.filter(
      (f) =>
        f.name.includes(filname) &&
        (f.name.includes(".jpg") || f.name.includes(".png"))
    );
    if (textureFiles.length > 0) {
      texture = p5.loadImage(textureFiles[0].url);
    }
  };

  const loadModel = (p5) => {
    obj = p5.loadModel(file.url, true, onLoad, onError);
  };

  const sketch = (p5) => {
    p5.preload = () => {
      try {
        if (!file || !file.url) return;
        loadModel(p5);
        loadTexture(p5);

        // //load normal map
        // normalMapImage = p5.loadImage(
        //   "http://localhost:3001/upload/2024/0123/files/65a39be98f646e22a3c392e7/textures/Wood_Tower_Nor.jpg"
        // );
      } catch (error) {
        console.log("Preload Error", error);
      }
    };

    p5.setup = () => {
      p5.createCanvas(canvasSize.w, canvasSize.h, p5.WEBGL);
      p5.loop();
      const canvasElement = p5.canvas;
      canvasElement.addEventListener("mouseenter", handleMouseEnter);
      canvasElement.addEventListener("mouseleave", handleMouseLeave);
    };

    p5.draw = () => {
      //   p5.background(computedColorScheme === "dark" ? "black" : "#eee");
      p5.background("#aaa");

      //   p5.directionalLight(250, 250, 250, 0, 0, -1);

      if (obj) {
        p5.model(obj);
      }

      //seem not working

      if (normalMapImage) {
        // p5.normalMaterial(); // Apply default material with lighting
        p5.texture(normalMapImage);
      }
      if (texture) {
        p5.texture(texture);
        p5.noStroke();
      }
      //orbitcontrol only limit within the canvas area

      if (isMouseInside) {
        p5.orbitControl();
      }

      p5.loop();
    };
  };
  return (
    file && (
      <Card.Section inheritPadding py={"xs"} key={key} title={file.name}>
        <Group justify="space-between" mb="sm">
          <Text size="sm" fw={500} tt={"uppercase"}>
            {file.name}
          </Text>
        </Group>
        <ReactP5Wrapper sketch={sketch} />
      </Card.Section>
    )
  );
};

const isObjFile = (fileName) => {
  var fileExtension = fileName.split(".").pop();
  if (fileExtension === "obj") {
    return true;
  }
  return false;
};

const Illustrator = ({ form, name }) => {
  const files = _.get(form.values, name) || [];

  //check extension of file which is *.obj
  const objFiles = files.filter((file) => isObjFile(file.name));
  return (
    <Card inheritPadding mih={300}>
      {_.isEmpty(objFiles) && <Text>No 3D Object Files upload</Text>}
      {/* <JsonViewer src={objFiles} /> */}
      {objFiles.map((file, index) => (
        <ObjViewer key={index} file={file} files={files} />
      ))}
    </Card>
  );
};

export default Illustrator;
