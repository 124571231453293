import React, { useEffect, useState, useContext } from "react";
import {
  ScrollArea,
  Code,
  UnstyledButton,
  Badge,
  Text,
  Group,
  Title,
  Divider,
  AppShell,
  Container,
  useComputedColorScheme,
  useMantineTheme,
  Burger,
  Image,
} from "@mantine/core";
import { routes } from "../data/routes";
import _ from "lodash";
import classes from "./navbar.module.css";

import { useSignOut, useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import SearchBox from "./searchBox";
import { useDisclosure } from "@mantine/hooks";
import UserPopupButton from "./userPopupButton";

const getRoleRoutes = (userRole) =>
  routes
    ?.filter((l) => userRole.clientRouteList?.includes(l.id))
    ?.filter((l) => l.showOnNavBar === true) ?? [];

function FNavBar({
  hidden,
  width,
  setOpened,
  opened,
  onBurgerClick = () => {},
}) {
  const auth = useAuthUser();
  const { userRole } = auth();
  const Navigate = useNavigate();
  // const [opened, { toggle }] = useDisclosure();
  const colorScheme = useComputedColorScheme();
  const theme = useMantineTheme();

  const availableFunctionLink = getRoleRoutes(userRole);

  const renderLinks = availableFunctionLink.map((link, index) => {
    return (
      <span key={index}>
        {(index === 0 ||
          link.group !== availableFunctionLink[index - 1].group) &&
          link.group && (
            <Text size={"xs"} weight={500} mt="xl" mb={"xs"}>
              {link.group}
            </Text>
          )}
        <UnstyledButton
          key={link.linkId}
          className={classes.mainLink}
          onClick={() => {
            Navigate(link.link);
            onBurgerClick();
          }}
          h={35}
        >
          <div className={classes.mainLinkInner}>
            <link.icon
              size={20}
              className={classes.mainLinkIcon}
              stroke={1.5}
            />
            <a
              className={classes.collectionLink}
              href={link.link}
              onClick={(event) => event.preventDefault()}
            >
              <span>{link.label}</span>
            </a>
          </div>
          {link.notifications != null && (
            <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
              {link.notifications}
            </Badge>
          )}
        </UnstyledButton>
        {link.showDivider && <Divider mt={"md"}></Divider>}
      </span>
    );
  });

  return (
    <AppShell.Navbar pt="md" bg={theme.navbar.background[colorScheme]}>
      <AppShell.Section mx="md">
        <Group>
          {opened && (
            <Burger
              opened={opened}
              onClick={onBurgerClick}
              hiddenFrom="sm"
              size="sm"
            />
          )}
          <Title order={2}>{process.env.REACT_APP_NAME}</Title>
          <Code sx={{ fontWeight: 700 }}>{process.env.REACT_APP_VERSION}</Code>
        </Group>
        <Text size={"xs"}>{process.env.REACT_APP_SUBTITLE}</Text>
      </AppShell.Section>

      {/* <Divider mt="xl" mb="sm"></Divider> */}

      <AppShell.Section grow component={ScrollArea} mt={"xl"}>
        <SearchBox />
        <Container mt={"md"}>{renderLinks}</Container>
      </AppShell.Section>

      <AppShell.Section>
        <UserPopupButton user={auth()} />
      </AppShell.Section>
    </AppShell.Navbar>
  );
}

export default React.memo(FNavBar);
