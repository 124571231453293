import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Title,
  Box,
  useMantineTheme,
  Group,
  Chip,
  Select,
  Center,
} from "@mantine/core";
import { ResponsiveLine, Line } from "@nivo/line";
import _ from "lodash";
import { FormSectionCard } from "../../layout/page";
import { useServerApi } from "../../hooks/useServerApi";
import { lineData as sampleData } from "../../data/sampleChartData";
import moment from "moment";
import JsonViewer from "../common/jsonViewer";
import { useForceUpdate } from "@mantine/hooks";
import { IconDatabaseOff } from "@tabler/icons-react";
import NoDataDisplay from "../noDataDisplay";

const periodFilterOptions = [
  "Year to Date",
  "1 Year",
  "2 Years",
  "5 Years",
  "All History",
];

const defaultChartProps = {
  margin: { top: 50, right: 100, bottom: 80, left: 60 },
  xScale: { type: "point" },
  yScale: {
    type: "linear",
    // min: "auto",
    max: "auto",
    stacked: true,
    reverse: false,
  },
  yFormat: " >-.0f",
  curve: "natural",
  axisTop: null,
  axisRight: null,

  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: 40,
    legendPosition: "middle",
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: -50,
    legendPosition: "middle",
  },
  enableGridY: false,
  enableGridX: false,
  colors: { scheme: "category10" },
  pointSize: 5,
  pointColor: { theme: "background" },
  pointBorderWidth: 2,
  pointBorderColor: { from: "serieColor" },
  pointLabelYOffset: -12,
  enableArea: true,
  areaOpacity: 0.05,
  areaBaselineValue: 0,
  useMesh: true,
  legends: [
    {
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: "circle",
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      effects: [
        {
          on: "hover",
          style: {
            itemBackground: "rgba(0, 0, 0, .03)",
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
};

const LineChart = ({
  title,
  height = 500,
  width = 800,
  description,
  chartProps,
  dataAggreation,
  useSampleData = true,
  responseive = true,
  data,
}) => {
  const theme = useMantineTheme();
  const [api] = useServerApi();
  const chartTheme = {
    textColor:
      theme.colorScheme === "dark"
        ? theme.colors.gray[1]
        : theme.colors.dark[8],
    grid: {
      line: {
        stroke:
          theme.colorScheme === "dark"
            ? theme.colors.gray[8]
            : theme.colors.gray[1],
      },
    },
  };

  return (
    <>
      <FormSectionCard title={title} h={height} w={"100%"}>
        {/* <JsonViewer src={data} /> */}

        <Group position="apart">
          <Text size={"xs"} color="dimmed">
            {description}
          </Text>
        </Group>

        {_.isEmpty(data[0]?.data) && <NoDataDisplay />}

        {responseive && !_.isEmpty(data[0]?.data) && (
          <ResponsiveLine
            data={useSampleData ? sampleData : data || []}
            theme={chartTheme}
            {..._.merge(defaultChartProps, chartProps)}
          />
        )}
        {!responseive && !_.isEmpty(data[0]?.data) && (
          <Line
            // data={useSampleData ? sampleData : data || []}
            data={data}
            theme={chartTheme}
            width={width}
            height={height * 0.8}
            {..._.merge(defaultChartProps, chartProps)}
          />
        )}
      </FormSectionCard>
    </>
  );
};

export default LineChart;
