import { useCellRender } from "./useCellRender";

import { UserformSchema } from "../schema/form/user";
import { formSchema as UserRoleFormSchema } from "../schema/form/userRole";
import { ClaimformSchema } from "../schema/form/claim";
import { QuotationFormSchema } from "../schema/form/quotation";
import { InvoiceformSchema } from "../schema/form/invoice";
import { StatementformSchema } from "../schema/form/statement";
import { ClientFormSchema } from "../schema/form/client";
import { ProjectformSchema } from "../schema/form/project";
import { formSchema as ItemFormSchema } from "../schema/form/item";

import { ItemCategoryFormSchema } from "../schema/form/itemCategory";
import { ClaimTypeFormSchema } from "../schema/form/claimType";
import { ItemTransactionFormSchema } from "../schema/form/itemTransaction";
import { ItemGroupFormSchema } from "../schema/form/itemGroup";
import { ProjectTypeFormSchema } from "../schema/form/projectType";
import { ProjectFormSchema } from "../schema/form/project";
import { ProductCategoryFormSchema } from "../schema/form/productCategory";
import { ProductFormSchema } from "../schema/form/product";
import { PositionFormSchema } from "../schema/form/position";
import { OrderFormSchema } from "../schema/form/order";
import { PayrollFormSchema } from "../schema/form/payroll";
import { PayBackFormSchema } from "../schema/form/payback";
import { CommissionFormSchema } from "../schema/form/commission";
import { CreditFormSchema } from "../schema/form/credit";
import { CalendarEventFormSchema } from "../schema/form/calendarEvent";

export const useFormSchema = () => {
  const getFormSchema = (name) => {
    switch (name) {
      case "user":
        return UserformSchema;
      case "userRole":
        return UserRoleFormSchema;
      case "client":
        return ClientFormSchema;

      case "productCategory":
        return ProductCategoryFormSchema;

      case "product":
        return ProductFormSchema;

      case "position":
        return PositionFormSchema;

      case "order":
        return OrderFormSchema;

      case "payroll":
        return PayrollFormSchema;

      case "payback":
        return PayBackFormSchema;

      case "commission":
        return CommissionFormSchema;

      case "credit":
        return CreditFormSchema;

      case "calendarEvent":
        return CalendarEventFormSchema;

      //////////////////////

      case "itemCategory":
        return ItemCategoryFormSchema;

      case "itemGroup":
        return ItemGroupFormSchema;

      case "item":
        return ItemFormSchema;
      case "itemTransaction":
        return ItemTransactionFormSchema;

      case "claimType":
        return ClaimTypeFormSchema;

      case "projectType":
        return ProjectTypeFormSchema;

      case "project":
        return ProjectFormSchema;

      case "quotation":
        return QuotationFormSchema;

      // case "claim":
      //   return ClaimformSchema(cellRender);
      // case "invoice":
      //   return InvoiceformSchema(cellRender);
      // case "statement":
      //   return StatementformSchema(cellRender);

      // case "project":
      //   return ProjectformSchema(cellRender);
      // case "item":
      //   return ItemformSchema(cellRender);
    }
  };

  return [getFormSchema];
};
