import _ from "lodash";
import { Group, Select, NumberInput, TextInput } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { useServerApi } from "../hooks/useServerApi";
import { PageList } from "./pageList";
import { useFormSchema } from "../hooks/useFormSchema";
import { useCellRender } from "../hooks/useCellRender";

const OrderPaybackList = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();
  const orderId = _.get(form.values, "_id");
  const [api] = useServerApi();

  const [cellRender] = useCellRender();

  const tableSchema = {
    showActionColumn: false,
    searchableFields: ["name", "code"],

    columns: [
      {
        field: "status",
        headerName: "Status",
        sortable: true,
      },
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        // cellRender: (col, row) => cellRender.Order.code(row),
      },
      {
        field: "type",
        headerName: "Type",
        sortable: true,
      },
      {
        field: "client",
        headerName: "From",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.order?.client),
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, row) => cellRender.Currency(row.amount),
      },
      {
        field: "level",
        headerName: "Level",
        sortable: true,
      },
    ],
  };

  return (
    orderId && (
      <>
        <Group position="left">
          <NumberInput
            label="Payback Count"
            value={_.get(form.values, "paybackCount")}
            onChange={(value) => form.setFieldValue("paybackCount", value)}
          />
          <NumberInput
            label="Payback Amount"
            value={_.get(form.values, "paybackAmount")}
            onChange={(value) => form.setFieldValue("paybackAmount", value)}
            prefix="$"
            thousandSeparator=","
          />
        </Group>
        <PageList
          apiEntity="commission"
          preQuery={{ payBackOrder: orderId }}
          preQueryNotEmpty={true}
          tableSchema={tableSchema}
          hideSearchBar={true}
          showAddButton={false}
          withPaper={false}
          pt={0}
          px={0}
        />
      </>
    )
  );
};

export default OrderPaybackList;
