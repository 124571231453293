import _ from "lodash";
import { ActionIcon } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { useServerApi } from "../hooks/useServerApi";
import { useFormSchema } from "../hooks/useFormSchema";
import { useCellRender } from "../hooks/useCellRender";
import { IconExternalLink } from "@tabler/icons-react";
import { PageList } from "../components/pageList";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import ActionCellRender from "../components/actionCellRender";

const Commission = ({}) => {
  const [cellRender] = useCellRender();
  const tableSchema = {
    showActionColumn: true,
    searchableFields: ["name", "code", "orderCode", "clientSnapshot.name"],

    columns: [
      // {
      //   field: "status",
      //   headerName: "Status",
      //   sortable: true,
      // },
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        cellRender: (col, row) => <>{cellRender.Commission.code(row)}</>,
      },
      {
        field: "client",
        headerName: "To",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.client),
      },
      {
        field: "type",
        headerName: "Type",
        sortable: true,
      },
      {
        field: "order.client",
        headerName: "From",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.order?.client),
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, row) => cellRender.Currency(row.amount),
      },
      {
        field: "level",
        headerName: "Level",
        sortable: true,
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
  };

  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title="Commission"
      apiEntity="commission"
      tableSchema={tableSchema}
      formSchema={getFormSchema("commission")}
      hideSearchBar={false}
      hideActionButton={false}
      withPaper={true}
    />
  );
};

export default Commission;
