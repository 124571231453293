import React, { useEffect } from "react";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { IconMessage, IconExclamationMark } from "@tabler/icons-react";
import { showNotification } from "@mantine/notifications";
import {
  Button,
  Text,
  TextInput,
  Select,
  Textarea,
  ActionIcon,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import UserLeaveOpeningBalance from "./userLeaveOpeningBalance";
import UserLeaveNonReleaseBid from "./userLeaveNonReleaseBid";
import UserLeaveClient from "./userLeaveClient";
import RemoteSelect2 from "./remoteSelect2";

import _ from "lodash";
import { useServerApi } from "../hooks/useServerApi";
import { useAuthUser } from "react-auth-kit";

const NewTicketButton = ({
  onCreated,
  buttonType = "icon",
  code = "",
  ticketType,
  members,
  entityId,
  entity,
  ...props
}) => {
  const [api] = useServerApi();
  const auth = useAuthUser();

  const formSchema = {
    title: "New Ticket",
    description: "Please follow the step to terminate an user",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl", //xs, sm, xl  ....100%
    },
    showSaveBar: true,
    closeAfterSave: true,

    initialValues: {
      subject: "",
      by: auth().id,
      author: auth(),
      members: [],
      type: "",
      itemCode: "",
    },

    layout: {
      containers: [
        {
          key: "main",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            variant: "pills", //default | pills | outline
            defaultValue: "Details",
            orientation: "vertical",
            // style: { minHeight: "800px" },
          },
        },
      ],

      fields: [
        {
          name: "type",
          component: RemoteSelect2,
          parent: "main",
          props: {
            label: "Ticket Type",
            apiEntity: "ticketType",
            valueField: "_id",
            labelField: "name",
            searchFields: ["name"],
            sort: {
              by: "name",
              order: "asc",
            },
          },
        },
        {
          name: "itemCode",
          component: TextInput,
          parent: "main",
          props: {
            label: "Code",
          },
        },

        {
          name: "subject",
          component: TextInput,
          parent: "main",
          props: {
            label: "Subject",
          },
        },

        {
          name: "description",
          component: Textarea,
          parent: "main",
          props: {
            label: "Description",
          },
        },
      ],
    },
  };

  const onSubmit = async ({ values, formMode }) => {
    try {
      const result = await api.Ticket.create(values);
      const { data } = result;
      if (!data) return;
      formAction.close();

      if (onCreated) onCreated(data.ticket);
      showNotification({
        title: `${data.ticket.code} - Ticket open success`,
        message: "Import Success",
      });
    } catch (error) {
      console.log(error);
      showNotification({
        title: `Ticket Open error`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: error?.msg,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting, form] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleClick = async () => {
    let type = "";
    if (ticketType) {
      const result = await api.search({
        apiEntity: "ticketType",
        pageSize: 1,
        searchQuery: { code: ticketType },
      });
      if (!_.isEmpty(result.docs)) {
        type = result.docs[0]._id;
      }
      // console.log("Ticket type result", result);
    }

    formAction.open({
      mode: "new",
      data: { itemCode: code, type, members, entity, entityId },
    });
  };

  return (
    <>
      {/* Status: {clientStatus} */}
      {/* code : {code} <br></br> */}

      {buttonType === "icon" && (
        <ActionIcon variant="default" onClick={handleClick}>
          <IconMessage size={14} />
        </ActionIcon>
      )}

      {buttonType === "fullWidth" && (
        <Button fullWidth onClick={handleClick}>
          Create Ticket
        </Button>
      )}
      {renderForm()}
    </>
  );
};

export default NewTicketButton;
