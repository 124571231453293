import React from "react";
import { Group, NumberInput, Text, Button, InputWrapper } from "@mantine/core";
import _ from "lodash";
import { IconTrash } from "@tabler/icons-react";

const memberRefereralFees = ({ form, name }) => {
  const memberRefereralFees = _.get(form.values, name) ?? [];

  const handleAdd = () => {
    form.setFieldValue(name, [...memberRefereralFees, 0]);
  };
  return (
    <>
      {/* <Text size="md">Member</Text> */}
      {memberRefereralFees.map((amount, index) => {
        return (
          <Group mb="xs" wrap={"nowrap"} align="center">
            <NumberInput
              label={`${index + 1} 次 `}
              value={amount}
              onChange={(value) => {
                memberRefereralFees[index] = value;
                form.setFieldValue(name, memberRefereralFees);
              }}
              size="xs"
              thousandSeparator=","
              prefix="$"
              precision={1}
            />

            <IconTrash
              onClick={() => {
                memberRefereralFees.splice(index, 1);
                form.setFieldValue(name, memberRefereralFees);
              }}
              style={{ cursor: "pointer", marginTop: "15px" }}
              size={15}
            />
          </Group>
        );
      })}

      <Button size="xs" onClick={handleAdd} mt={"xl"} variant="default">
        Add
      </Button>
    </>
  );
};

export default memberRefereralFees;
