import { ENUM_FORM_DISPLAY } from "../../components/pageList";

const initialValues = {};

export const ClaimAddForm = {
  name: "addSchema",
  title: "New Claim",
  description: "Please follow the step to create a New Claim",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
  },

  apiEntity: "Claims",
  initialValues,

  layout: {
    containers: [],
    fields: [],
  },
};

export const ClaimEditForm = {};
