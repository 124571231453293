import {
  Text,
  TextInput,
  Select,
  Chip,
  Checkbox,
  Badge,
  Textarea,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState, useEffect } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons-react";
import { DATA_CLIENT_INDUSTRY, DATA_CLIENT_TYPES } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";
import ClientBasicInfo from "../../components/clientBasicInfo";
import ClientActionList from "../../components/clientActionList";

import ClientPageStats from "../../components/clientPageStats";
import AddressList from "../../components/addressList";

import ActivityLog from "../../components/activityLog.js";

import FileList from "../../components/fileList";

import RemarkList from "../../components/remarkList";
import ClientOrderList from "../../components/clientOrderList";
import ClientCommissionList from "../../components/clientCommissionList.js";
import ClientCreditList from "../../components/clientCreditList.js";
import ClientPayrollPaymentList from "../../components/clientPayrollPaymentList.js";
import ClientBankDetail from "../../components/clientBankDetail.js";
import ClientPositionInfo from "../../components/clientPositionInfo.js";

import { DateInput } from "@mantine/dates";

const apiEntity = "client";
const initialValues = {
  name: "",
  cname: "",
  aliasName: "",
  type: "DIRECT",
  address: "",
  consultants: [],
  language: "CANTONESE",
  status: "ACTIVE",
  joinDate: new Date(),
};

export const addFormSchema = {
  title: "New Client",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },

  showSaveBar: true,
  apiEntity,
  initialValues,

  validate: {
    name: (value) =>
      value?.length < 4 ? "Name must have at least 4 letters" : null,
    id: (value) => {
      return value?.length < 8 ? "ID must have at least 8 letters" : null;
    },
    idIsUnqiue: (value) => value,
  },

  layout: {
    containers: [],
    fields: [
      {
        name: "phone",
        component: TextInput,
        props: {
          required: true,
          placeholder: "30005000",
          label: "Mobile Phone",
        },
      },
      {
        name: "name",
        component: TextInput,

        props: {
          // required: true,
          placeholder: "English Name",
          label: "English Name ",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "basicInfo-g0",
        props: {
          placeholder: "名稱",
          label: "Chinese Name ",
        },
      },

      {
        name: "position",
        component: RemoteSelect2,
        parent: "basicInfo-g1",
        props: {
          apiEntity: "position",
          required: true,
          label: "Position",
          placeholder: "Assign Position",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `[${data?.code}] ${data?.name}  `,
          multiple: false,
          // preQuery,
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "code", "level"],
          sort: {
            by: "level",
            order: "asc",
          },
        },
      },

      {
        name: "referer",
        component: RemoteSelect2,
        parent: "basicInfo-g1",
        props: {
          apiEntity: "client",
          required: true,
          label: "Referer",
          placeholder: "Assign Referer",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `${data.name}, ${data.phone} `,
          multiple: false,
          // preQuery,
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "cname", "phone", "email", "aliasName"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "joinDate",
        component: DateInput,
        type: "dateInput",
        props: {
          label: "Join Date",
        },
      },
    ],
  },
};

export const editFormSchema = {
  title: "Client",
  titleField: "name",

  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity,
  initialValues,

  validate: {
    code: (value) =>
      value?.length < 2 ? "Code must have at least 2 letters" : null,
    name: (value) =>
      value?.length < 4 ? "Name must have at least 4 letters" : null,
    id: (value) =>
      value?.length < 8 ? "ID must have at least 8 letters" : null,
  },

  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
            icon: IconSettings,
          },

          {
            key: "tab-order",
            label: "Order",
            icon: IconSettings,
          },

          {
            key: "tab-commission",
            label: "Commission",
            icon: IconSettings,
          },

          {
            key: "tab-credit",
            label: "Credit",
            icon: IconSettings,
          },

          {
            key: "tab-payroll",
            label: "Payroll",
            icon: IconSettings,
          },

          {
            key: "tab-files",
            label: "Files",
            icon: IconSettings,
          },
          {
            key: "tab-log",
            label: "Logs",
            icon: IconSettings,
          },
        ],
      },
      {
        key: "section-basic-info",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-position-info",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Position",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-bank-info",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Payroll Detail",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-order-history",
        parent: "tab-order",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Order History",
          titleOrder: 5,
          withBorder: true,
          p: 0,
        },
      },

      {
        key: "section-remarks",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Client Remarks",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: 0,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-client-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Client Log",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: 350,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-files",
        parent: "tab-files",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-credit",
        parent: "tab-credit",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Credits",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "section-commission",
        parent: "tab-commission",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Commissions",
          titleOrder: 5,
          withBorder: true,
          p: 0,
        },
      },
      {
        key: "section-payroll",
        parent: "tab-payroll",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Payroll",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",

          mt: 0,
          mb: 20,
        },
      },
    ],

    fields: [
      {
        name: "display-basic-info",
        parent: "section-basic-info",
        component: ClientBasicInfo,
        props: {},
      },

      {
        name: "position",
        component: ClientPositionInfo,
        parent: "section-position-info",
        props: {},
      },
      {
        name: "orders",
        component: ClientOrderList,
        parent: "section-order-history",
        props: {},
      },

      {
        name: "commission",
        component: ClientCommissionList,
        parent: "section-commission",
        props: {},
      },

      {
        name: "credit",
        component: ClientCreditList,
        parent: "section-credit",
        props: {},
      },

      {
        name: "payroll",
        component: ClientPayrollPaymentList,
        parent: "section-payroll",
        props: {},
      },
      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },

      {
        name: "remarks",
        component: RemarkList,
        parent: "section-remarks",
        props: {
          scrollHeight: 100,
        },
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-client-log",
        props: {},
      },

      {
        name: "log",
        component: ClientBankDetail,
        parent: "section-bank-info",
        props: {},
      },
    ],
  },
};

export const ClientFormSchema = {
  add: addFormSchema,
  edit: editFormSchema,
};
