import { Page } from "../layout/page";
import {
  Accordion,
  Title,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import React, { useState, useEffect } from "react";

import ReportUser from "../components/reportUser";
import ReportOrder from "../components/reportOrder";
import ReportCommission from "../components/reportCommission";
import ReportClient from "../components/reportClient";
import ReportCredit from "../components/reportCredit";

const Report = ({}) => {
  const reports = [
    {
      title: "User Report",
      reportComponent: ReportUser,
    },
    {
      title: "Order Report",
      reportComponent: ReportOrder,
    },
    {
      title: "Commission Report",
      reportComponent: ReportCommission,
    },
    {
      title: "Credit Report",
      reportComponent: ReportCredit,
    },
    {
      title: "Client Report",
      reportComponent: ReportClient,
    },
  ];
  const colorScheme = useComputedColorScheme();
  const theme = useMantineTheme();

  const getColor = () =>
    colorScheme === "dark" ? theme.colors.dark[8] : "white";

  return (
    <Page>
      <Title> Report </Title>

      <Accordion
        variant="separated"
        radius="xs"
        defaultValue="customization"
        mt={"xl"}
      >
        {reports.map((r) => (
          <Accordion.Item value={r.title}>
            <Accordion.Control bg={getColor()}>
              <Title order={5}>{r.title}</Title>
            </Accordion.Control>
            <Accordion.Panel bg={getColor()}>
              <r.reportComponent />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Page>
  );
};

export default Report;
