import {
  Group,
  Paper,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React from "react";

const CardStat = ({
  stat,
  txtWeight = 700,
  txtSize = "xl",
  labelSize = "xs",
  iconSize = 30,
  width = "100%",
  ...props
}) => {
  return (
    <>
      <UnstyledButton w={width}>
        <Paper radius="md" p="md" key={stat.label} {...props} shadow="md">
          <Group justify="space-between" noWrap>
            <stat.icon size={iconSize} stroke={1} color={stat.color} />
            {/* <Space w={"lg"} /> */}
            <div style={{ textAlign: "right" }}>
              <Text
                color="dimmed"
                size={labelSize}
                transform="uppercase"
                weight={700}
              >
                {stat.label}
              </Text>
              <Text weight={txtWeight} size={txtSize}>
                {stat.msg}
              </Text>
            </div>
          </Group>
        </Paper>
      </UnstyledButton>
    </>
  );
};

export default CardStat;
