import React from "react";
import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  Container,
  Group,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useServerApi } from "../hooks/useServerApi";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons-react";
import { useSignIn, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

const Login = () => {
  const title = `${process.env.REACT_APP_WELCOME_MSG}`;
  const [api] = useServerApi();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenicated = useIsAuthenticated();
  const location = useLocation();
  const signOut = useSignOut();

  // if (isAuthenicated()) { //Generate Errors
  //   const query = new URLSearchParams(location.search);
  //   const error = query.get("error");
  //   if (error === "invalid_token") {
  //     signOut();
  //     // navigate("/");
  //   } else {
  //     navigate("/home");
  //   }
  //   // navigate("/home");
  // }

  const form = useForm({
    initialValues: {
      email: "",
      keepSign: true,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleError = ({ message }) => {
    let msg = "";
    switch (message) {
      case "ERR_NO_USER":
        msg = "No this user";
        break;
      case "ERR_USER_INACTIVE":
        msg = "The user is inactive";
        break;
      case "ERR_INVALID_PASSWORD":
        msg = "Invalid password";
        break;
      default:
        msg = message;
        break;
    }

    return showNotification({
      title: "Login Fail",
      color: "red",
      icon: <IconExclamationMark size={18} />,
      message: msg,
    });
  };

  const handleSuccess = async (result) => {
    // console.log(result);
    if (!result.data) return;
    const { a, t, u, w, expiresInHr } = result.data;
    // console.log("handleSuccess Logined:", u);
    const signed = await signIn({
      token: t,
      expiresIn: expiresInHr * 60,
      tokenType: "Bearer",
      authState: {
        ...u,
        a,
      },
    });
    console.log("Signed", signed);
    if (signed) {
      setTimeout(() => {
        navigate("/home");
      }, 200);
    }
  };

  const handleSignIn = async ({ email, password, keepSign }) => {
    // console.log(values);
    try {
      // signOut();
      const result = await api.User.signIn(email, password);
      if (!result.success) return handleError(result);

      return handleSuccess(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleForget = () => {
    // console.log("Forget");
    navigate("/forgetPassword");
  };

  return (
    <Container size={420} my={40}>
      <form onSubmit={form.onSubmit((values) => handleSignIn(values))}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          {title}
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Email"
            placeholder="your@email.com"
            required
            {...form.getInputProps("email")}
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps("password")}
          />
          <Group justify="space-between" mt="lg">
            <Checkbox
              label="Remember me"
              sx={{ lineHeight: 1 }}
              {...form.getInputProps("keepSign", {
                type: "checkbox",
              })}
            />
            <Anchor onClick={handleForget} href="#" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </Paper>
      </form>
    </Container>
  );
};

export default Login;
