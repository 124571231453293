import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const PayrollTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "totalPayment",
        headerName: "Total Payment",
        sortable: true,
      },
      {
        field: "totalAmount",
        headerName: "Total Amount",
        sortable: true,
        cellRender: (col, row) => cellRender.Currency(row.totalAmount),
      },

      {
        field: "remark",
        headerName: "Remark",
        sortable: true,
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, row) => cellRender.Status(row.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],

    searchableFields: ["name"],
  };
};
