import React, { useEffect, useState } from "react";

import {
  Text,
  TextInput,
  Select,
  Checkbox,
  Button,
  Group,
  InputWrapper,
  NumberInput,
  Divider,
  Title,
  Card,
  Textarea,
  Table,
  Grid,
} from "@mantine/core";

import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import { useCellRender } from "../hooks/useCellRender";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import {
  DATA_PAYMENT_METHODS,
  DATA_PAYMENT_STATUS,
  DATA_BANK,
} from "../data/options";
import ImagePicker from "./imagePicker";

const ClientBasicInfo = ({ form }) => {
  const { bankInfo, position } = form.values;
  const [cellRender] = useCellRender();

  if (!bankInfo) {
    form.setFieldValue("bankInfo", {
      bankName: "",
      bankAccount: "",
      remark: "",
    });
  }
  return (
    <>
      <Grid>
        <Grid.Col span={3}>
          <ImagePicker form={form} name={"image"} />
        </Grid.Col>
        <Grid.Col span={9}>
          <Table striped>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td>Code</Table.Td>
                <Table.Td>
                  <TextInput {...form.getInputProps("code")} />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Phone</Table.Td>
                <Table.Td>
                  <TextInput {...form.getInputProps("phone")} />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Name</Table.Td>
                <Table.Td>
                  <TextInput {...form.getInputProps("name")} />
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td>Chinese Name</Table.Td>
                <Table.Td>
                  <TextInput {...form.getInputProps("cname")} />
                </Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Alias Name</Table.Td>
                <Table.Td>
                  <TextInput {...form.getInputProps("aliasName")} />
                </Table.Td>
              </Table.Tr>

              <Table.Tr>
                <Table.Td>Join Date</Table.Td>
                <Table.Td>
                  <DateInput />
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default ClientBasicInfo;
