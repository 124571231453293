import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Switch,
} from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import remoteSelect2 from "../../components/remoteSelect2";

import memberRefereralFees from "../../components/memberRefereralFees";

import OrderList from "../../components/orderList";
import CommissionDirectRow from "../../components/commissionDirectRow";
import BundleProductRow from "../../components/bundleProductRow";
import RichTextEditor from "../../components/richTextEditor";
import ImagePicker from "../../components/imagePicker";

const apiEntity = "product";
const initialValues = {
  dimensions: {
    width: 0,
    depth: 0,
    height: 0,
  },
};

export const ProductFormSchema = {
  name: "productSchema",
  title: "Product",
  titleField: "name",
  description: "",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "section-basic",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },
      {
        key: "section-eshop",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "EShop",
          description: "Please fill in the basic information of user",
          withBorder: false,
        },
      },
      {
        key: "grid-basic",
        parent: "section-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basic-col1",
            parent: "grid-basic",
            type: ENUM_FORM_LAYOUT_CONTAINER.COL,
            props: {
              span: { base: 12, md: 6 },
            },
          },
          {
            key: "basic-col2",
            parent: "grid-basic",
            type: ENUM_FORM_LAYOUT_CONTAINER.COL,
            props: {
              span: { base: 12, md: 6 },
            },
          },
        ],
        props: {},
      },

      // {
      //   key: "section-course-Info",
      //   parent: null,
      //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
      //   visibleOnly: (values) => values.isCourse,
      //   props: {
      //     title: "Course Info",
      //     description: "Please fill in the basic information of user",
      //     withBorder: false,
      //     mt: 20,
      //   },
      // },

      {
        key: "tab-detail",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Commission",
        },
        tab: [
          {
            key: "tab-commission",
            label: "Commission",
            //icon: IconSettings,
          },
          {
            key: "tab-payback",
            label: "Payback",
            // icon: IconSettings,
          },
          {
            key: "tab-bundle",
            label: "Bundle",
            // icon: IconSettings,
          },
          {
            key: "tab-promotion",
            label: "Promotion",
            // icon: IconSettings,
          },
        ],
      },

      {
        key: "section-commission-direct",
        parent: "tab-commission",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Direct",
          description: "Please fill in the basic information of user",
          withBorder: false,
        },
      },
      {
        key: "section-commission-override",
        parent: "tab-commission",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Overriding",
          description: "Please fill in the basic information of user",
          withBorder: false,
        },
      },
      {
        key: "section-referal",
        parent: "tab-payback",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Payback to Referer",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },

      {
        key: "section-bundle",
        parent: "tab-bundle",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Bundle Products",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },

      {
        key: "section-promotion",
        parent: "tab-promotion",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Auto Promotion",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },
    ],
    fields: [
      {
        name: "code",
        component: TextInput,
        parent: "basic-col1",
        props: {
          required: true,
          label: "Code (Auto)",
          placeholder: "",
        },
      },
      {
        name: "category",
        component: remoteSelect2,
        parent: "basic-col1",
        props: {
          label: "Product Category",
          apiEntity: "productCategory",
          required: true,
          valueField: "_id",
          // labelField: "name",
          labelRender: (item) => item.name + "  " + item.cname,
          searchFields: ["name", "cname"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "status",
        component: Select,
        parent: "basic-col2",
        props: {
          label: "Status",
          required: true,
          data: [
            // { value: "PENDING", label: "PENDING" },
            { value: "ACTIVE", label: "ACTIVE" },
            { value: "INACTIVE", label: "INACTIVE" },
          ],
        },
      },
      {
        name: "name",
        component: TextInput,
        parent: "basic-col1",

        props: {
          required: true,
          label: "Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "basic-col1",

        props: {
          // required: true,
          label: "Chinese name",
          placeholder: "",
        },
      },

      {
        name: "description",
        component: Textarea,
        parent: "basic-col1",

        props: {
          // required: true,
          label: "Description",
          placeholder: "",
        },
      },

      {
        name: "price",
        component: NumberInput,
        parent: "basic-col2",

        props: {
          label: "Price",
          thousandSeparator: ",",
          precision: 2,
          prefix: "$",
        },
      },
      {
        name: "creditNeed",
        component: NumberInput,
        parent: "basic-col2",

        props: {
          label: "Credit Need",
          thousandSeparator: ",",
          precision: 0,
        },
      },

      {
        name: "credit",
        component: NumberInput,
        parent: "basic-col2",

        props: {
          label: "Credit Earn by buy this product",
          thousandSeparator: ",",
          precision: 0,
        },
      },
      // {
      //   name: "isCourse",
      //   component: Switch,
      //   parent: "basic-col2",

      //   props: {
      //     type: "checkbox",
      //     label: "Course Product",
      //     mt: 20,
      //   },
      // },
      {
        name: "isEShop",
        component: Switch,
        parent: "basic-col2",

        props: {
          type: "checkbox",
          label: "Show on EShop",
          mt: 20,
        },
      },

      {
        name: "mainImage",
        component: ImagePicker,
        parent: "section-eshop",
        props: {
          useAvatar: false,
          label: "Main Image",
        },
      },
      {
        name: "eshopDescription",
        component: RichTextEditor,
        parent: "section-eshop",
        props: {
          label: "EShop Description",
          placeholder: "",
          mt: 0,
        },
      },

      {
        name: "commissionDirect",
        component: OrderList,
        parent: "section-commission-direct",
        props: {
          label: "",
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: CommissionDirectRow,
          },
        },
      },
      {
        name: "commissionOverride",
        component: OrderList,
        parent: "section-commission-override",
        props: {
          label: "",
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: CommissionDirectRow,
          },
        },
      },
      {
        name: "memberRefereralFees",
        component: memberRefereralFees,
        parent: "section-referal",
        props: {
          label: "",
          mt: 20,
        },
      },

      {
        name: "bundleProducts",
        component: OrderList,
        parent: "section-bundle",
        props: {
          label: "",
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: BundleProductRow,
          },
        },
      },
      {
        name: "directToPosition",
        component: remoteSelect2,
        parent: "section-promotion",
        props: {
          apiEntity: "position",
          required: true,
          label: "Direct Promote to Position",
          placeholder: "Select Position",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `[ ${data.code} ] ${data.name} `,
          multiple: false,
          // preQuery,
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "cname", "code"],
          sort: {
            by: "level",
            order: "asc",
          },
        },
      },
    ],
  },
};

// export const formSchema = {
//   add: addForm,
//   edit: editForm,
// };
