import { useServerApi } from "../../hooks/useServerApi";
import { FormSectionCard } from "../../layout/page";
import { PeriodFilter } from "../../components/periodFilter";
import { useState, useEffect } from "react";
import { Group, SimpleGrid, Tabs, Table, Grid, Card } from "@mantine/core";
import { useTranslation } from "react-i18next";
import JsonViewer from "../common/jsonViewer";
import PieChart from "../../components/chartWidget/PieChart";
import BarChart from "../../components/chartWidget/BarChart";
import _ from "lodash";

import SimpleLineChart from "../../components/chartWidget/SimpleLineChart";
import CardStat from "../cardStat";
import { Icon123, IconMoneybag, IconUsers } from "@tabler/icons-react";
import { useFormatter } from "../../hooks/useFomatter";
const DashboardManagement = () => {
  const [period, setPeriod] = useState(null);
  const [api] = useServerApi();
  const { t } = useTranslation();
  const [orderByDateStats, setOrderByDateStats] = useState([]);
  const [orderAmountByDateStats, setOrderAmountByDateStats] = useState([]);
  const [orderByProductTotalStats, setOrderByProductTotalStats] = useState([]);
  const [orderByProductAmountStats, setOrderByProductAmountStats] = useState(
    []
  );
  const [orderTotalByReferStats, setOrderTotalByReferStats] = useState([]);
  const [orderAmountByReferStats, setAmountTotalByReferStats] = useState([]);

  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalSalesCount, setTotalSalesCount] = useState(0);
  const [newClient, setNewClient] = useState(0);

  const chartHeight = 420;

  const fetchStats = async () => {
    try {
      if (!period) return;
      // const data = await api.Order.getStats(period);
      // setOrderStats(data);
      const data = await api.Dashboard.getOrderStats(period);
      const { orderByDate, orderByProduct, orderByReferer, newClient } = data;
      // console.log("fetchStats", data);

      setOrderByProductTotalStats([
        ...orderByProduct?.sort((a, b) => a.total - b.total),
      ]);
      setOrderByProductAmountStats([
        ...orderByProduct?.sort((a, b) => a.totalPayment - b.totalPayment),
      ]);
      setOrderByDateStats([
        {
          id: "Order Count",
          data: orderByDate?.map((d) => ({ x: d._id, y: d.total })),
        },
      ]);
      setOrderAmountByDateStats([
        {
          id: "Order Amount",
          data: orderByDate?.map((d) => ({ x: d._id, y: d.totalPayment })),
        },
      ]);

      setOrderTotalByReferStats([
        ...orderByReferer?.sort((a, b) => a.total - b.total),
      ]);
      setAmountTotalByReferStats([
        ...orderByReferer?.sort((a, b) => a.totalPayment - b.totalPayment),
      ]);

      setTotalSalesAmount(
        orderByDate[orderByDate.length - 1]?.totalPayment ?? 0
      );
      setTotalSalesCount(orderByDate[orderByDate.length - 1]?.total ?? 0);

      setNewClient(newClient);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [period]);

  const [formatter] = useFormatter();

  return (
    <>
      {/* <JsonViewer src={orderByDateStats} /> */}
      <Group justify={"right"} mb={"xl"}>
        <PeriodFilter
          handleRefresh={fetchStats}
          handlePeriodChange={(p) => {
            // console.log("Period Change", p);
            setPeriod(p);
          }}
          numPeriods={8}
          by="Q"
        />
      </Group>

      <Group mb="xl">
        <CardStat
          width={{ base: "100%", md: "25%" }}
          stat={{
            icon: Icon123,
            color: "blue",
            label: "Total Orders",
            msg: totalSalesCount,
          }}
        />
        <CardStat
          width={{ base: "100%", md: "25%" }}
          stat={{
            icon: IconMoneybag,
            color: "blue",
            label: "Total Sales Amount",
            msg: formatter.currency(totalSalesAmount),
          }}
        />

        <CardStat
          width={{ base: "100%", md: "25%" }}
          stat={{
            icon: IconUsers,
            color: "blue",
            label: "Clients",
            msg: newClient,
          }}
        />
      </Group>

      <Tabs defaultValue="General">
        <Tabs.List mb={"xl"}>
          <Tabs.Tab value="General">General</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="General">
          <SimpleLineChart
            title={"Sales Amount"}
            data={orderAmountByDateStats}
            useSampleData={false}
            showPeriodFilter={false}
            height={chartHeight}
            chartProps={{
              legends: [
                {
                  anchor: "bottom",
                  translateX: 0,
                  translateY: 80,
                },
              ],
              enableArea: false,
              //   curve: "linear",
              yScale: { min: "auto" },
              //   margin: { top: 20, right: 50, bottom: 60, left: 80 },
              enableGridX: true,
              enableGridY: true,
              axisBottom: {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 30,
                legend: "",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              },
            }}
          />
          <SimpleLineChart
            title={"Sales Count"}
            data={orderByDateStats ?? []}
            useSampleData={false}
            showPeriodFilter={false}
            height={chartHeight}
            chartProps={{
              legends: [
                {
                  anchor: "bottom",
                  translateX: 0,
                  translateY: 80,
                },
              ],
              enableArea: false,
              //   curve: "linear",
              yScale: { min: "auto" },
              //   margin: { top: 20, right: 50, bottom: 60, left: 80 },
              enableGridX: true,
              enableGridY: true,
            }}
          />

          <BarChart
            title={"Top Products By Amount"}
            data={orderByProductAmountStats}
            height={chartHeight}
            chartProps={{
              keys: ["totalPayment"],
              layout: "vertical",
              indexBy: "name",
              marginLeft: 10,

              axisBottom: {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 15,
                legend: "",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              },
            }}
          />
          <BarChart
            title={"Top Products By Count"}
            data={orderByProductTotalStats}
            height={chartHeight}
            chartProps={{
              keys: ["total"],
              layout: "vertical",
              indexBy: "name",
              marginLeft: 10,
              axisBottom: {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 15,
                legend: "",
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              },
            }}
          />
          <BarChart
            title={"Top Referer By referal amount"}
            data={orderAmountByReferStats}
            height={chartHeight}
            chartProps={{
              keys: ["totalPayment"],
              layout: "horizontal",
              indexBy: "name",
              marginLeft: 10,
            }}
          />

          <BarChart
            title={"Top Referer By referal Count"}
            data={orderTotalByReferStats}
            height={chartHeight}
            chartProps={{
              keys: ["total"],
              layout: "horizontal",
              indexBy: "name",
              marginLeft: 10,
            }}
          />
        </Tabs.Panel>
        <Tabs.Panel value="Product"></Tabs.Panel>

        <Tabs.Panel value="Referer"></Tabs.Panel>
      </Tabs>
    </>
  );
};
export default DashboardManagement;
