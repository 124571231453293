import { DatePicker, DateTimePicker } from "@mantine/dates";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import remoteSelect2 from "../../components/remoteSelect2";
import OrderClientSelect from "../../components/orderClientSelect";
import { Textarea, TextInput } from "@mantine/core";
import FormConfirmation from "../../components/formConfirmation";
import ActivityLog from "../../components/activityLog.js";
import ProductionSections from "../../components/productionSections";
import ProjectSummaryCard from "../../components/projectSummaryCard";
import LocationCard from "../../components/locationCard";
import RemarkList from "../../components/remarkList.js";
import TeamCard from "../../components/teamCard.js";
import { AssignRuleRow } from "../../components/AssignRuleRow.js";
import OrderList from "../../components/orderList.js";
import { AssignTeamMemberRow } from "../../components/AssignTeamMemberRow.js";
import { TeamMemberAssign } from "../../components/TeamMemberAssign.js";
import { RundownList } from "../../components/rundownList.js";
import LocationAutocomplete from "../../components/locationAutocomplete.js";
import ProjectTimeline from "../../components/projectTimeline";
import ItemSummaryList from "../../components/itemSummaryList.js";
import ProjectQuotationList from "../../components/projectQuotationList.js";

const apiEntity = "project";

const initialValues = {
  duration: {
    start: "",
    end: "",
    setup: "",
    dismantle: "",
  },
  team: [],
  timeline: [
    {
      title: "Setup",
      start: "",
      description: "Moving In and setup",
      end: "",
    },
    {
      title: "Event Start",
      start: "",
      description: "The event start.",
    },
    {
      title: "Event End",
      start: "",
      description: "The event is over.",
    },
    {
      title: "Dismantle",
      start: "",
      description: "Please dismantle all the stuffs.",
    },
  ],
  productionSections: [
    {
      name: "Section 1",
      description: "Production Section 1",
      files: [],
      itemGroups: [],
    },
  ],
};

const addForm = {
  name: "addSchema",
  title: "New Project",
  description: "Please follow the step to create a New Project",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
  },
  apiEntity,
  initialValues,
  showSaveBar: false,
  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          // style: { minHeight: "400px" },
        },
        steps: [
          {
            key: "t1-1",
            label: "Basic Info",
          },
          { key: "t1-2", label: "Timeline" },
          { key: "t1-3", label: "Client" },
          { key: "t1-4", label: "Location" },

          { key: "t1-5", label: "Project Roles" },
          { key: "t1-6", label: "Confirm" },
        ],
      },
    ],
    fields: [
      {
        name: "projectType",
        component: remoteSelect2,
        parent: "t1-1",
        props: {
          label: "Project Type",
          apiEntity: "projectType",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "name",
        component: TextInput,
        parent: "t1-1",
        props: {
          label: "Project Name",
          // required: true,
        },
      },

      {
        name: "duration.setup",
        component: DateTimePicker,
        parent: "t1-2",
        type: "datePicker",
        props: {
          // required: true,
          label: "Setup Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
      {
        name: "duration.start",
        component: DateTimePicker,
        parent: "t1-2",
        type: "datePicker",
        props: {
          required: true,
          label: "Event Start Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "duration.end",
        component: DateTimePicker,
        parent: "t1-2",
        type: "datePicker",
        props: {
          required: true,
          label: "Event End Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
      {
        name: "duration.dismantle",
        component: DateTimePicker,
        parent: "t1-2",
        type: "datePicker",
        props: {
          // required: true,
          label: "Dismantle",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },
      {
        name: "client",
        component: remoteSelect2,
        parent: "t1-3",
        props: {
          label: "Client",
          apiEntity: "client",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "clientSelected",
        component: OrderClientSelect,
        parent: "t1-3",
        props: {},
      },
      {
        name: "location",
        component: LocationAutocomplete,
        parent: "t1-4",
        props: {
          label: "Event Location",
          // apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
          // onPlaceSelected: (place) => {
          //   console.log(place);
          // },
        },
      },

      {
        name: "address",
        component: Textarea,
        parent: "t1-4",
        props: {
          label: "Detail Address",
        },
      },

      {
        name: "projectManageer",
        component: remoteSelect2,
        parent: "t1-5",
        props: {
          label: "Project Manager",
          apiEntity: "user",
          required: true,
          valueField: "_id",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          searchFields: ["name", "staffId", "cname", "email", "aliasName"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "salesPerson",
        component: remoteSelect2,
        parent: "t1-5",
        props: {
          label: "Saleman",
          apiEntity: "user",
          required: true,
          valueField: "_id",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          searchFields: ["name", "staffId", "cname", "email", "aliasName"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "t1-6",
        props: {},
      },
    ],
  },
};

const editForm = {
  title: "Project",
  titleField: "name",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity,

  layout: {
    containers: [
      {
        key: "main-g",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "main-g1",
            props: {
              span: 3,
            },
          },
          {
            key: "main-g2",
            props: {
              span: 9,
            },
          },
        ],
      },

      {
        key: "main-tab",
        parent: "main-g2",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Production",
        },
        tab: [
          {
            key: "tab-production",
            label: "Production",
          },

          {
            key: "tab-team",
            label: "Team",
          },

          {
            key: "tab-rundown",
            label: "Run Down",
          },
          {
            key: "tab-itemlist",
            label: "Item List",
          },
          {
            key: "tab-documents",
            label: "Documents",
          },
          {
            key: "tab-claim",
            label: "Claim Records",
          },
          {
            key: "tab-log",
            label: "Logs",
          },
        ],
      },

      {
        key: "section-team",
        parent: "tab-team",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Team Members",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-rundown",
        parent: "tab-rundown",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Rundown",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-itemlist",
        parent: "tab-itemlist",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Item List ",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-quotation",
        parent: "tab-documents",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Quotations",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-invoice",
        parent: "tab-documents",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Invoices",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-claim",
        parent: "tab-claim",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Claim Records",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-production",
        parent: "tab-production",
        type: ENUM_FORM_LAYOUT_CONTAINER.EMPTY,
        props: {
          title: "Production",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-remark",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Remarks",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
    ],

    fields: [
      {
        name: "productionSections",
        component: ProductionSections,
        parent: "section-production",
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
      {
        name: "summary",
        component: ProjectSummaryCard,
        parent: "main-g1",
      },

      {
        name: "timeline",
        component: ProjectTimeline,
        parent: "main-g1",
      },

      {
        name: "location",
        component: LocationCard,
        parent: "main-g1",
      },
      {
        name: "team",
        component: TeamCard,
        parent: "main-g1",
      },

      {
        name: "remarks",
        component: RemarkList,
        parent: "section-remark",
      },

      // {
      //   name: "team",
      //   component: OrderList,
      //   parent: "section-team",
      //   props: {
      //     onEnterSave: true,
      //     defaultRowValue: {},
      //     row: {
      //       component: AssignTeamMemberRow,
      //     },
      //   },
      // },

      {
        name: "team",
        component: TeamMemberAssign,
        parent: "section-team",
        props: {},
      },
      {
        name: "rundowns",
        component: RundownList,
        parent: "section-rundown",
        props: {},
      },

      {
        name: "itemSummary",
        component: ItemSummaryList,
        parent: "section-itemlist",
        props: {},
      },

      {
        name: "quotation",
        component: ProjectQuotationList,
        parent: "section-quotation",
        props: {},
      },
    ],
  },
};

export const ProjectFormSchema = {
  add: addForm,
  edit: editForm,
};
