import {
  Text,
  TextInput,
  Select,
  Chip,
  Checkbox,
  Badge,
  Textarea,
  NumberInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState, useEffect } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons-react";
import { DATA_CLIENT_INDUSTRY, DATA_CLIENT_TYPES } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";
import ClientBasicInfo from "../../components/clientBasicInfo";
import ClientActionList from "../../components/clientActionList";

import ClientPageStats from "../../components/clientPageStats";
import AddressList from "../../components/addressList";

import ActivityLog from "../../components/activityLog.js";

import FileList from "../../components/fileList";

import RemarkList from "../../components/remarkList";
import ClientOrderList from "../../components/clientOrderList";
import ClientCommissionList from "../../components/clientCommissionList.js";
import ClientCreditList from "../../components/clientCreditList.js";
import ClientPayrollPaymentList from "../../components/clientPayrollPaymentList.js";
import ClientBankDetail from "../../components/clientBankDetail.js";
import ClientPositionInfo from "../../components/clientPositionInfo.js";

const apiEntity = "commission";
const initialValues = {
  type: "ADJUSTMENT",
  status: "OPENING",
};

export const addFormSchema = {
  title: "New Commission",
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },

  showSaveBar: true,
  apiEntity,
  initialValues,

  validate: {
    name: (value) =>
      value?.length < 4 ? "Name must have at least 4 letters" : null,
    id: (value) => {
      return value?.length < 8 ? "ID must have at least 8 letters" : null;
    },
    idIsUnqiue: (value) => value,
  },

  layout: {
    containers: [],
    fields: [
      {
        name: "client",
        component: RemoteSelect2,
        props: {
          apiEntity: "client",
          required: true,
          label: "Client",
          placeholder: "To Client",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `${data?.name} [${data?.phone}]  `,
          multiple: false,
          // preQuery,
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "phone", "code"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "order",
        component: RemoteSelect2,
        props: {
          apiEntity: "order",
          // required: true,
          label: "Order",
          placeholder: "Order",
          valueField: "_id",
          labelField: "code",
          labelRender: (data) => `${data?.code}`,
          multiple: false,
          // preQuery,
          // preQuery:  {isConsultant : true} ,
          searchFields: ["code"],
          sort: {
            by: "code",
            order: "asc",
          },
        },
      },
      {
        name: "amount",
        component: NumberInput,
        props: {
          label: "Amount",
          required: true,
          thousandSeparator: ",",
        },
      },

      {
        name: "type",
        component: Select,
        props: {
          label: "Type",
          data: [
            //["DIRECT", "OVERRIDE", "PAYBACK"];

            { value: "DIRECT", label: "Direct" },
            { value: "OVERRIDE", label: "Override" },
            { value: "PAYBACK", label: "Payback" },
            { value: "ADJUSTMENT", label: "Adjustment" },
          ],
        },
      },

      {
        name: "remark",
        component: Textarea,
        props: {
          label: "Remark",
        },
      },
    ],
  },
};

export const CommissionFormSchema = addFormSchema;
