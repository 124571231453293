import React, { useEffect, useState } from "react";
import { FormSectionCard } from "../layout/page";
import {
  Title,
  Group,
  Card,
  Divider,
  Text,
  Avatar,
  Container,
} from "@mantine/core";
import { useCellRender, openWhatsapp } from "../hooks/useCellRender";
import _ from "lodash";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import {
  IconAddressBook,
  IconMap,
  IconMessage,
  IconPin,
} from "@tabler/icons-react";
import { IconPinned } from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";

const TeamMemberAvatar = ({ member }) => {
  const [user, setUser] = useState();
  const [api] = useServerApi();

  const fetchUser = async (userId) => {
    if (!userId) return;
    const u = await api.getById({ apiEntity: "user", id: userId });
    // console.log(u);
    setUser(u);
  };

  useEffect(() => {
    // console.log("member", member);
    fetchUser(member.user);
  }, [member.user]);

  //   <Avatar
  //   src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-3.png"
  //   alt="Jacob Warnhalter"
  //   radius="xl"
  // />

  return (
    <Group my="xs" gap="xs">
      <Avatar src={user?.image} alt={user?.aliasName ?? "dummy"} radius="xl" />
      <span>
        <Text color="dimmed" size="xs">
          {member.roleName}
        </Text>
        {user && (
          <Group justify="space-between" w={150}>
            <Text size="xs">{user?.aliasName}</Text>
            <Group
              gap="2"
              onClick={() => openWhatsapp(user?.phone)}
              style={{ cursor: "pointer" }}
            >
              <IconMessage size="15" />
              <Text size="xs">{user?.phone}</Text>
            </Group>
          </Group>
        )}
        {!user && <Text size="xs"> x {member.quantity}</Text>}
      </span>
    </Group>
  );
};
/**
 *
 * @param {viewMode} place view directions streetview search
 * @param {maptype} roadmap or satellite
 * @returns
 *
 */
function TeamCard({
  form,
  name,
  shadow = "md",
  viewMode = "view",
  maptype = "roadmap",
}) {
  const [cellRender] = useCellRender();
  const team = _.get(form.values, name);

  return (
    <Card shadow={shadow}>
      <Card.Section withBorder inheritPadding py="xs">
        <Text size="sm" fw={500}>
          Team
        </Text>
      </Card.Section>
      <Card.Section inheritPadding py="xs" withBorder>
        {team.map((member, index) => (
          <TeamMemberAvatar member={member} key={`member-${index}`} />
        ))}
      </Card.Section>
    </Card>
  );
}

export default TeamCard;
