import _ from "lodash";
import { Badge, Group, Text, Title } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { useServerApi } from "../hooks/useServerApi";
import { PageList } from "./pageList";
import { useFormSchema } from "../hooks/useFormSchema";
import { useCellRender } from "../hooks/useCellRender";

const ClientCreditList = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();
  const clientId = _.get(form.values, "_id");
  const activeCredit = _.get(form.values, "activeCredit");
  const [api] = useServerApi();

  const [cellRender] = useCellRender();
  // const fetch = async () => {
  //   try{
  //     const result = await api.sear

  //   }catch(error){
  //     console.log("Error in OrderCreditList fetch", error);

  //   }
  // }

  const tableSchema = {
    showActionColumn: false,
    searchableFields: ["name", "code"],

    columns: [
      {
        field: "status",
        headerName: "Status",
        sortable: true,
      },
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        // cellRender: (col, row) => cellRender.Order.code(row),
      },
      {
        field: "type",
        headerName: "Type",
        sortable: true,
      },
      {
        field: "order.client",
        headerName: "From",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.order?.client),
      },
      {
        field: "credit",
        headerName: "Credit",
        sortable: true,
        cellRender: (col, row) => row.amount,
      },
      {
        field: "level",
        headerName: "Level",
        sortable: true,
      },
    ],
  };

  return (
    clientId && (
      <>
        <Group ml="xl">
          <Text>Active Credits: </Text>
          <Badge radius={0}>{cellRender.NumberFormat(activeCredit)}</Badge>
        </Group>
        <PageList
          apiEntity="credit"
          preQuery={{ client: clientId }}
          preQueryNotEmpty={true}
          tableSchema={tableSchema}
          hideSearchBar={true}
          hideActionButton={true}
          withPaper={false}
          pt={0}
          px={0}
        />
      </>
    )
  );
};

export default ClientCreditList;
