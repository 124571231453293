import React, { useRef } from "react";
import FormSection from "./formSection";
import OrderList from "./orderList";
import _ from "lodash";
import {
  Card,
  Group,
  NumberInput,
  Title,
  Text,
  Button,
  CardSection,
  Tabs,
  Container,
  Divider,
  TextInput,
  Textarea,
  InputWrapper,
  Select,
  ActionIcon,
  Menu,
  Space,
  Center,
} from "@mantine/core";
import {
  IconCirclePlus,
  IconDotsVertical,
  IconHeart,
  IconPhoto,
} from "@tabler/icons-react";
import { Form } from "react-router-dom";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";
import FileList from "./fileList";
import JsonViewer from "./common/jsonViewer";
import { useServerApi } from "../hooks/useServerApi";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import ItemsFloorPlan from "./itemsFloorPlan";
import FloorPlanLayoutTable from "./floorPlanLayoutTable";
import GroupMenu from "./groupMenu";
import ProductionItemListRow from "./productionItemListRow";
import Illustrator from "./illustrator";

const ProductionSectionGroup = ({ group, form, sectionIndex, groupIndex }) => {
  const forceUpdate = useForceUpdate();
  const formItemGroupName = `productionSections.${sectionIndex}.itemGroups.${groupIndex}`;
  const [api] = useServerApi();

  const autoPlan = async (group) => {
    try {
      // console.log("Autoplan", group);
      // form.setFieldValue(`${formItemGroupName}.itemList`, []);

      const result = await api.ItemGroup.autoPlan(group);
      console.log(result);
      if (!result || !result.data) return;

      const newItemList = [];
      const newFloorPlan = [];
      for (const r of result.data.results) {
        //Single Item
        if (r.item) {
          newItemList.push(r);
          continue;
        }
        //Combination result (hasve Selection)
        if (r.combination) {
          //Item List
          const optimized = r.combination[0];
          if (!optimized) continue;
          newItemList.push(...optimized.typeGroup);
          //Floor Plan (layout)
          newFloorPlan.push(...optimized.items);
          newFloorPlan.forEach((plan) => (plan.category = r.category));
          continue;
        }
      }
      // const newItemList = result.data?.results.map((r) => ({
      //   category: r.category ?? "ABC",
      //   item: r.item?._id ?? null,
      //   quantity: r.quantity,
      // }));

      const floorPlans = _.groupBy(newFloorPlan, "category._id");
      // console.log(floorPlans);

      form.setFieldValue(`${formItemGroupName}.itemList`, []);
      form.setFieldValue(`${formItemGroupName}.itemList`, newItemList);

      form.setFieldValue(`${formItemGroupName}.floorPlan`, {});
      form.setFieldValue(`${formItemGroupName}.floorPlan`, floorPlans);

      forceUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Tabs.Panel value={groupIndex + ""}>
      <Card title={group.name} mb="xl">
        <Group align="end" grow mb="sm">
          <TextInput
            value={group.name}
            label="Group Name"
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.name`)}
          />
          <InputWrapper label="Group Type" size="xs" my={0}>
            <RemoteSelect2
              size="xs"
              apiEntity="itemGroup"
              labelField="name"
              valueField="_id"
              labelRender={(data) => `${data.name}`}
              multiple={false}
              searchFields={["name", "code"]}
              placeholder="Item"
              clearable={true}
              value={_.get(form.values, `${formItemGroupName}.groupType`)}
              onDataChange={(data) => {
                form.setFieldValue(
                  `${formItemGroupName}.groupType`,
                  data ? data._id : null
                );
              }}
              sort={{
                by: "name",
                order: "asc",
              }}
            />
          </InputWrapper>
        </Group>

        {/* Group Dimension */}
        <Group grow align="end">
          <NumberInput
            value={group.dimensions.width}
            label="Width"
            thousandSeparator=","
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.dimensions.width`)}
          />
          <NumberInput
            value={group.dimensions.depth}
            label="Depth"
            thousandSeparator=","
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.dimensions.depth`)}
          />
          <NumberInput
            value={group.dimensions.height}
            label="Height"
            thousandSeparator=","
            size="xs"
            {...form.getInputProps(`${formItemGroupName}.dimensions.height`)}
          />
          <Button variant="default" onClick={() => autoPlan(group)}>
            Auto Plan
          </Button>
        </Group>

        {/* Item List */}
        <Tabs defaultValue={"Item List"} mt={"xl"}>
          <Tabs.List>
            <Tabs.Tab value="Item List">Item List</Tabs.Tab>
            <Tabs.Tab value="Floor Plan">Floor Plan</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="Item List">
            <Card>
              <OrderList
                form={form}
                // name={name + ".itemList"}
                name={`${formItemGroupName}.itemList`}
                row={{ component: ProductionItemListRow }}
                addLabel="Add Item"
              />
            </Card>
          </Tabs.Panel>

          <Tabs.Panel value="Floor Plan">
            <Card>
              {/* <JsonViewer src={_.get(form.values, `${formItemGroupName}`)} /> */}

              <FloorPlanLayoutTable
                itemGroup={_.get(form.values, `${formItemGroupName}`)}
              />
              <Space h="md" />
              <ItemsFloorPlan
                itemGroup={_.get(form.values, `${formItemGroupName}`)}
              />
            </Card>
          </Tabs.Panel>
        </Tabs>
      </Card>
    </Tabs.Panel>
  );
};

const ProductionSectionItemGroups = ({
  itemGroups,
  sectionIndex,
  form,
  handleRemoveGroup,
  handleAddGroup,
}) => {
  return (
    <>
      <Tabs defaultValue={"0"} variant="outline">
        <Tabs.List>
          <Center w={50}>
            <IconCirclePlus
              size={20}
              onClick={handleAddGroup}
              style={{ cursor: "pointer" }}
            />
          </Center>
          {/* <Button size="xs" variant="default" onClick={handleAddGroup} mr="xs">
            Add Group
          </Button> */}
          {itemGroups.map((group, groupIndex) => (
            <Tabs.Tab value={groupIndex + ""} size={"xs"} key={groupIndex}>
              <Group>
                <Text size="xs">{group.name}</Text>
                <GroupMenu
                  menu={[
                    {
                      label: "Remove Group",
                      color: "red",
                      onClick: () => handleRemoveGroup(groupIndex),
                    },
                  ]}
                />
              </Group>
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {itemGroups.map((group, groupIndex) => (
          <ProductionSectionGroup
            group={group}
            form={form}
            sectionIndex={sectionIndex}
            groupIndex={groupIndex}
          />
        ))}
      </Tabs>
    </>
  );
};

const ProductionSectionInfo = ({ section, form, sectionIndex }) => {
  const forceUpdate = useForceUpdate();
  const defaultItemGroupValue = {
    name: `Group ${section.itemGroups.length + 1}`,
    dimensions: {
      width: 0,
      depth: 0,
      height: 0,
    },
    itemList: [],
    floorPlan: [],
  };

  const handleAddGroup = () => {
    section.itemGroups.push(defaultItemGroupValue);
    forceUpdate();
  };

  const handleRemoveGroup = (index) => {
    section.itemGroups.splice(index, 1);
    forceUpdate();
  };

  return (
    <>
      <Card mb="xl">
        {/* <Card ml={0}>{section.name}</Card> */}
        <TextInput
          label="Section Name"
          mb="xs"
          size="xs"
          {...form.getInputProps(`productionSections.${sectionIndex}.name`)}
        />
        <Textarea
          value={section.description}
          label="Description"
          mb="xs"
          size="xs"
          {...form.getInputProps(
            `productionSections.${sectionIndex}.description`
          )}
        />
        <Space h={50} />
        {section.itemGroups.length > 0 && (
          <ProductionSectionItemGroups
            form={form}
            sectionIndex={sectionIndex}
            itemGroups={section.itemGroups}
            handleRemoveGroup={handleRemoveGroup}
            handleAddGroup={handleAddGroup}
          />
        )}
      </Card>
    </>
  );
};

const ProductionSection = ({ section, form, index }) => {
  const sectionFormName = `productionSections.${index}`;
  return (
    <>
      {/* <Title order={5} mb="xl">
        {section.name}
      </Title> */}
      <Tabs defaultValue={"basic"} mt="xl">
        <Tabs.List>
          <Tabs.Tab value="basic"> Section Info</Tabs.Tab>
          <Tabs.Tab value="illustration"> Illustration</Tabs.Tab>
          <Tabs.Tab value="files"> Files</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="basic">
          <ProductionSectionInfo
            section={section}
            form={form}
            sectionIndex={index}
          />
        </Tabs.Panel>

        <Tabs.Panel value="illustration">
          <Illustrator form={form} name={`${sectionFormName}.files`} />
        </Tabs.Panel>

        <Tabs.Panel value="files">
          <Card>
            {/* <JsonViewer src={_.get(form.values, `${sectionFormName}.files`)} /> */}
            <FileList form={form} name={`${sectionFormName}.files`}></FileList>
          </Card>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const ProductionSections = ({ form, name }) => {
  const sections = _.get(form.values, name);

  const defaultSectionValue = {
    name: `Section ${sections.length + 1}`,
    files: [],
    description: "",
    itemGroups: [
      {
        name: "Main Group",
        groupType: null,
        dimensions: {
          width: 0,
          depth: 0,
          height: 0,
        },
        itemList: [],
        floorPlan: [],
      },
    ],
  };

  const handleAddSection = () => {
    sections.push({ ...defaultSectionValue });
    form.setFieldValue("productionSections", sections);
  };

  const handleRemoveSession = (index) => {
    sections.splice(index, 1);
    form.setFieldValue("productionSections", sections);
  };

  return (
    <>
      {/* <JsonViewer src={form.values} /> */}
      {_.isEmpty(sections) && (
        <Button size="xs" variant="default" onClick={handleAddSection} mr="xs">
          Add Section
        </Button>
      )}
      {!_.isEmpty(sections) && (
        <Tabs
          variant="outline"
          // orientation="vertical"
          defaultValue={"0"}
          placement="right"
          // mb="xl"
        >
          <Tabs.List grow>
            <Button
              size="xs"
              variant="default"
              onClick={handleAddSection}
              mr="xs"
            >
              Add Section
            </Button>
            {sections.map((section, index) => (
              <Tabs.Tab value={index + ""} size={"xs"}>
                <Group>
                  <Text size="xs">{section.name}</Text>

                  <GroupMenu
                    menu={[
                      {
                        label: "Remove Session",
                        color: "red",
                        onClick: () => handleRemoveSession(index),
                      },
                    ]}
                  />
                </Group>
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {sections.map((section, index) => (
            <Tabs.Panel value={index + ""} px={0} pr={"md"} key={index}>
              <ProductionSection section={section} form={form} index={index} />
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default ProductionSections;
