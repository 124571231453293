import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";

export const QuotationTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "project.name",
        headerName: "Project",
        sortable: true,
      },

      {
        field: "project.client.name",
        headerName: "Client",
        sortable: true,
      },

      {
        field: "quoDate",
        headerName: "Quo Date",
        sortable: true,
        cellRender: (col, data) => cellRender.Date(data.quoDate, "ll"),
      },
      {
        field: "createdBy",
        headerName: "Created By",
        sortable: true,
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};
