import React from "react";
import { TextInput, Grid, Select } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { DATA_ITEM_ASSIGN_METHOD } from "../data/options";

export const AssignRuleRow = ({ item }) => {
  const forceUpdate = useForceUpdate();

  return (
    <Grid>
      {/* <JsonViewer src={item} /> */}

      <Grid.Col span={"auto"}>
        <RemoteSelect2
          apiEntity="itemCategory"
          labelField="name"
          valueField="_id"
          // labelRender={(data) => `${data.name} (${data.staffId ?? " -- "})`}
          multiple={false}
          searchFields={["name"]}
          placeholder="Item Category"
          clearable={true}
          value={item.content["itemCategory"]}
          onDataChange={(data) => {
            item.content["itemCategory"] = data?._id;
            forceUpdate();
          }}
          sort={{
            by: "name",
            order: "asc",
          }}
        />
      </Grid.Col>
      <Grid.Col span={"auto"}>
        <Select
          value={item.content["assignMethod"]}
          onChange={(d) => {
            item.content["assignMethod"] = d;
            forceUpdate();
          }}
          placeholder="Item Assign Method"
          data={DATA_ITEM_ASSIGN_METHOD}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          value={item.content["quantityFormula"]}
          placeholder="Quantity Formula"
          fw={true}
          onChange={(e) => {
            item.content["quantityFormula"] = e.currentTarget.value;
            forceUpdate();
          }}
        />
      </Grid.Col>
    </Grid>
  );
};
