import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const ClientTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "name",
        headerName: "Name",
        sortable: true,
      },
      // {
      //   field: "cname",
      //   headerName: "Chinese Name",
      //   sortable: true,
      // },

      {
        field: "phone",
        headerName: "Phone Number",
        sortable: true,
      },

      {
        field: "position",
        headerName: "Position",
        sortable: true,
        cellRender: (col, row) => {
          return row.position ? row.position.name : "";
        },
      },
      {
        field: "Referer",
        headerName: "Referer",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.referer),
      },

      {
        field: "activeCredit",
        headerName: "Credits",
        sortable: true,
        cellRender: (col, row) => cellRender.NumberFormat(row.activeCredit),
        // cellRender: (col, row) => cellRender.Client(row.referer),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
    searchByAggregation: true,
    searchableFields: [
      "name",
      "code",
      "phone",
      "email",
      "cname",
      "position.name",
      "position.code",
      "position.cname",
      "referer.name",
      "referer.code",
      "referer.cname",
      "referer.phone",
    ],
  };
};
