import React, { useRef } from "react";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { Group, NumberInput } from "@mantine/core";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

const ProductionItemListRow = ({ item }) => {
  const forceUpdate = useForceUpdate();
  const remoteRef = useRef(null);

  useDeepCompareEffect(() => {
    // console.log("item changed", item);
    forceUpdate();
    remoteRef.current?.refresh();
  }, [item]);
  return (
    <>
      {/* <JsonViewer src={item} /> */}
      <Group grow>
        {/* <Text size="md"> {item.content["category"]}</Text> */}
        {/* item : {item.content["_id"]} */}
        <RemoteSelect2
          ref={remoteRef}
          apiEntity="item"
          labelField="name"
          valueField="_id"
          labelRender={(data) => `${data.name}`}
          multiple={false}
          searchFields={["name", "code", "cname"]}
          placeholder="Item"
          clearable={true}
          value={item.content["item"]}
          onDataChange={(data) => {
            // console.log("data", data);
            item.content["item"] = data ? data._id : null;
            item.content["description"] = data ? data.description : null;
            forceUpdate();
          }}
          sort={{
            by: "name",
            order: "asc",
          }}
        />
        {/* <TextInput
          value={item.content["description"]}
          onChange={(e) => {
            item.content["description"] = e.currentTarget.value;
            forceUpdate();
          }}
          placeholder="Description"
          width={300}
        /> */}
        <NumberInput
          placeholder={"Quantity"}
          defaultValue={1}
          thousandSeparator=","
          value={item.content["quantity"]}
          onChange={(n) => {
            item.content["quantity"] = n;
            forceUpdate();
          }}
          width={100}
        />
      </Group>
    </>
  );
};

export default ProductionItemListRow;
