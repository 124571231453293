import React from "react";
import { Page } from "../layout/page";
import PromotionPageStats from "../components/promotionPageStats";
import { Table, Tabs, Button, Group } from "@mantine/core";
import { useState, useEffect, useRef } from "react";
import { useServerApi } from "../hooks/useServerApi";
import LocalPageList from "../components/localPageList";
import { PageList } from "../components/pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useCellRender } from "../hooks/useCellRender";

const PromotionTable = ({ status, pageListRef, onReload }) => {
  const [api] = useServerApi();
  const [cellRender] = useCellRender();

  const handleOnClickAccept = async (data) => {
    try {
      const msg = `Are you sure you want to ACCEPT the promotion: \n [ ${data.client.name} ]  => [ ${data.fromPosition.name} ] to [ ${data.toPosition.name} ] ?`;
      if (window.confirm(msg)) {
        const result = await api.Promotion.accept(data._id);
        console.log(result);
        onReload();
      }
    } catch (error) {}
  };

  const handleOnClickDecline = async (data) => {
    try {
      const msg = `Are you sure you want to  DECLINE the promotion: \n [ ${data.client.name} ]  => [ ${data.fromPosition.name} ] to [ ${data.toPosition.name} ] ?`;
      if (window.confirm(msg)) {
        const result = await api.Promotion.decline(data._id);
        console.log(result);
        onReload();
      }
    } catch (error) {}
  };

  const handleOnClickRevert = async (data) => {
    try {
      const msg = `Are you sure you want to REVERT the promotion: \n [ ${data.client.name} ]  => [ ${data.toPosition.name} ] to [ ${data.fromPosition.name} ] ?`;
      if (window.confirm(msg)) {
        const result = await api.Promotion.revert(data._id);
        console.log(result);
        onReload();
      }
    } catch (error) {}
  };

  const getButtonLabel = (status) => {
    switch (status) {
      case "PENDING":
        return null;
      case "ACTIVE":
        return "Accept";
      case "ACCEPTED":
        return "Revert";
      case "DECLINED":
        return "Revert";
      default:
        return "View";
    }
  };
  const tableSchema = {
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        cellRender: (col, data) => cellRender.Promotion.code(data),
      },
      {
        field: "client",
        headerName: "Client",
        sortable: true,
        cellRender: (col, data) => cellRender.Client(data.client),
      },
      {
        field: "fromPosition",
        headerName: "From",
        sortable: true,
        cellRender: (col, data) => data.fromPosition?.name,
      },
      {
        field: "toPosition",
        headerName: "To",
        sortable: true,
        cellRender: (col, data) => data.toPosition?.name,
      },

      {
        field: "referalCount",
        headerName: "Referal Count",
        sortable: true,
      },
      //   {
      //     field: "referalAmount",
      //     headerName: "Amount",
      //     sortable: true,
      //     cellRender: (col, data) => cellRender.Currency(data.referalAmount),
      //   },

      {
        field: "action",
        headerName: "Action",
        cellRender: (col, data) => {
          return (
            <>
              {data.status === "ACTIVE" && (
                <Group>
                  <Button
                    size="xs"
                    color="teal"
                    onClick={() => handleOnClickAccept(data)}
                  >
                    Accept
                  </Button>
                  <Button
                    size="xs"
                    color="red"
                    onClick={() => handleOnClickDecline(data)}
                  >
                    Decline
                  </Button>
                </Group>
              )}

              {(data.status === "ACCEPTED" || data.status === "DECLINED") && (
                <Group>
                  <Button
                    size="xs"
                    color="red"
                    onClick={() => handleOnClickRevert(data)}
                  >
                    Revert
                  </Button>
                </Group>
              )}
            </>
          );
        },
      },
    ],
  };

  if (status === "PENDING") {
    tableSchema.columns = tableSchema.columns.filter(
      (col) => col.field !== "action"
    );
  }

  return (
    <PageList
      apiEntity={"promotion"}
      preQuery={{ status }}
      tableSchema={tableSchema}
      showAddButton={false}
      hideSearchBar={true}
      px="0"
      ref={pageListRef}
      noDataText="0 Records Found"
    />
  );
};

const Promotion = () => {
  const [getTableSchema] = useTableSchema();

  const refActive = useRef();
  const refPending = useRef();
  const refAccept = useRef();
  const refDecline = useRef();

  const onReload = () => {
    refActive.current?.reload();
    refPending.current?.reload();
    refAccept.current?.reload();
    refDecline.current?.reload();
  };

  return (
    <>
      <Page title="Promotion">
        {/* <PromotionPageStats /> */}
        <Tabs defaultValue={"ACTIVE"}>
          <Tabs.List>
            <Tabs.Tab value="ACTIVE">Active</Tabs.Tab>
            <Tabs.Tab value="PENDING">Pending</Tabs.Tab>
            <Tabs.Tab value="ACCEPTED">Accepted</Tabs.Tab>
            <Tabs.Tab value="DECLINED">Declined</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="ACTIVE">
            <PromotionTable
              status="ACTIVE"
              onReload={onReload}
              pageListRef={refActive}
            />
          </Tabs.Panel>
          <Tabs.Panel value="PENDING">
            <PromotionTable
              status="PENDING"
              onReload={onReload}
              pageListRef={refPending}
            />
          </Tabs.Panel>
          <Tabs.Panel value="ACCEPTED">
            <PromotionTable
              status="ACCEPTED"
              onReload={onReload}
              pageListRef={refAccept}
            />
          </Tabs.Panel>
          <Tabs.Panel value="DECLINED">
            <PromotionTable
              status="DECLINED"
              onReload={onReload}
              pageListRef={refDecline}
            />
          </Tabs.Panel>
        </Tabs>
      </Page>
    </>
  );
};

export default Promotion;
