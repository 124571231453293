import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  Title,
  Button,
  TextInput,
  ActionIcon,
  Group,
  Menu,
  Card,
  Space,
  Grid,
  Center,
  FileButton,
  Box,
  UnstyledButton,
  Image,
  Avatar,
  Loader,
  Table,
  Checkbox,
} from "@mantine/core";

import {
  useForceUpdate,
  useHotkeys,
  randomId,
  useTimeout,
} from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import {
  IconTrash,
  IconCheck,
  IconSettings,
  IconExclamationMark,
  IconMessageCircle,
  IconCoin,
  IconDownload,
  IconDotsVertical,
  IconList,
  IconGridPattern,
  IconPencil,
  IconLayoutGrid,
  IconRefresh,
  IconFileTypeDoc,
  IconFileSpreadsheet,
  IconFileTypeZip,
  IconFileTypeCsv,
  IconFileTypePdf,
  IconFileTypePpt,
  IconFile,
  IconFileTypePng,
  IconFileMusic,
} from "@tabler/icons-react";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { FontAwesomeIcon } from "@fortawesome/fontawesome-free";
import _ from "lodash";

import { useServerApi } from "../hooks/useServerApi";
import { useDateRender } from "../hooks/useDateRender";
import { useClickOutside } from "@mantine/hooks";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import DataTable from "./dataTable";
import { useAuthUser } from "react-auth-kit";
import { IconFileTypeJpg } from "@tabler/icons-react";
import { IconFileTypeSvg } from "@tabler/icons-react";
import { IconFileTypeXls } from "@tabler/icons-react";
import JsonViewer from "./common/jsonViewer";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

const filesize = require("file-size");

const PdfPreviewer = ({ fileUrl, pageIndex = 1 }) => {
  const generateThumbnail = async (file) => {
    // console.log("generateThumbnail", file);
    try {
      const thumbnails = await generatePdfThumbnails(file, 150);
      // console.log(thumbnails);
    } catch (error) {
      console.log(error);
    }
    const thumbnails = generatePdfThumbnails(file, 150);
  };

  useEffect(() => {
    if (!fileUrl) return;
    generateThumbnail(fileUrl);
  }, [fileUrl]);
  return <>PDF</>;
};

export const isImageFile = (mimetype) => {
  return [
    "image/png",
    "image/gif",
    "image/jpg",
    "image/jpeg",
    "image/svg+xml",
    "image/webp",
    "video/mp4",
  ].includes(mimetype);
};

export const isPDFFile = (mimetype) => {
  return ["application/pdf"].includes(mimetype);
};

export const supportFiles = [
  {
    ext: "png",
    icon: IconFileTypePng,
    color: "#8479F1",
    mimetype: "image/png",
  },
  {
    ext: "gif",
    icon: IconFileTypeJpg,
    color: "#8479F1",
    mimetype: "image/gif",
  },
  {
    ext: "jpeg",
    icon: IconFileTypeJpg,
    color: "#8479F1",
    mimetype: "image/jpeg",
  },
  {
    ext: "jpg",
    icon: IconFileTypeJpg,
    color: "#8479F1",
    mimetype: "image/jpg",
  },
  {
    ext: "svg",
    icon: IconFileTypeSvg,
    color: "#aaa",
    mimetype: "image/svg+xml",
  },
  { ext: "mp4", icon: IconFileMusic, color: "#aaa", mimetype: "video/mp4" },
  {
    ext: "zip",
    icon: IconFileTypeZip,
    color: "#aaa",
    mimetype: "application/zip",
  },
  { ext: "csv", icon: IconFileTypeCsv, color: "#aaa", mimetype: "text/csv" },
  {
    ext: "pdf",
    icon: IconFileTypePdf,
    color: "#EA5455",
    mimetype: "application/pdf",
  },
  {
    ext: "doc",
    icon: IconFileTypeDoc,
    color: "#467FFF",
    mimetype: "application/msword",
  },
  {
    ext: "docx",
    icon: IconFileTypeDoc,
    color: "#467FFF",
    mimetype:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {
    ext: "xls",
    icon: IconFileTypeXls,
    color: "green",
    mimetype: "application/vnd.ms-excel",
  },
  {
    ext: "xlsx",
    icon: IconFileTypeXls,
    color: "green",
    mimetype:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  {
    ext: "ppt",
    icon: IconFileTypePpt,
    color: "#EA5455",
    mimetype: "application/vnd.ms-powerpoint",
  },
  {
    ext: "pptx",
    icon: IconFileTypePpt,
    color: "#EA5455",
    mimetype:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  {
    ext: "exe",
    icon: IconFile,
    color: "black",
    mimetype: "application/vnd.microsoft.portable-executable",
  },
];

export const getFileIcon = (mimetype) => {
  let file = supportFiles.find((f) => f.mimetype === mimetype);
  return { c: file?.icon ?? IconFile };
};

export const getFileIconColor = (mimetype) => {
  let file = supportFiles.find((f) => f.mimetype === mimetype);
  return file?.color ?? "#ccc";
};

const K_FILE_LIST_DISPLAY_MODE = {
  GRID: 1,
  LIST: 2,
};

const FileList = ({
  form,
  name,
  title,
  columnSpan = 4,
  disabled,

  ...props
}) => {
  const NO_FILES_MESSAGE = "No files upload yet.";
  const [api] = useServerApi();
  const forceUpdate = useForceUpdate();
  const [allowEdit, setAllowEdit] = useState(true);
  const [uploading, setUploading] = useState(false);
  const auth = useAuthUser();
  const resetRef = useRef(null);

  const [dirty, setDirty] = useState(false);

  const files = _.get(form.values, name) || [];
  const entityId = _.get(form.values, "_id") || [];

  useHotkeys([
    ["ctrl+A", () => selectAll()],
    ["mod+A", () => selectAll()],
    ["backspace", () => deleteSelected()],
    ["delete", () => deleteSelected()],
    ["escape", () => unSelectAll()],
  ]);

  const resetFileButton = () => resetRef.current?.();

  const selectAll = () => {
    files.forEach((f) => (f.selected = true));
    forceUpdate();
  };

  useEffect(() => {
    if (dirty) {
      form.save();
      setDirty(false);
    }
  }, [dirty]);

  const unSelectAll = () => {
    // console.log("unSelectAll");
    files.forEach((f) => (f.selected = false));
    forceUpdate();
  };

  const getSelectedFiles = () => files.filter((f) => f.selected);

  const deleteSelected = () => {
    if (!window.confirm("Are you sure to remove the files?")) return;
    for (let i = files.length - 1; i >= 0; i--) {
      if (files[i].selected) {
        handleRemove(files[i], false);
      }
    }
  };

  const downloadSelected = async () => {
    const links = getSelectedFiles().map((f) => f.link);
    if (!links || links.length == 0) return;
    downloadZipFiles(links);
  };

  const downloadAll = () => {
    const links = files.map((f) => f.link);
    downloadZipFiles(links);
  };

  const downloadZipFiles = async (links) => {
    const filename = "product-files";
    let result = await api.downloadZipFiles(filename, links);
    if (result.success) {
      return window.open(result.data.link, "_blank");
    } else {
      console.log(result);
      showNotification({
        title: `Download Zip Fail`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: "Download Fail",
      });
    }
  };

  const uploadNewFiles = async (uploadFiles) => {
    let newFiles = [];
    try {
      for (const file of uploadFiles) {
        if (!file) continue;

        setUploading(true);
        let result = await api.uploadFile("files", file, entityId, files);
        setUploading(false);

        let { data, success } = result;
        if (success) {
          showNotification({
            title: `Upload successfully`,
            message: "Upload Success",
          });
          newFiles.push(data);
        } else {
          showNotification({
            title: `${file.name} upload unsuccessful`,
            color: "red",
            icon: <IconExclamationMark size={18} />,
            message: "Upload Fail",
          });
        }
      }

      return newFiles;
    } catch (error) {
      throw error;
    }
  };

  const handleUploadFiles = async (uploadFiles) => {
    try {
      // console.log("handleUploadFiles", uploadFiles, files);
      let newFiles = await uploadNewFiles(uploadFiles);
      resetFileButton();

      form.setFieldValue(name, [...files, ...newFiles]);
      setDirty(true);
    } catch (error) {
      // console.log(error);
      showNotification({
        title: `Files upload unsuccessful`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: "Upload Fail",
      });
    }
  };

  const handleRemove = (file, needConfirm = true) => {
    if (
      needConfirm &&
      !window.confirm(`Are you sure to remove the file ${file.name}?`)
    ) {
      return;
    }

    let index = files.findIndex((f) => f._id === file._id);
    // console.log("Remove=>", index, file._id, files);
    if (index === -1) return;

    files.splice(index, 1);
    form.setFieldValue(name, [...files]);
    setDirty(true);
    // form.updateAndSave({ [name]: [...files] });
  };

  const handleEditFileName = (file, editing) => {
    file.editing = editing;
    forceUpdate();
  };

  const setSelected = (file) => {
    if (file.selected) return openFile(file);
    unSelectAll();
    file.selected = true;
    forceUpdate();
  };

  const openFile = (file) => {
    // console.log("open FIle", file);
    if (!file.url) return;
    // console.log("openFile", file);
    window.open(file.url, "_blank");
  };

  const handleCardOnClick = (event, file) => {
    if (event.altKey) {
      // console.log("Alt, yay!");
      file.selected = true;
      return forceUpdate();
    }
    setSelected(file);
  };

  return (
    <Box>
      {/* Dirty:{dirty.toString()}
      <JsonViewer src={files} />
      <JsonViewer src={form.values} /> */}
      {/* entityId:{entityId} */}
      {title && <Text> {title}</Text>}
      {!_.isEmpty(files) && (
        <FilesRenderer
          form={form}
          name={name}
          files={files}
          downloadAll={downloadAll}
          deleteSelected={deleteSelected}
          downloadSelected={downloadSelected}
          handleCardOnClick={handleCardOnClick}
          openFile={openFile}
          disabled={disabled}
          allowEdit={allowEdit}
          columnSpan={columnSpan}
          handleRemove={handleRemove}
        />
      )}
      {_.isEmpty(files) && (
        <Text size="xs" mb={"xl"}>
          {NO_FILES_MESSAGE}
        </Text>
      )}
      {!disabled && (
        <Dropzone
          onDrop={handleUploadFiles}
          onReject={(files) => console.log("rejected files", files)}
          // accept={{
          // 	"image/*": [], // All images
          // 	"text/html": [".html", ".htm"],

          // }}

          // activateOnClick={false}
          {...props}
        >
          <FileButton resetRef={resetRef} onChange={handleUploadFiles} multiple>
            {(props) => (
              <Button {...props} size="xs">
                Upload Files
              </Button>
            )}
          </FileButton>
        </Dropzone>
      )}
    </Box>
  );
};

export default FileList;

const FileTableHeader = ({ textSize = "xs" }) => (
  <Table.Thead>
    <Table.Tr>
      <Table.Th>
        <Text size={textSize}> File</Text>
      </Table.Th>

      <Table.Th>
        <Text size={textSize}> Size</Text>
      </Table.Th>
      <Table.Th>
        <Text size={textSize}> Date</Text>
      </Table.Th>
      <Table.Th></Table.Th>
    </Table.Tr>
  </Table.Thead>
);

const FileTableBody = ({
  form,
  name,
  files,
  textSize = "xs",
  openFile,
  disabled,
  allowEdit,
  handleRemove,
}) => {
  const [rows, setRows] = useState([]);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (dirty) {
      form.updateAndSave({ [name]: rows });
      setDirty(false);
    }
  }, [dirty]);
  useDeepCompareEffect(() => {
    if (!files) setRows([]);
    setRows(_.cloneDeep(files));
  }, [files]);

  const setRowEdit = (row) => {
    row.editing = true;
    setRows([...rows]);
  };

  const updateFiles = () => {
    console.log("updateFiles", name, rows);
    form.updateAndSave({ [name]: rows });
    setDirty(true);
  };

  return (
    <Table.Tbody>
      {rows &&
        rows?.map((t, index) => (
          <Table.Tr key={index}>
            <Table.Td align="left" style={{ minWidth: 250 }}>
              {!t.editing && (
                <Group spacing={"xs"}>
                  {/* <FontAwesomeIcon
                    icon={getFileIcon(t.mimetype)}
                    color={getFileIconColor(t.mimetype)}
                    size="xl"
                  ></FontAwesomeIcon> */}
                  {/* <FontAwesomeIcon icon={faFile} /> */}

                  <UnstyledButton onClick={() => openFile(t)}>
                    <Text size={textSize}> {t.name} </Text>
                  </UnstyledButton>
                </Group>
              )}
              {t.editing && (
                <TextInput
                  size="xs"
                  autoFocus={true}
                  value={t.name}
                  onBlur={() => {
                    t.editing = false;
                    setRows([...rows]);
                    updateFiles();
                  }}
                  onChange={(e) => {
                    t.name = e.currentTarget.value;
                    setRows([...rows]);
                  }}
                />
              )}
            </Table.Td>
            <Table.Td>
              <Text size={textSize}>
                {filesize(t.size, { fixed: 0 }).human("si")}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text size={textSize}>
                {t.created ? moment(t.created).format("LLL") : "--"}
              </Text>
            </Table.Td>
            <Table.Td>
              {!disabled && allowEdit && (
                <Group gap={"xs"}>
                  <ActionIcon
                    onClick={() => setRowEdit(t, true)}
                    variant="default"
                  >
                    <IconPencil size="1rem" />
                  </ActionIcon>
                  <ActionIcon onClick={() => handleRemove(t)} variant="default">
                    <IconTrash size="1rem" />
                  </ActionIcon>
                </Group>
              )}
            </Table.Td>
          </Table.Tr>
        ))}
    </Table.Tbody>
  );
};

const FilesRenderer = ({
  form,
  name,
  files,
  downloadAll,
  deleteSelected,
  downloadSelected,
  handleCardOnClick,
  openFile,
  disabled,
  allowEdit,
  columnSpan,
  uploading,
  handleRemove,
}) => {
  const [displayMode, setDisplayMode] = useState(K_FILE_LIST_DISPLAY_MODE.LIST);
  // let files = _.get(form.values, name) || [];
  return (
    <>
      <Group justify="right" mb={20}>
        {/* <Title order={5} mb={10}>{title}</Title> */}
        <Group spacing="xs" gap={"xs"}>
          <ActionIcon variant="default" onClick={() => form.reload()}>
            <IconRefresh size="1rem" />
          </ActionIcon>
          <ActionIcon
            variant="default"
            onClick={() => setDisplayMode(K_FILE_LIST_DISPLAY_MODE.LIST)}
          >
            <IconList size="1rem" />
          </ActionIcon>
          <ActionIcon
            variant="default"
            onClick={() => setDisplayMode(K_FILE_LIST_DISPLAY_MODE.GRID)}
          >
            <IconLayoutGrid size="1rem" />
          </ActionIcon>
          <FilesActionButton
            deleteSelected={deleteSelected}
            downloadAll={downloadAll}
            downloadSelected={downloadSelected}
          />
        </Group>
      </Group>
      {displayMode === K_FILE_LIST_DISPLAY_MODE.GRID && (
        <FilesGrid
          files={files}
          handleCardOnClick={handleCardOnClick}
          columnSpan={columnSpan}
          uploading={uploading}
        />
      )}

      {displayMode === K_FILE_LIST_DISPLAY_MODE.LIST && (
        <FilesTable
          form={form}
          name={name}
          files={files}
          openFile={openFile}
          disabled={disabled}
          allowEdit={allowEdit}
          handleRemove={handleRemove}
        />
      )}
    </>
  );
};

const FilesActionButton = ({
  downloadSelected,
  downloadAll,
  deleteSelected,
}) => (
  <Menu shadow="md" width={200}>
    <Menu.Target>
      <ActionIcon variant="default">
        <IconDotsVertical size="1rem" />
      </ActionIcon>
    </Menu.Target>

    <Menu.Dropdown>
      <Menu.Label>{`Actions`}</Menu.Label>
      <Menu.Item onClick={downloadSelected} icon={<IconDownload size={14} />}>
        Download Selected
      </Menu.Item>
      <Menu.Item onClick={downloadAll} icon={<IconDownload size={14} />}>
        Download All
      </Menu.Item>
      <Menu.Item onClick={deleteSelected} icon={<IconTrash size={14} />}>
        Delete
      </Menu.Item>
    </Menu.Dropdown>
  </Menu>
);

const FilesTable = ({
  files,
  name,
  form,
  openFile,
  disabled,
  allowEdit,
  handleRemove,
}) => {
  return (
    <>
      {/* <ReactJson src={files} style={{ background: "white" }} /> */}
      <Table mb={300} striped highlightOnHover>
        <FileTableHeader />
        <FileTableBody
          name={name}
          form={form}
          files={files}
          openFile={openFile}
          disabled={disabled}
          allowEdit={allowEdit}
          handleRemove={handleRemove}
        />
      </Table>
    </>
  );
};

const FilesGrid = ({ files, handleCardOnClick, columnSpan, uploading }) => (
  <Grid mb={50}>
    {files &&
      files?.map((file) => (
        <Grid.Col key={file._id || file.id} span={columnSpan}>
          <div style={{ zIndex: 200 }}>
            <FileCard file={file} handleCardOnClick={handleCardOnClick} />
          </div>
        </Grid.Col>
      ))}
    <Center w={300} h={200}>
      {uploading && <Loader variant="bars" />}
    </Center>
  </Grid>
);

const FileCard = ({ file, handleCardOnClick }) => (
  <Card
    withBorder
    shadow="sm"
    radius="md"
    style={{ borderColor: file.selected ? "#A5D8FF" : "" }}
    onClick={(e) => {
      handleCardOnClick(e, file);
    }}
  >
    <Card.Section>
      <Previewer file={file} />
    </Card.Section>
    <Text mt="sm" size="sm">
      {file.name}
    </Text>
    <Group justify="space-between">
      <Text color="dimmed" size="xs">
        {moment(file.created).format("YYYY/MM/DD")}
      </Text>
      <Text mt="sm" color="dimmed" size="xs">
        {filesize(file.size, { fixed: 0 }).human("si")}
      </Text>
    </Group>
  </Card>
);

const Previewer = ({ file }) => {
  const isImage = isImageFile(file.mimetype);
  const isPdf = isPDFFile(file.mimetype);

  const icon = getFileIcon(file.mimetype);
  const color = getFileIconColor(file.mimetype);

  return (
    <>
      {isImage && (
        <div style={{ height: "140px", bakcgorund: "#F8F8F8" }}>
          <Image
            src={`${process.env.REACT_APP_FILE_URL}${file.link}`}
            height={100}
          />

          {/* {`${process.env.REACT_APP_FILE_URL}${file.link}`} */}
        </div>
      )}

      {/* {isPdf && file && file.url && <PdfPreviewer fileUrl={file.url} />} */}

      {!isImage && (
        <Center style={{ height: "140px", background: "#F8F8F8" }}>
          <icon.c size="25" color={color} />
        </Center>
      )}
    </>
  );
};
