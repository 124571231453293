import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { Badge } from "@mantine/core";

export const OrderTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        cellRender: (col, row) => cellRender.Order.code(row),
      },
      // {
      //   field: "product",
      //   headerName: "Product",
      //   sortable: true,
      //   cellRender: (col, row) => {
      //     return row.product ? row.product.name : "";
      //   },
      // },
      {
        field: "client",
        headerName: "Client",
        cellRender: (col, row) => {
          return cellRender.Client(row.client);
        },
      },

      {
        field: "referer",
        headerName: "Referer",
        cellRender: (col, row) => {
          return cellRender.Client(row.client?.referer);
        },
      },
      {
        field: "payment",
        headerName: "Payment",
        cellRender: (col, row) => {
          return cellRender.Currency(row.payment);
        },
      },
      {
        field: "payback",
        headerName: "Payback Count",
        cellRender: (col, row) => {
          return (
            <>
              {row.fullyPayBack && (
                <Badge radius={0}>已回本({row.paybackCount})</Badge>
              )}
              {!row.fullyPayBack && <>{row.paybackCount}</>}
            </>
          );
        },
      },
      // {
      //   field: "status",
      //   headerName: "Status",
      //   sortable: true,
      //   cellRender: (col, row) => cellRender.Status(row.status),
      // },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
    searchByTextField: true,
  };
};
