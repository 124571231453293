import React, { useState } from "react";
import _ from "lodash";
import { PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";

const ProjectQuotationList = ({ form }) => {
  const _id = _.get(form.values, "_id");
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  // const [api] = useServerApi();
  // const fetch = async ()=>{
  //   if (!_id) return;
  //   const result = await api.
  // }
  // useEffect(() => {
  //   fetch();
  // }, [_id]);

  return (
    <>
      <PageList
        hideActionButton={true}
        hideSearchBar={true}
        withPaper={false}
        pt={0}
        px={0}
        apiEntity={"quotation"}
        filterQuery={{ project: _id }}
        tableSchema={getTableSchema("quotation")}
        formSchema={getFormSchema("quotation")}
        showMinAddButton={true}
      />
    </>
  );
};

export default ProjectQuotationList;
