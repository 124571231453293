import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
  Box,
  Flex,
  Card,
  Grid,
} from "@mantine/core";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";
import { IconAlertTriangle } from "@tabler/icons-react";
import JsonViewer from "./common/jsonViewer";
import InvoicePaymentHistory from "./invoicePaymentHistory";

const calculateTotal = (
  charges,
  discountAmount,
  paymentPercentage,
  paidAmount
) => {
  if (_.isEmpty(charges)) return { sumTotal: 0, total: 0 };

  charges.forEach((c) => {
    c.price = (c.qty ?? 1) * c.unitPrice;
  });

  const sumTotal = +charges
    ?.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0)
    .toFixed(2);

  const total = +(sumTotal - discountAmount).toFixed(2);
  const amount = (total * paymentPercentage) / 100;
  const balance = amount - paidAmount;

  console.log("calculateTotal = ", sumTotal, total, amount, balance);
  return { sumTotal, total, amount, balance };
};

const InvoiceItems = ({ form, name }) => {
  const items = _.get(form.values, "rows");
  const { amount, balance } = form.values ?? {}; //Invoice
  const total = _.get(form.values, "total") ?? 0;
  const sumTotal = _.get(form.values, "sumTotal") ?? 0;
  const paidAmount = _.get(form.values, "paidAmount") ?? 0;
  const paymentPercentage = _.get(form.values, "paymentPercentage") ?? 0;

  // const [total, setTotal] = useState(_.get(form.values, "total") ?? 0);
  // const [sumTotal, setSumTotal] = useState(_.get(form.values, "sumTotal") ?? 0);
  const discountAmount = _.get(form.values, "discountAmount") ?? 0;

  const [formatter] = useFormatter();
  const forceUpdate = useForceUpdate();

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: Textarea,
      props: {
        autosize: true,
        minRows: 1,
      },
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },
    // {
    //   header: "Currency",
    //   headerWidth: "10%",
    //   fieldName: "currency",
    //   component: Select,
    //   props: {
    //     data: DATA_CURRENCY,
    //     defaultValue: "HKD",
    //     searchable: true,
    //   },
    // },
    {
      header: "Unit Price",
      headerWidth: "15%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
    {
      header: "Price",
      headerWidth: "15%",
      fieldName: "price",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
  ];

  const onItemChange = (charges) => {
    const { sumTotal, total, amount, balance } = calculateTotal(
      charges,
      discountAmount,
      paymentPercentage,
      paidAmount
    );
    form.setFieldValue("rows", charges);
    form.setFieldValue("total", total);
    form.setFieldValue("sumTotal", sumTotal);
    form.setFieldValue("amount", amount);
    form.setFieldValue("balance", balance);
  };

  useEffect(() => {
    const { sumTotal, total, amount, balance } = calculateTotal(
      items,
      discountAmount,
      paymentPercentage,
      paidAmount
    );
    form.setFieldValue("total", total);
    form.setFieldValue("sumTotal", sumTotal);
    form.setFieldValue("amount", amount);
    form.setFieldValue("balance", balance);
  }, [discountAmount, paymentPercentage]);

  return (
    <>
      {/* <JsonViewer src={form.values} /> */}
      {/* total:{total} */}
      <ArrayTable
        form={form}
        name={"rows"}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onItemChange}
      />
      <Divider my="xl" />
      <Grid>
        <Grid.Col span={6}>
          {/* <InvoicePaymentHistory form={form} /> */}
        </Grid.Col>

        <Grid.Col span={6}>
          <Box>
            <Group justify="space-between" mb="xs">
              <Text size="xs" c={"dimmed"}>
                Sum Total
              </Text>
              <Text>{formatter.currency(sumTotal)}</Text>
            </Group>
            <Group justify="space-between" mb={"xs"}>
              <Text size="xs" c={"dimmed"}>
                Discount
              </Text>
              <NumberInput
                precision={2}
                thousandSeparator=","
                placeholder="10"
                value={discountAmount}
                size="xs"
                onChange={(v) => {
                  form.setFieldValue("discountAmount", v);

                  calculateTotal(items, v, paymentPercentage);
                }}
              />
            </Group>
            <Divider variant="dotted" my={"lg"} />
            <Group justify="space-between" mb="xs">
              <Text size="xs" c={"dimmed"}>
                Total
              </Text>
              <Text>{formatter.currency(total)}</Text>
            </Group>

            <Group justify="space-between" mb="xs">
              <Text size="xs" c={"dimmed"}>
                Payment %
              </Text>
              <NumberInput
                precision={2}
                thousandSeparator=","
                placeholder="10"
                value={paymentPercentage}
                size="xs"
                onChange={(v) => {
                  form.setFieldValue("paymentPercentage", v);
                  // calculateTotal(items, discountAmount, v, paidAmount);
                }}
              />
            </Group>

            <Group justify="space-between" mb="xs">
              <Text size="xs" c={"dimmed"}>
                Invoice Amount
              </Text>
              <Text>{formatter.currency(amount)}</Text>
            </Group>
            {/* <Divider variant="dotted" my={"lg"} />

            <Group justify="space-between" my="xs">
              <Text>Total Due</Text>
              <Title order={3}>{formatter.currency(balance)}</Title>
            </Group> */}
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default InvoiceItems;
