import React from "react";
import { Card, Text } from "@mantine/core";

const ClientHome = () => {
  return (
    <Card>
      <Text>Welcome Client</Text>
    </Card>
  );
};

export default ClientHome;
