import React, { useEffect, useState } from "react";

import {
  Text,
  TextInput,
  Select,
  Checkbox,
  Button,
  Group,
  InputWrapper,
  NumberInput,
  Divider,
  Title,
  Card,
  Textarea,
  Table,
} from "@mantine/core";

import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import { useCellRender } from "../hooks/useCellRender";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import {
  DATA_PAYMENT_METHODS,
  DATA_PAYMENT_STATUS,
  DATA_BANK,
} from "../data/options";
import RemoteSelect2 from "./remoteSelect2";

const ClientPositionInfo = ({ form }) => {
  const { position, referer } = form.values;
  const [cellRender] = useCellRender();

  const clientId = form.values?._id;
  return (
    <>
      {/* <JsonViewer src={form.values.referer} /> */}
      <Table withRowBorders={false}>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>Position</Table.Td>
            <Table.Td>
              <RemoteSelect2
                apiEntity="position"
                valueField="_id"
                value={form.values.position}
                pageSize={100}
                onDataChange={(value) => {
                  form.setFieldValue("position", value);
                }}
                searchFields={["name", "cname", "code"]}
                labelRender={(item) => `${item.name}`}
              />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Referer</Table.Td>
            <Table.Td>
              <RemoteSelect2
                form={form}
                name="referer"
                value={form.values.referer}
                apiEntity="client"
                valueField="_id"
                pageSize={100}
                //Prequery not eqaul to current client
                preQuery={{ _id: { $ne: clientId } }}
                onDataChange={(value) => {
                  console.log("onChange", value);
                  form.setFieldValue("referer", value);
                }}
                searchFields={["name", "cname"]}
                labelRender={(item) =>
                  `[ ${item.phone} ] ${item?.name}  ( ${item.position?.name} )`
                }
              />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  );
};

export default ClientPositionInfo;
