import React from "react";
import {
  Card,
  Group,
  InputWrapper,
  Table,
  Divider,
  Text,
  Title,
  ActionIcon,
  TextInput,
  Textarea,
  NumberInput,
} from "@mantine/core";
import ActionCellRender from "./actionCellRender";
import { IconPencil, IconPrinter, IconTrash } from "@tabler/icons-react";

import { PageList, ENUM_FORM_DISPLAY } from "./pageList";
import { DateInput, DatePickerInput } from "@mantine/dates";
import AutoCodeInput from "./autoCodeInput";
import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";
import { useFormatter } from "../hooks/useFomatter";

const PaymentHistory = ({ form: mainForm }) => {
  const [cellRender] = useCellRender();
  const invoice = mainForm.values;
  const [formatter] = useFormatter();
  const formSchema = {
    title: "Invoice Payment",
    isMainForm: false,
    display: {
      mode: ENUM_FORM_DISPLAY.DRAWER,
      size: "xl", //xs, sm, xl  ....100%
      position: "right",
    },
    apiEntity: "invoicePayment",

    initialValues: {
      invoice: invoice._id,
    },

    layout: {
      fields: [
        {
          name: "code",
          component: AutoCodeInput,
          props: {
            required: true,
            label: "Code",
            placeholder: "",
          },
        },
        {
          name: "paymentDate",
          component: DateInput,
          type: "dateInput",

          props: {
            label: "Payment Date",
            zIndex: 1000,
            placeholder: "Pick Date",
            allowFreeInput: true,
          },
        },
        {
          name: "amount",
          component: NumberInput,
          props: {
            required: true,
            label: "Amount",
            thousandSeparator: ",",
          },
        },

        {
          name: "remark",
          component: TextInput,
          props: {
            // required: true,
            label: "Remark",
            placeholder: "",
            mb: "xl",
          },
        },
      ],
    },
  };
  const tableSchema = {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },
      {
        field: "paymentDate",
        headerName: "Payment Date",
        sortable: true,
        cellRender: (data) => moment(data.paymentDate).format("ll"),
        // cellRender: (data) => {
        //   moment(data.paymentDate).format("YYYY-MM-DD");
        // },
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
          {
            name: "print",
            icon: IconPrinter,
            props: {
              //   color:"red"
              label: "Print Receipt",
              onclick: () => {
                console.log("Print click");
              },
            },
          },
        ],
        // actionMenu: [
        //   {
        //     name: "print",
        //     label: "Print Receipt",
        //     icon: IconPrinter,
        //     props: {},
        //   },
        // ],
      },
    ],
  };

  const handleCustomAction = (action, payment) => {
    if (action === "print") {
      console.log("Print click", payment);
    }
  };
  return (
    invoice._id && (
      <>
        <PageList
          showMinAddButton={true}
          hideActionButton={true}
          hidePagination={true}
          debug={false}
          formSchema={formSchema}
          tableSchema={tableSchema}
          hideSearchBar={true}
          defaultPageSize="100000"
          apiEntity="invoicePayment"
          withPaper={false}
          tablePadding="0"
          preQueryNotEmpty={true}
          preQuery={{ invoice: invoice._id }}
          postSubmit={() => mainForm.reload()}
          postDelete={() => mainForm.reload()}
          handleCustomAction={handleCustomAction}
        />

        {invoice.paidAmount > 0 && <Divider variant="dotted" mx="xl" mb="xl" />}
        <Group justify="right" px="md">
          <Text size="xs" c={"dimmed"}>
            Paid Amount
          </Text>
          <Text>{formatter.currency(invoice.paidAmount)}</Text>
        </Group>

        <Group justify="right" px="md">
          <Divider variant="dotted" my={"lg"} />
          <Text size="xs" c={"dimmed"}>
            Total due
          </Text>
          <Title order={3} c={invoice.balance > 0 ? "red" : "green"}>
            {formatter.currency(invoice.balance)}
          </Title>
        </Group>
      </>
    )
  );
};

export default PaymentHistory;
