import React, { useCallBack, useState } from "react";
import axios from "axios";
import { useAuthHeader, useSignOut } from "react-auth-kit";
import { authName } from "../App";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark } from "@tabler/icons-react";
import _ from "lodash";

export const useServerApi = () => {
  const navigate = useNavigate();
  const authName = `${process.env.REACT_APP_AUTH_NAME}`;

  const token = localStorage.getItem(authName);

  /**
   * @function getHeader
   * @description create the request header
   * @param {*} method
   * @param {*} body
   * @returns
   */
  const getHeader = (method = "GET", body) => {
    switch (method) {
      case "GET":
        return {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Origin": "http://localhost:3000", // Add the appropriate origin here
          },
        };

      case "POST": {
        return {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(body),
        };
      }

      case "DELETE": {
        return {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
          },
        };
      }

      case "PUT": {
        return {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(body),
        };
      }
    }
  };

  /**
   * @function handleResponseError
   * @param {*} result
   * @returns
   */
  const handleResponseError = (result) => {
    if (result.success) return;
    console.log("handleResponseError", result.message);
    if (result.message && result.message === "ERR_INVALID_TOKEN") {
      navigate("/login");
    }
  };

  const aggregate = async ({ apiEntity, aggregations }) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/aggregate`;

    try {
      let result = await fetch(url, getHeader("POST", { aggregations }));
      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);

      return result.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const loadFile = async (url) => {
    try {
      let result = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
          "Access-Control-Allow-Origin": "*",
        },
      });
      console.log(result);
      result = await result.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const search = async ({
    apiEntity,
    pageSize = 50,
    currentPage = 1,
    sort,
    searchQuery,
    searchText,
    searchByTextField,
    preQuery,
    select,
    byAggregation = false,
  }) => {
    try {
      let url = `${
        process.env.REACT_APP_SERVER_URL
      }/${apiEntity}/search?limit=${pageSize}&page=${currentPage - 1}`;

      if (sort) {
        url += sort.by ? `&sort=${sort.by}` : "";
        url += sort.by ? `&order=${sort.order}` : "";
      }

      if (select) {
        url += `&select=${select}`;
      }

      let result = await fetch(
        url,
        getHeader("POST", {
          condition: searchQuery,
          byAggregation,
          searchByTextField,
          searchText,
          preQuery,
        })
      );

      result = await result.json();
      // console.log(result);
      if (!result || !result.success) return handleResponseError(result);
      return result.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const add = async ({ apiEntity, values }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}`,
        getHeader("POST", values)
      );
      result = await result.json();

      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const removeById = async ({ apiEntity, id }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/${id}`,
        getHeader("DELETE")
      );
      result = await result.json();
      if (!result.success && result.error) throw result.error;
      return result;
    } catch (error) {
      console.log(error);
      showNotification({
        title: `Delete fail`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: _.isString(error) ? error : error?.message ?? error?.msg,
      });
    }
  };

  const getById = async ({ apiEntity, id }) => {
    try {
      if (!id || !apiEntity) return null;
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/${id}`,
        getHeader("GET")
      );
      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);

      return result.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getManyByIds = async ({ apiEntity, ids }) => {
    try {
      if (!ids || !apiEntity) return null;
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/getManyByIds`,
        getHeader("POST", { ids })
      );
      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);

      return result.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateById = async ({ apiEntity, values, id }) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/${id}`,
        getHeader("PUT", values)
      );
      result = await result.json();
      // console.log("Update", apiEntity, result, values);
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const importBatch = async ({ apiEntity, file }) => {
    try {
      let data = new FormData();
      data.append("file", file);
      data.append("filename", file.name); // use orginial name
      data.append("token", token);

      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/${apiEntity}/importBatch`,
        {
          method: "POST",
          body: data,
          redirect: "follow",
        }
      );
      result = await result.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const getErrorMsg = (error) => {
    try {
      if (typeof error != "object") return error;
      let msg = Object.entries(error)
        .map(([key, value]) => value.message)
        .join(" ,");
      // console.log(msg);
      return msg;
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async (uploadFolder, file, entityId, exisitingFiles) => {
    try {
      let data = new FormData();

      data.append("file", file);
      data.append("filename", file.name);
      data.append("exisitingFiles", JSON.stringify(exisitingFiles));
      data.append("entityId", entityId);
      data.append("uploadFolder", uploadFolder);
      data.append("token", token);
      // console.log(
      //     "uploadFile",
      //     `${process.env.REACT_APP_SERVER_URL}/file/upload/${uploadFolder}`
      // );
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/file/upload`,
        {
          method: "POST",
          body: data,
          redirect: "follow",
        }
      );

      result = await result.json();
      if (!result || !result.success) return handleResponseError(result);

      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const downloadZipFiles = async (filename, links) => {
    try {
      let result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/file/zip`,
        getHeader("POST", { filename, files: links })
      );

      result = await result.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const getActivityLog = async (entityId, commentOnly = false) => {
    try {
      if (!entityId) return null;
      const apiEntity = "activityLog";
      const pageSize = 10;
      const currentPage = 1;
      // const sort  ={ by:'createdAt', order:'desc'}
      // const searchQuery = { entityId : '62f4bc38cf33e13de29c6e84' }
      const searchQuery = commentOnly
        ? { entityId, type: "COMMENT" }
        : { entityId };
      let result = await search({
        apiEntity,
        pageSize,
        currentPage,
        searchQuery,
      });
      // console.log(result)
      return result.docs;
    } catch (error) {
      return null;
    }
  };

  const addCommentLog = async ({ entityId, title, by }) => {
    try {
      if (!entityId) return null;
      const values = {
        entityId,
        type: "COMMENT",
        title,
        by,
      };
      let result = await add({ apiEntity: "activityLog", values });
      return result;
    } catch (error) {
      return null;
    }
  };

  const Report = {
    getCommissionReport: async ({ fromDate, toDate }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/commission/getReport`,
          getHeader("POST", { fromDate, toDate })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getClientReport: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getReport`,
          getHeader("POST", {})
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    getCreditReport: async ({ fromDate, toDate }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/credit/getReport`,
          getHeader("POST", { fromDate, toDate })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Order = {
    getReport: async ({ fromDate, toDate }) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/order/getReport`,
          getHeader("POST", { fromDate, toDate })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Dashboard = {
    getOrderStats: async (period) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/dashboard/getOrderStats`,
          getHeader("POST", { period })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const UserRole = {
    //Get all api list
    getAPIList: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/userRole/getAPIList`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data.functions ?? [];
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Promotion = {
    getStatSummary: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/promotion/getStatSummary`,
          getHeader("GET")
        );

        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    accept: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/promotion/accept/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    decline: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/promotion/decline/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    revert: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/promotion/revert/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Payroll = {
    getPendingCommission: async (payrollId, payrollType = "NONPAYBACK") => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/getPendingCommission/${payrollId}`,
          getHeader("POST", { payrollType })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getPaymentDetail: async (paymentId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payrollPayment/getDetail/${paymentId}`,
          getHeader("GET")
        );
        result = await result.json();
        console.log(result);
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    complete: async (payrollId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/complete/${payrollId}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    printDetail: async (payrollId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/payroll/printDetail/${payrollId}`,
          getHeader("GET")
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const Client = {
    getTreeData: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/client/getTreeData`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },
  };

  const User = {
    signIn: async (email, password) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/authenticate`,
          getHeader("POST", {
            email,
            password,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    resetPassword: async (email) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/resetPassword`,
          getHeader("POST", {
            email,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getListGroupByRole: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getListGroupByRole/`,
          getHeader("GET")
        );
        result = await result.json();
        // console.log("API User getListGroupByRole ",result)
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    getNonReleaseCommissionBidsCount: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getNonReleaseCommissionBidsCount/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    getReport: async () => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getReport`,
          getHeader("GET")
        );

        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getNonSettledBidsCount: async (id) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getNonSettledBidsCount/${id}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    getClients: async (id, soleAgent = "true") => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getClients/${id}/${soleAgent}`,
          getHeader("GET")
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    terminate: async (termination) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/terminate`,
          getHeader("POST", { termination })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    changePassword: async ({ id, oldPassword, newPassword, newPassword2 }) => {
      try {
        // console.log(id, oldPassword, newPassword, newPassword2);

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/changePassword`,
          getHeader("POST", {
            id,
            oldPassword,
            newPassword,
            newPassword2,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        throw error;
      }
    },

    checkLicence: async (id, productTypeId) => {
      try {
        if (!id || !productTypeId) return;

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/checkLicence`,
          getHeader("POST", {
            id,
            productType: productTypeId,
          })
        );
        result = await result.json();
        return result.data;
      } catch (error) {
        throw error;
      }
    },

    setStatus: async (id, status) => {
      try {
        if (!status || !id) return;
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/setStatus/${id}`,
          getHeader("POST", {
            status,
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        throw error;
      }
    },

    getWallets: async (userId) => {
      try {
        if (!userId) return [];
        const result = await search({
          apiEntity: "wallet",
          searchQuery: {
            $or: [
              { user: userId },
              {
                "members.user": {
                  $in: [userId],
                },
              },
            ],
          },
        });

        // console.log("FetchWallet", result);
        return result.docs ?? [];
      } catch (error) {
        console.log(error);
      }
    },

    getForcastPayroll: async (userId) => {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getForcastPayroll/${userId}`,
          getHeader("POST", {
            period: 1, //1year
          })
        );
        result = await result.json();
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getTeamMembers: async (userId, numlevel = 2) => {
      try {
        if (!userId) return [];

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getTeamMembers/${userId}`,
          getHeader("POST", {
            numlevel, // Number of level
          })
        );

        result = await result.json();
        return result.data.teamMembers ?? [];
      } catch (error) {
        console.log(error);
      }
    },
    getOrderStatics: async (userId, period) => {
      try {
        if (!userId) return;

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/getOrderStatics/${userId}`,
          getHeader("POST", {
            period, //
          })
        );

        result = await result.json();
        return result.data;
      } catch (error) {
        console.log(error);
      }
    },

    disableCreateOrder: async (userId, disabled) => {
      try {
        console.log("disabled", disabled);
        if (!userId) return;

        let result = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/user/disableCreateOrder/${userId}`,
          getHeader("POST", {
            disabled, //
          })
        );

        result = await result.json();

        return result;
      } catch (error) {}
    },
  };

  // return [searchApi, addApi, deleteApi ]
  const api = {
    User,
    UserRole,
    Promotion,
    Order,
    Payroll,
    Report,
    Dashboard,
    Client,
    search,
    aggregate,
    add,
    loadFile,
    removeById,
    getById,
    getManyByIds,
    updateById,
    getErrorMsg,
    uploadFile,
    downloadZipFiles,
    importBatch,

    getActivityLog,
    addCommentLog,
  };
  return [api];
};
