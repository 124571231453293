import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";

export const ProjectTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    searchableFields: ["name", "code", "status"],

    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "name",
        headerName: "Name",
        sortable: true,
      },
      {
        field: "duration.start",
        headerName: "Date Time",
        sortable: true,
        cellRender: (col, data) => moment(data?.duration.start).format("LLL"),
      },
      {
        field: "client.name",
        headerName: "Client",
        sortable: true,
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};
