import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Title,
  Box,
  useMantineTheme,
  Group,
  Chip,
  Select,
  Center,
} from "@mantine/core";
import { IconDatabaseOff } from "@tabler/icons-react";

const NoDataDisplay = () => {
  return (
    <Center h={"100%"}>
      <span>
        <IconDatabaseOff size={50} color="lightgray" />
        <Text c="dimmed" size="xs">
          No Data
        </Text>
      </span>
    </Center>
  );
};

export default NoDataDisplay;
