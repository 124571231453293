import React from "react";
import { useServerApi } from "../hooks/useServerApi";
import { Grid, Text, TextInput, Textarea } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import moment from "moment";

const QuotationProjectSection = ({ form }) => {
  const project = _.get(form.values, "project");

  const setProject = (data) => {
    if (!data) return;
    const attn = _.isEmpty(data.client.contactPersons)
      ? null
      : data.client.contactPersons[0].name;
    form.setFieldValue("project", data);
    form.setFieldValue("program", data.name);
    form.setFieldValue("location", data.address);
    form.setFieldValue("client", data.client);
    form.setFieldValue("tel", data.client);
    form.setFieldValue("email", data.client.email);
    form.setFieldValue("tel", data.client.phoneNumber);
    form.setFieldValue("fax", data.client.fax);
    form.setFieldValue("address", data.client.address);

    if (attn) form.setFieldValue("attn", attn);

    const { start, end } = data.duration;
    const startDate = start ? moment(start).format("ll") : "";
    const endDate = end ? moment(end).format("ll") : "";
    const eventDate =
      startDate === endDate ? startDate : `${startDate} to ${endDate}`;
    if (eventDate) form.setFieldValue("eventDate", eventDate);
  };

  return (
    <>
      {/* <JsonViewer src={form.values} /> */}
      <Grid cols={12} gap={0} style={{ marginBottom: 10 }}>
        <Grid.Col span={6}>
          <RemoteSelect2
            label="Project"
            apiEntity="project"
            labelField="name"
            valueField="_id"
            labelRender={(data) => `${data.name}`}
            multiple={false}
            searchFields={["name", "code", "staffId"]}
            placeholder="Project"
            clearable={true}
            value={project?._id}
            onDataChange={(data) => {
              setProject(data);
              console.log("data", data);
              //   item.content["user"] = data ?? [];
              //   forceUpdate();
            }}
            sort={{
              by: "name",
              order: "asc",
            }}
          />
          <Textarea
            label="Location"
            {...form.getInputProps("location")}
            mt="xs"
            mb="xs"
            autosize
            minRows={1}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            label="Program Name"
            {...form.getInputProps("program")}
            mb="xs"
          />
          <TextInput
            label="Event/Service Date"
            mb="xs"
            {...form.getInputProps("eventDate")}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default QuotationProjectSection;
