import { Select, TextInput, Textarea, Text } from "@mantine/core";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import AutoCodeInput from "../../components/autoCodeInput";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons-react";
import { AssignRuleRow } from "../../components/AssignRuleRow";
import OrderList from "../../components/orderList";
import AssignRuleTester from "../../components/assignRuleTester";
import ActivityLog from "../../components/activityLog";
import FileList from "../../components/fileList";

export const ItemGroupFormSchema = {
  title: "Item Group",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity: "itemGroup",

  initialValues: {
    code: "",
    name: "",
    cname: "",
  },

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Group Setting",
        },
        tab: [
          { key: "t1-1", label: "Group Setting", icon: IconSettings },
          { key: "t1-tester", label: "Tester", icon: IconSettings },
          { key: "t1-2", label: "Files", icon: IconSettings },
          {
            key: "t1-3",
            label: "Logs",
            icon: IconSettings,
          },
        ],
      },
      {
        key: "section-basicInfo",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          description: "Please fill in the basic info of product",
        },
      },
      {
        key: "section-assignRule",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Item Auto Assignment Rules",
          description: "Please fill in the basic info of product",
        },
      },
      {
        key: "section-tester",
        parent: "t1-tester",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Item Group Dimension",
          description: "Please fill in the basic info of product",
        },
      },
      {
        key: "section-file",
        parent: "t1-2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          description: "Please fill in the basic info of product",
        },
      },
      {
        key: "section-log",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          description: "Please fill in the basic info of product",
        },
      },
      {
        key: "basicInfo-g",
        parent: "section-basicInfo",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basicInfo-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "basicInfo-g2",
            props: {
              span: 6,
            },
          },
        ],
      },
    ],
    fields: [
      //   {
      //     name: "code",
      //     component: AutoCodeInput,
      //     props: {
      //       required: true,
      //       label: "Code",
      //       placeholder: "",
      //     },
      //   },
      {
        name: "name",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "basicInfo-g2",

        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },

      {
        name: "description",
        component: Textarea,
        parent: "section-basicInfo",
        props: {
          // required: true,
          label: "Description",
          placeholder: "",
          mb: "xl",
        },
      },
      {
        name: "autoAssignRules",
        component: OrderList,
        parent: "section-assignRule",
        props: {
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: AssignRuleRow,
          },
        },
      },
      {
        name: "autoAssignRules",
        component: AssignRuleTester,
        parent: "section-tester",
        props: {},
      },
      {
        name: "files",
        component: FileList,
        parent: "section-file",
        props: {},
      },

      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
    ],
  },
};
