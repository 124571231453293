import React from "react";
import { Page } from "../layout/page";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Card } from "@mantine/core";
import "../sass/react-big-calendar/sass/styles.scss"; // Import the default styles

const localizer = momentLocalizer(moment);

const Calendars = ({ title = "Calendar" }) => {
  const calendarStyle = {
    backgroundColor: "white",
    color: "black",
    height: 600,
    // other styles
  };

  return (
    <Page title={title}>
      {/* <Card style={{ background: "white" }}> */}
      <BigCalendar
        localizer={localizer}
        // events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={calendarStyle}
        selectable
        onSelectSlot={(slotInfo) => {
          console.log(slotInfo);
        }}
      />
      {/* </Card> */}
    </Page>
  );
};

export default Calendars;
