import _ from "lodash";
import { ActionIcon } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";
import { DateInput } from "@mantine/dates";
import { useServerApi } from "../hooks/useServerApi";
import { PageList } from "./pageList";
import { useFormSchema } from "../hooks/useFormSchema";
import { useCellRender } from "../hooks/useCellRender";
import { IconExternalLink } from "@tabler/icons-react";
import ActionCellRender from "./actionCellRender";

const ClientCommissionList = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();
  const clientId = _.get(form.values, "_id");
  const [api] = useServerApi();

  const [cellRender] = useCellRender();
  // const fetch = async () => {
  //   try{
  //     const result = await api.sear

  //   }catch(error){
  //     console.log("Error in OrderCommissionList fetch", error);

  //   }
  // }

  const tableSchema = {
    showActionColumn: false,
    searchableFields: ["name", "code"],

    columns: [
      {
        field: "status",
        headerName: "Status",
        sortable: true,
      },
      {
        field: "code",
        headerName: "Code",
        sortable: true,
        // cellRender: (col, row) => cellRender.Order.code(row),
      },
      {
        field: "type",
        headerName: "Type",
        sortable: true,
      },
      {
        field: "order.client",
        headerName: "From",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.order?.client),
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, row) => cellRender.Currency(row.amount),
      },
      {
        field: "level",
        headerName: "Level",
        sortable: true,
      },
    ],
  };

  return (
    clientId && (
      <PageList
        apiEntity="commission"
        preQuery={{ client: clientId }}
        preQueryNotEmpty={true}
        tableSchema={tableSchema}
        hideSearchBar={true}
        hideActionButton={true}
        withPaper={false}
        pt={0}
        px={0}
      />
    )
  );
};

export default ClientCommissionList;
