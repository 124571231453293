import React, { useState, useEffect } from "react";
import RemoteSelect2 from "../components/remoteSelect2";

import { useServerApi } from "../hooks/useServerApi";
import { Page } from "../layout/page";
import TreeView, { flattenTree } from "react-accessible-treeview";
import ReactJson from "react-json-view";
import _ from "lodash";
import { Badge, Group, Text } from "@mantine/core";
import "../css/tree.css";
import {
  IconArrowDown,
  IconArrowRight,
  IconSquareArrowRight,
  IconSquareMinus,
  IconSquarePlus,
  Iconlolo,
} from "@tabler/icons-react";
import { IconSquareRoundedMinus2 } from "@tabler/icons-react";
import { IconArrowRightSquare } from "@tabler/icons-react";

// import Tree from "react-d3-tree";
const sampleData = {
  name: "CEO",
  children: [
    {
      name: "Manager",
      attributes: {
        department: "Production",
      },
      children: [
        {
          name: "Foreman",
          attributes: {
            department: "Fabrication",
          },
          children: [
            {
              name: "Worker",
            },
          ],
        },
        {
          name: "Foreman",
          attributes: {
            department: "Assembly",
          },
          children: [
            {
              name: "Worker",
            },
          ],
        },
      ],
    },
  ],
};

// export const TreeDiagram = ({
//   zoomable = true,
//   collapsible = true,
//   draggable = true,
//   pathFunc = "step",
//   initialDepth = 1,
//   separation = { siblings: 0.4, nonSiblings: 1 },

//   transitionDuration = 500,
//   centeringTransitionDuration = 500,
//   translate = { x: 500, y: 50 },
//   orientation = "horizontal",
//   data,
// }) => {
//   return (
//     <Tree
//       data={data}
//       orientation={orientation}
//       pathFunc={pathFunc}
//       collapsible={collapsible}
//       zoomable={zoomable}
//       draggable={draggable}
//       separation={separation}
//       transitionDuration={transitionDuration}
//       centeringTransitionDuration={centeringTransitionDuration}
//       initialDepth={initialDepth}
//       translate={translate}
//       rootNodeClassName="node__root"
//       branchNodeClassName="node__branch"
//       leafNodeClassName="node__leaf"
//     />
//   );
// };
const TeamTree = ({}) => {
  const [data, setData] = useState([]);
  const [api] = useServerApi();

  const fetchTreeData = async () => {
    try {
      const data = await api.Client.getTreeData();
      console.log("Tree Data result = ", data);

      if (!data || !data.tree) return;

      setData(data);

      // if (!result || !result.data) {
      //   setData(result.data);
      // }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchTreeData();
  }, []);

  return (
    <>
      {/* <ReactJson src={data} />
      <ReactJson src={data2} /> */}

      <Page title={"Team Tree"}>
        <div
          id="treeWrapper"
          style={{
            backgroundColor: "white",
            padding: 20,
          }}
        >
          {!_.isEmpty(data) && (
            <>
              <Group gap={"xs"}>
                <Text size="md">Team Members</Text>
                <Badge size="xs" color="blue">
                  {data.total ?? 0}
                </Badge>
              </Group>
              <TreeView
                data={data.tree}
                className="basic"
                aria-label="basic example tree"
                nodeRenderer={({
                  element,
                  getNodeProps,
                  level,
                  isBranch,
                  isExpanded,
                  handleSelect,
                }) => (
                  <div
                    {...getNodeProps()}
                    style={{ paddingLeft: 50 * (level - 1) }}
                  >
                    <Group style={{ cursor: "pointer" }} mb={2}>
                      {/* {isBranch && isExpanded && <IconSquareArrowRight />}
                    {isBranch && !isExpanded && <IconArrowDow />} */}
                      <Text fw={isExpanded ? 500 : 200}>{element.name}</Text>
                      {element.children.length > 0 && (
                        <Badge size="xs">{element.children.length}</Badge>
                      )}
                    </Group>
                  </div>
                )}
              />
            </>
          )}
          {/* {data && <TreeDiagram data={data} />} */}
        </div>
      </Page>
    </>
  );
};

export default TeamTree;
