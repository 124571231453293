import { Text, Title, createStyles, Button, Group, Space } from "@mantine/core";

import { IconPlus } from "@tabler/icons-react";

// function PageListHeader( {title, handleAdd, handleRefresh, handleClose}){
function PageListHeader({ title, actionButtons }) {
  return (
    <>
      <Group justify="space-between">
        <Title>{title}</Title>
        <span>
          <Group>
            {actionButtons.map(({ onClick, label, variant = "filled" }) => (
              <Button size="xs" key={label} onClick={onClick} variant={variant}>
                {label}
              </Button>
            ))}
          </Group>
        </span>
      </Group>
      <Space h="lg" />
    </>
  );
}

export default PageListHeader;
