import React, { useEffect, useState } from "react";

import {
  Text,
  TextInput,
  Select,
  Checkbox,
  Button,
  Group,
  InputWrapper,
  NumberInput,
  Divider,
  Title,
  Card,
  Textarea,
  Table,
} from "@mantine/core";

import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import { useCellRender } from "../hooks/useCellRender";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import { DATA_PAYMENT_METHODS, DATA_PAYMENT_STATUS } from "../data/options";

const OrderPayment = ({
  form,
  name,
  showInput = true,
  title = "Payment Details",
  showDivider = true,
  showPayLater = true,
  showDiscount = false,
  showStatus = false,
  rowMb = "0",
}) => {
  const {
    productSnapshot,
    product,
    discount,
    total,
    netTotal,
    payment,
    payLater,
    client,
    clientSnapshot,
  } = form.values;
  const [cellRender] = useCellRender();

  useEffect(() => {
    if (!product || !productSnapshot) return;
    //Update total
    if (!discount) form.setFieldValue("discount", 0);
    form.setFieldValue("total", productSnapshot.price);
    form.setFieldValue("netTotal", productSnapshot.price - discount);
    // form.setFieldValue("payment", productSnapshot.price - discount);
    // if (payLater) {
    //   form.setFieldValue("payment", 0);
    //   form.setFieldValue("payLater", true);
    // } else {
    //   form.setFieldValue("payment", productSnapshot.price - discount);
    //   form.setFieldValue("payLater", false);
    // }
  }, [product, discount]);

  const bundleProducts =
    product?.bundleProducts ?? productSnapshot?.bundleProducts ?? [];

  const activeCredit =
    form.values.client?.activeCredit ??
    form.values.clientSnapshot?.activeCredit ??
    0;
  return (
    <>
      {/* <JsonViewer src={form.values} /> */}

      {_.isEmpty(productSnapshot) && (
        <Text size="md">Please select a product</Text>
      )}

      {!_.isEmpty(productSnapshot) && (
        <Table striped>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>Product</Table.Td>
              <Table.Td>{productSnapshot.name}</Table.Td>
            </Table.Tr>
            {bundleProducts?.map((p, index) => (
              <Table.Tr key={index}>
                <Table.Td>{`\t + Bundle Product ${index + 1}`}</Table.Td>
                <Table.Td>
                  + {p.product.name} x {p.quantity}
                </Table.Td>
              </Table.Tr>
            ))}
            <Table.Tr>
              <Table.Td>Total Price</Table.Td>
              <Table.Td>{cellRender.Currency(total)}</Table.Td>
            </Table.Tr>
            {productSnapshot?.creditNeed > 0 && (
              <Table.Tr>
                <Table.Td>Credit Need</Table.Td>
                <Table.Td>
                  {` ${cellRender.NumberFormat(productSnapshot?.creditNeed)} `}
                </Table.Td>
              </Table.Tr>
            )}
            <Table.Tr>
              <Table.Td>Discount</Table.Td>
              <Table.Td>
                <NumberInput
                  {...form.getInputProps("discount")}
                  size="xs"
                  prefix="$"
                  precision={2}
                  thousandSeparator=","
                />
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Net Total</Table.Td>
              <Table.Td>{cellRender.Currency(netTotal)}</Table.Td>
            </Table.Tr>

            <Table.Tr>
              <Table.Td>Payment Received</Table.Td>
              <Table.Td>
                <NumberInput
                  {...form.getInputProps("payment")}
                  size="xs"
                  prefix="$"
                  precision={2}
                  thousandSeparator=","
                />
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Payment Method</Table.Td>
              <Table.Td>
                <Select
                  {...form.getInputProps("paymentMethod")}
                  data={DATA_PAYMENT_METHODS}
                />
              </Table.Td>
            </Table.Tr>
            {/* <Table.Tr>
              <Table.Td>Payment Date2 </Table.Td>
              <Table.Td>
                <DateInput {...form.getInputProps("paymentDate")} />
              </Table.Td>
            </Table.Tr> */}

            <Table.Tr>
              <Table.Td>Payment Status</Table.Td>
              <Table.Td>
                <Select
                  {...form.getInputProps("status")}
                  data={DATA_PAYMENT_STATUS}
                />
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Payment Remark</Table.Td>
              <Table.Td>
                <Textarea {...form.getInputProps("paymentRemark")} />
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      )}
    </>
  );
};

export default OrderPayment;
