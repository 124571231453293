import {
  Button,
  Container,
  Group,
  Modal,
  NumberInput,
  Stack,
  Flex,
} from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/useServerApi";

import QuotationConfirmButton from "./quotationConfirmButton";
import { FormSectionCard } from "../layout/page";
import { useDisclosure } from "@mantine/hooks";
import { DateInput, DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import JsonViewer from "./common/jsonViewer";

const QuotationCopyToInvoice = ({ mainForm }) => {
  const quotation = mainForm.values;
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [api] = useServerApi();

  const form = useForm({
    initialValues: {
      quotationId: quotation._id,
      percentage: 100,
      amount: quotation.total,
      invDate: new Date(),
      dueDate: moment().add(14, "days").toDate(),
      paymentPercentage: 100,
    },
  });

  const changePercentage = (v) => {
    form.setFieldValue("paymentPercentage", v);
    form.setFieldValue("amount", (quotation.total * v) / 100);
  };

  const changeAmount = (v) => {
    form.setFieldValue("amount", v);
    form.setFieldValue("paymentPercentage", (v / quotation.total) * 100);
  };

  const copyToInvoice = async () => {
    try {
      const result = await api.Invoice.copyFromQuotation(form.values);
      console.log("copyToInvoice", result);
      close();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        opened={modalOpened}
        title="Copy to Invoice"
        size={"lg"}
        onClose={close}
      >
        {/* <Modal.Body>
          <p>Are you sure to copy this quotation to invoice?</p>
        </Modal.Body> */}
        {/* <JsonViewer src={form.values} /> */}

        <Stack justify="space-between">
          <Flex direction="column" mb={"md"} gap={"md"}>
            <Group grow>
              <DateInput
                label="Invoice Date"
                {...form.getInputProps("invDate")}
              />
              <DateInput label="Due Date" {...form.getInputProps("dueDate")} />
            </Group>
            <Group grow>
              <NumberInput
                label="Percentage"
                suffix="%"
                value={_.get(form.values, "paymentPercentage")}
                onChange={changePercentage}
                min={0.1}
                decimalScale={2}
              />
              <NumberInput
                label="Amount"
                prefix="$"
                value={_.get(form.values, "amount")}
                thousandSeparator=","
                onChange={changeAmount}
                decimalScale={2}
              />
            </Group>
          </Flex>
          <Group justify="right" gap={5}>
            <Button size="xs" variant="default" onClick={close}>
              Cancel
            </Button>
            <Button size="xs" onClick={copyToInvoice}>
              Confirm
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Button fullWidth onClick={open} variant="default">
        Copy to Invoice
      </Button>
    </>
  );
};

const QuotationPrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;
      await mainForm.save();

      setPrinting(true);
      const result = await api.Quotation.print(_id);
      setPrinting(false);
      console.log(result);

      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      if (result.data.quotation.status)
        mainForm.setFieldValue("status", result.data.quotation.status);
    } catch (error) {
      console.log(error);
      setPrinting(false);
    }
  };

  return (
    <Button
      fullWidth
      onClick={() => print()}
      loading={printing}
      variant="default"
    >
      {printing ? "Printing" : "Print"}
    </Button>
  );
};

const OuotationTerminateButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const terminate = () => {
    if (window.confirm("Are you sure to cancel this quotation?")) {
      mainForm.updateAndSave({ status: "CANCEL" });
    }
  };

  return (
    <Button
      fullWidth
      color={"red"}
      onClick={() => terminate()}
      variant="default"
    >
      Cancel
    </Button>
  );
};

const QuotationActionList = ({ form, role }) => {
  const { status, code, consultants, _id } = form.values;

  const showConfirm = true;
  const showPrint = true;
  const showTerminate = status !== "CANCEL";
  const showCopyInvoice = status === "CONFIRM";

  return (
    <>
      <FormSectionCard title={"Actions"}>
        <Group mt={10}>
          {showPrint && <QuotationPrintButton mainForm={form} />}
          {showConfirm && <QuotationConfirmButton mainForm={form} />}
          {showTerminate && <OuotationTerminateButton mainForm={form} />}
          {showCopyInvoice && <QuotationCopyToInvoice mainForm={form} />}
        </Group>
      </FormSectionCard>
      <Group mt={10}></Group>
    </>
  );
};

export default QuotationActionList;
