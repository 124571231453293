import React, { useState, useRef } from "react";
import {
  Avatar,
  FileButton,
  Button,
  Text,
  ActionIcon,
  Group,
  Stack,
  Image,
  Center,
  InputWrapper,
} from "@mantine/core";
import _ from "lodash";
import { useServerApi } from "../hooks/useServerApi";
import { showNotification } from "@mantine/notifications";
import {
  IconExclamationMark,
  IconPhone,
  IconTrash,
  IconPhoto,
} from "@tabler/icons-react";

const ImagePicker = ({
  form: mainForm,
  name,
  size = 120,
  label,
  updateAndSave = true,
  useAvatar = true,
}) => {
  const image = _.get(mainForm.values, name);
  const [api] = useServerApi();
  const ref = useRef();

  const handleUpload = async (file) => {
    // console.log("imagePicker", file);
    let result = await api.uploadFile("images", file);
    let { data, success } = result;

    if (success) {
      if (updateAndSave) mainForm.updateAndSave({ [name]: data.link ?? "" });
      else mainForm.setFieldValue(name, data.link ?? "");
    } else {
      showNotification({
        title: `${file.name} upload unsuccessful`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: "Upload Fail",
      });
    }
  };

  const handleClear = () => {
    mainForm.setFieldValue(name, "");
  };

  return (
    <>
      {/* <ReactJson src={ref.current} collapsed style={{ background: "white" }} /> */}
      {label && <InputWrapper label={label} />}
      {useAvatar && (
        <Center>
          <FileButton onChange={handleUpload} accept="image/png,image/jpeg">
            {(props) => (
              <>
                <Avatar
                  mt={10}
                  //   w={}
                  src={
                    image ? `${process.env.REACT_APP_FILE_URL}/${image}` : null
                  }
                  size={size}
                  {...props}
                >
                  <IconPhoto />
                </Avatar>
              </>
            )}
          </FileButton>
        </Center>
      )}

      {!useAvatar && (
        <FileButton onChange={handleUpload} accept="image/png,image/jpeg">
          {(props) => (
            <>
              <Image
                h={200}
                w="auto"
                fit="contain"
                src={
                  image ? `${process.env.REACT_APP_FILE_URL}/${image}` : null
                }
                fallbackSrc="https://placehold.co/300x200?text="
                {...props}
              />
            </>
          )}
        </FileButton>
      )}

      {/* <Center> */}
      {image && (
        <Group justify="right" mr={30} mt={10}>
          <ActionIcon variant="default" onClick={handleClear}>
            <IconTrash size={16} color="#aaa" />
          </ActionIcon>
        </Group>
      )}
      {/* </Center> */}
    </>
  );
};

export default ImagePicker;
