import {
  Group,
  Paper,
  Space,
  Text,
  SimpleGrid,
  UnstyledButton,
  Accordion,
  Center,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/useServerApi";

import _ from "lodash";

import {
  IconCircleCheck,
  IconNotes,
  IconReceipt2,
  IconSquareDot,
  IconInfoCircle,
} from "@tabler/icons-react";

import CardStat from "./cardStat";
import { useFormatter } from "../hooks/useFomatter";

const PayrollStat = ({ form, name, cols = 5, ...props }) => {
  const [formatter] = useFormatter();

  const {
    totalAmount,
    code,
    totalCommission,
    payments,
    paid,
    status,
    commissions,
  } = form.values;

  const stats = [
    {
      icon: IconNotes,
      color: "#00b341",
      label: "Payroll Number",
      msg: code,
    },
    {
      icon: IconReceipt2,
      color: "#8278F2",
      label: "Total Amount",
      msg: formatter.currency(totalAmount ?? 0),
    },
    {
      icon: IconSquareDot,
      color: "#ffec00",
      label: "Commissions",
      msg: commissions?.length ?? 0,
    },
    {
      icon: IconCircleCheck,
      color: "#00b341",
      label: "Total Payments",
      msg: payments?.length ?? 0,
    },

    {
      icon: IconInfoCircle,
      color: "#00abfb",
      label: "Status",
      msg: status,
    },
  ];

  return (
    <>
      {/* <ReactJson src={form.values} style={{ background: "white" }} /> */}
      <SimpleGrid
        cols={cols}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "md", cols: 2 },
        ]}
        {...props}
      >
        {stats?.map((stat, index) => (
          <CardStat key={index} stat={stat} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default PayrollStat;
