import { SimpleGrid, Text, Title, Group, Badge } from "@mantine/core";
import React from "react";
import _ from "lodash";
const FieldDisplay = ({
  title,
  titleTextSize = "sm",
  dataTextSize = "sm",
  value,
  render,
  dataFontWeight,
}) => (
  <Group justify="space-between">
    <Text size={titleTextSize}>{title}</Text>
    {_.isFunction(render) && render()}
    {value && (
      <Text size={dataTextSize} weight={dataFontWeight}>
        {value}
      </Text>
    )}
    {_.isNull(value) && !render && <Text size={"xs"}>{"-"}</Text>}
  </Group>
);

const InfoRowList = ({
  fields,
  cols = 3,
  spacing = "md",
  titleTextSize = "xs",
  dataTextSize = "sm",
  values,
  dataFontWeight = 600,
  ...props
}) => {
  return (
    !_.isEmpty(fields) &&
    values &&
    fields?.map(({ title, value, render, array }, index) => (
      <FieldDisplay
        key={index}
        title={title}
        value={value}
        render={render}
        dataFontWeight={dataFontWeight}
        titleTextSize={titleTextSize}
        dataTextSize={dataTextSize}
      />
    ))
  );
};

export default InfoRowList;
