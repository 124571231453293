import _ from "lodash";
import { Text } from "@mantine/core";

import { useForceUpdate } from "@mantine/hooks";
import { useServerApi } from "../hooks/useServerApi";
import { PageList } from "./pageList";
import { useCellRender } from "../hooks/useCellRender";
import moment from "moment";

const ClientPayrollPaymentList = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();
  const clientId = _.get(form.values, "_id");
  const [api] = useServerApi();

  const [cellRender] = useCellRender();
  // const fetch = async () => {
  //   try{
  //     const result = await api.sear

  //   }catch(error){
  //     console.log("Error in OrderCreditList fetch", error);

  //   }
  // }

  const tableSchema = {
    showActionColumn: false,
    searchableFields: ["name", "code"],

    columns: [
      {
        field: "payroll",
        headerName: "Payroll",
        sortable: true,
        cellRender: (col, row) => (
          <span>
            {row.payroll?.code}

            <Text c="dimmed" size="xs">
              {moment(row.payroll?.date).format("LL")}
            </Text>
          </span>
        ),
      },
      {
        field: "client",
        headerName: "To",
        sortable: true,
        cellRender: (col, row) => cellRender.Client(row.client),
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
      },

      {
        field: "paymentMethod",
        headerName: "Payment Method",
        sortable: true,
      },

      {
        field: "total",
        headerName: "Total",
        sortable: true,
        cellRender: (col, row) => cellRender.Currency(row.total),
      },
    ],
  };

  return (
    clientId && (
      <PageList
        apiEntity="payrollPayment"
        preQuery={{ client: clientId }}
        preQueryNotEmpty={true}
        tableSchema={tableSchema}
        hideSearchBar={true}
        hideActionButton={true}
        withPaper={false}
        pt={0}
        px={0}
      />
    )
  );
};

export default ClientPayrollPaymentList;
