import React from "react";
import { Page, FormSectionCard } from "../layout/page";
import { Grid, SimpleGrid } from "@mantine/core";
import DashboardManagement from "../components/dashboard/management";

const Home = ({}) => {
  const mt = 50;
  return (
    <Page title={"Welcome"}>
      <DashboardManagement />
    </Page>
  );
};

export default Home;
