import React from "react";
import {
  TextInput,
  Grid,
  Select,
  Group,
  Checkbox,
  NumberInput,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { DATA_ITEM_ASSIGN_METHOD } from "../data/options";
import OrderList from "./orderList";
import { AssignTeamMemberRow } from "./AssignTeamMemberRow";

export const TeamMemberAssign = ({ form, name }) => {
  return (
    <OrderList
      form={form}
      name={name}
      row={{
        component: AssignTeamMemberRow,
      }}
      defaultRowValue={{ user: null, quantity: 1 }}
    />
  );
};
