import { Select, TextInput, Textarea } from "@mantine/core";
import { ENUM_FORM_DISPLAY } from "../../components/pageList";
import AutoCodeInput from "../../components/autoCodeInput";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";

export const ProductCategoryFormSchema = {
  title: "Product Category",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "productCategory",

  initialValues: {
    code: "",
    name: "",
    cname: "",
  },

  layout: {
    fields: [
      {
        name: "code",
        component: TextInput,
        props: {
          required: true,
          label: "Code",
          placeholder: "",
        },
      },
      {
        name: "name",
        component: TextInput,
        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },

      {
        name: "description",
        component: Textarea,
        props: {
          // required: true,
          label: "Description",
          placeholder: "",
          mb: "xl",
        },
      },
    ],
  },
};
