import { Button } from "@mantine/core";
import React from "react";
import { useFormRender } from "../hooks/useFormRender";

import { IconEditCircle, IconSettings } from "@tabler/icons-react";

import _ from "lodash";
import { useFormSchema } from "../hooks/useFormSchema";

const apiEntity = "item";

const ItemBasicEditButton = ({ mainForm }) => {
  const [getFormSchema] = useFormSchema();
  const formSchema = getFormSchema("item").add;
  formSchema.title = "Edit";

  const onSubmit = async ({ values, formMode }) => {
    await mainForm.saveValues(values);
    mainForm.reload();
    formAction.close();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {JSON.stringify(mainForm.values.termination)} */}
      <Button
        onClick={handleActionClick}
        size="xs"
        leftSection={<IconEditCircle size={14} />}
        variant="default"
      >
        Edit
      </Button>
      {renderForm()}
    </>
  );
};

export default ItemBasicEditButton;
