import React, { useState } from "react";
import {
  Checkbox,
  TextInput,
  Text,
  Group,
  Grid,
  InputWrapper,
  Box,
} from "@mantine/core";
import _ from "lodash";

const AutoCodeInput = ({
  form,
  name,
  formMode,
  label,
  placeholder,
  showAsInput = false,
  ...props
}) => {
  const [auto, setAuto] = useState(true);

  const code = _.get(form.values, name);

  const handleCheckAuto = (e) => {
    setAuto(e.currentTarget.checked);
    form.setFieldValue();
  };

  return (
    <>
      {formMode != "add" && !showAsInput && (
        <InputWrapper label={label} mb="xs">
          <Text> {code ?? "---"}</Text>
        </InputWrapper>
      )}
      {formMode != "add" && showAsInput && (
        <TextInput
          value={code}
          label={label}
          disabled={auto}
          placeholder={placeholder}
        ></TextInput>
      )}
      {formMode === "add" && (
        <Grid align="center">
          <Grid.Col span={10}>
            <TextInput
              value={code}
              label={label}
              disabled={auto}
              placeholder={placeholder}
              w={"95%"}
            ></TextInput>
          </Grid.Col>
          <Grid.Col span={2}>
            <Checkbox
              label="Auto"
              onChange={handleCheckAuto}
              checked={auto}
              mt={20}
            />
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

export default AutoCodeInput;
