import React, { useEffect, useState } from "react";

import {
  Text,
  TextInput,
  Select,
  Checkbox,
  Button,
  Group,
  InputWrapper,
  NumberInput,
  Divider,
  Title,
  Card,
  Textarea,
  Table,
} from "@mantine/core";

import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
import { useCellRender } from "../hooks/useCellRender";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import {
  DATA_PAYMENT_METHODS,
  DATA_PAYMENT_STATUS,
  DATA_BANK,
  DATA_BANK_WITH_CODE,
} from "../data/options";

const ClientBankDetail = ({ form }) => {
  const { bankInfo } = form.values;
  const [cellRender] = useCellRender();

  if (!bankInfo) {
    form.setFieldValue("bankInfo", {
      bankName: "",
      bankAccount: "",
      remark: "",
    });
  }
  return (
    <>
      <Table striped>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>Payment Method</Table.Td>
            <Table.Td>
              <Select
                {...form.getInputProps("paymentMethod")}
                data={DATA_PAYMENT_METHODS}
              />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Bank</Table.Td>
            <Table.Td>
              <Select
                {...form.getInputProps("bankInfo.bankName")}
                data={DATA_BANK_WITH_CODE}
                searchable
                clearable
              />
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td>Name on Account</Table.Td>
            <Table.Td>
              <TextInput {...form.getInputProps("bankInfo.nameOnAccount")} />
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td>Account Number</Table.Td>
            <Table.Td>
              <TextInput {...form.getInputProps("bankInfo.bankAccount")} />
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td>Remark</Table.Td>
            <Table.Td>
              <Textarea {...form.getInputProps("bankInfo.remark")} />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  );
};

export default ClientBankDetail;
