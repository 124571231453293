import React, { useState, useEffect } from "react";
import { useServerApi } from "../hooks/useServerApi";
import { Chip, Text, Button, Box, Tabs, Group } from "@mantine/core";
import JsonViewer from "./common/jsonViewer";
import { IconCheck } from "@tabler/icons-react";
import _ from "lodash";

const ProductButton = ({ product, setProduct, isSelected }) => {
  return (
    <Box mb="xs">
      <Button
        fullWidth={true}
        onClick={() => {
          setProduct(product);
        }}
        color={isSelected(product._id) ? "teal" : "gray"}
      >
        {isSelected(product._id) && <IconCheck size={20} mr="xl" />}
        {product.name}
      </Button>
    </Box>
  );
};

const ProductList = ({ form, name }) => {
  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [api] = useServerApi();

  const fetchProducts = async () => {
    try {
      const data = await api.search({
        apiEntity: "product",
        pageSize: 1000,
        searchQuery: {
          status: "ACTIVE",
          // isEShop: false,
        },
      });

      //   console.log(data.docs);
      setProducts(data.docs);
    } catch (error) {
      //   console.log(error);
      setProducts([]);
    }
  };

  const fetchProductCategories = async () => {
    try {
      const data = await api.search({
        apiEntity: "productCategory",
        pageSize: 1000,
      });

      //   console.log(data.docs);
      setProductCategories(data.docs);
    } catch (error) {
      //   console.log(error);
      setProductCategories([]);
    }
  };

  useEffect(() => {
    fetchProductCategories();
    fetchProducts();
  }, []);

  useEffect(() => {
    // if (products.length > 0 && form.values.product) {
    //   setProduct(form.values.product);
    // }
  }, [products, form.values.product]);

  const setProduct = (p) => {
    form.setFieldValue("productSnapshot", p);
    form.setFieldValue("product", p._id);
  };

  const productId = form.values.product?._id ?? form.values.product;
  const categoryID =
    form.values.product?.category?._id ?? form.values.product?.category;
  const isSelected = (id) => id === productId;

  const tabRef = React.createRef();

  return (
    <>
      {/* categoryId : {categoryID} */}
      {/* <JsonViewer src={products} name={"products"} /> */}
      {/* <JsonViewer src={productCategories} name={"cat"} /> */}
      <Tabs defaultValue={"product"}>
        <Tabs.List>
          <Tabs.Tab value={"product"}>Products</Tabs.Tab>
          <Tabs.Tab value={"service"}>Services</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel key={"product"} value={"product"} pt={"md"}>
          {products
            .filter((p) => p.category?.name !== "Service")
            .map((product) => (
              <ProductButton
                product={product}
                isSelected={isSelected}
                product={product}
                setProduct={setProduct}
              />
            ))}
        </Tabs.Panel>

        <Tabs.Panel key={"service"} value={"service"} pt={"md"}>
          {products
            .filter((p) => p.category?.name == "Service")
            .map((product) => (
              <ProductButton
                product={product}
                isSelected={isSelected}
                product={product}
                setProduct={setProduct}
              />
            ))}
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default ProductList;
