import { TextInput, Select, Textarea, NumberInput } from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import remoteSelect2 from "../../components/remoteSelect2";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";
import ActivityLog from "../../components/activityLog";
import FileList from "../../components/fileList";
import ItemBasicInfo from "../../components/itemBasicInfo";
import ItemInventory from "../../components/itemInventory";
import CalendarChart from "../../components/chartWidget/CalendarChart";
import ItemTransactionHistory from "../../components/itemTransactionHistory";

const apiEntity = "item";
const initialValues = {
  dimensions: {
    width: 0,
    depth: 0,
    height: 0,
  },
};

export const addForm = {
  name: "addSchema",
  title: "New Item",
  description: "Please follow the step to create a New Item",
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    position: "right",
  },

  apiEntity: "item",
  initialValues,

  layout: {
    containers: [],
    fields: [
      {
        name: "code",
        component: TextInput,
        props: {
          required: true,
          label: "Code",
          placeholder: "",
        },
      },
      {
        name: "name",
        component: TextInput,
        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "category",
        component: remoteSelect2,
        props: {
          label: "Item Category",
          apiEntity: "itemCategory",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "cname",
        component: TextInput,
        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },
      {
        name: "unitsOfMeasure",
        component: Select,
        props: {
          required: true,
          label: "Unit of Measure",
          placeholder: "",
          data: DATA_UNIT_OF_MEASURE,
          searchable: true,
          clearable: true,
        },
      },

      {
        name: "dimensions.width",
        component: NumberInput,
        props: {
          label: "Width",
        },
      },
      {
        name: "dimensions.depth",
        component: NumberInput,
        props: {
          label: "Depth",
        },
      },
      {
        name: "dimensions.height",
        component: NumberInput,
        props: {
          label: "Height",
        },
      },

      {
        name: "description",
        component: Textarea,
        props: {
          // required: true,
          label: "Description",
          placeholder: "",
          mb: "xl",
        },
      },
    ],
  },
};

export const editForm = {
  name: "editSchema",
  title: "Item",
  titleField: "name",
  description: "Please follow the step to create a New Item",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
          },

          {
            key: "tab-transcation",
            label: "Transaction",
          },

          {
            key: "tab-files",
            label: "Files",
          },
          {
            key: "tab-logs",
            label: "Logs",
          },
        ],
      },
      {
        key: "section-basic",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Item Info",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },
      {
        key: "section-inventory",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Inventory",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },

      {
        key: "section-transaction",
        parent: "tab-transcation",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Transcation Records",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },

      {
        key: "section-files",
        parent: "tab-files",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },
      {
        key: "section-log",
        parent: "tab-logs",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "overview",
        component: ItemBasicInfo,
        parent: "section-basic",
        props: {},
      },
      {
        name: "inventory",
        component: ItemInventory,
        parent: "section-inventory",
        props: {},
      },
      {
        name: "inventory",
        component: CalendarChart,
        parent: "tab-overview",
        props: {
          title: "Item Reservation",
          height: "35vh",
          p: 0,
          chartProps: {
            direction: "horizontal",
            monthSpacing: 25,
          },
        },
      },

      {
        name: "transaction",
        component: ItemTransactionHistory,
        parent: "section-transaction",
        props: {},
      },

      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },
    ],
  },
};

export const formSchema = {
  add: addForm,
  edit: editForm,
};
