import {
  Text,
  TextInput,
  Select,
  Chip,
  Checkbox,
  Badge,
  NumberInput,
  Textarea,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState, useEffect } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import RemoteSelect2 from "../../components/remoteSelect2";
import OrderList from "../../components/orderList";
import PromotionRuleRow from "../../components/promotionRuleRow";

const apiEntity = "position";
const initialValues = {
  promotions: [],
};

export const PositionFormSchema = {
  title: "Position",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
  },
  apiEntity,
  initialValues,

  showSaveBar: false,

  validate: {},

  layout: {
    containers: [
      {
        key: "section-basicInfo",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          description: "Please fill in the basic info of position",
        },
      },

      {
        key: "section-promotions",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Promotions",
          description: "Please fill in the basic info of position",
        },
      },
    ],

    fields: [
      {
        name: "code",
        component: TextInput,
        parent: "section-basicInfo",
        props: {
          required: true,
          placeholder: "Code",
          label: "Code",
        },
      },
      {
        name: "name",
        component: TextInput,
        parent: "section-basicInfo",
        props: {
          required: true,
          placeholder: "Position Name",
          label: "Position Name",
        },
      },
      {
        name: "level",
        component: NumberInput,
        parent: "section-basicInfo",
        props: {
          required: true,
          placeholder: "Level",
          label: "Level",
          default: 0,
        },
      },
      {
        name: "description",
        component: Textarea,
        parent: "section-basicInfo",
        props: {
          placeholder: "description",
          label: "Description",
        },
      },
      // {
      //   name: "upperPosition",
      //   component: RemoteSelect2,
      //   parent: "section-promotions",
      //   props: {
      //     label: "Upper Position",
      //     apiEntity: "position",
      //     placeholder: "Upper Position",

      //     labelField: "name",
      //     valueField: "_id",
      //     filter: {
      //       status: "ACTIVE",
      //     },
      //   },
      // },

      {
        name: "promotions",
        component: OrderList,
        parent: "section-promotions",
        props: {
          label: "Rules (Any)",
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: PromotionRuleRow,
          },
        },
      },
    ],
  },
};
