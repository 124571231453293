import { useCellRender } from "./useCellRender";
import ActionCellRender from "../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";
import moment from "moment";

import { UserMainTableSchema } from "../schema/table/user";
import { ClaimTableSchema } from "../schema/table/claim";
import { QuotationTableSchema } from "../schema/table/quotation";
import { InvoiceTableSchema } from "../schema/table/invoice";
import { StatementTableSchema } from "../schema/table/statement";
import { ClientTableSchema } from "../schema/table/client";
import { ProjectTableSchema } from "../schema/table/project";
import { ItemTableSchema } from "../schema/table/item";
import { ClientProjectHistoryTable } from "../schema/table/clientProjectHistory";
import { ClientInvoiceHistoryTable } from "../schema/table/clientInvoiceHistory";
import { ClientStatementHistoryTable } from "../schema/table/clientStatementHistory";
import { ItemCategorySchema } from "../schema/table/itemCategory";
import { ItemGroupSchema } from "../schema/table/itemGroup";

import { ItemTransactionSchema } from "../schema/table/itemTransaction";
import { ClaimTypeTableSchema } from "../schema/table/claimType";
import { ProjectTypeTableSchema } from "../schema/table/projectType";
//////////////
import { ProductTableSchema } from "../schema/table/product";
import { ProjectCategoryTableSchema } from "../schema/table/productCategory";
import { PositionTableSchema } from "../schema/table/position";
import { OrderTableSchema } from "../schema/table/order";
import { PayrollTableSchema } from "../schema/table/payroll";
import { PromotionTableSchema } from "../schema/table/promotion";
import { CalendarEventTableSchema } from "../schema/table/calendarEvent";

export const useTableSchema = () => {
  const [cellRender] = useCellRender();

  const getTableSchema = (name, props = {}) => {
    switch (name) {
      case "product":
        return ProductTableSchema(cellRender);
      case "productCategory":
        return ProjectCategoryTableSchema(cellRender);

      case "position":
        return PositionTableSchema(cellRender);

      case "order":
        return OrderTableSchema(cellRender);

      case "payroll":
        return PayrollTableSchema(cellRender);

      case "promotion":
        return PromotionTableSchema(cellRender);

      case "calendarEvent":
        return CalendarEventTableSchema(cellRender);

      //////////////////////
      case "user":
        return UserMainTableSchema(cellRender);
      case "claim":
        return ClaimTableSchema(cellRender);
      case "quotation":
        return QuotationTableSchema(cellRender);
      case "invoice":
        return InvoiceTableSchema(cellRender);
      case "statement":
        return StatementTableSchema(cellRender);
      case "client":
        return ClientTableSchema(cellRender);

      case "ClientProjectHistory":
        return ClientProjectHistoryTable(cellRender, props);
      case "ClientStatementHistory":
        return ClientStatementHistoryTable(cellRender, props);
      case "ClientInvoiceHistory":
        return ClientInvoiceHistoryTable(cellRender, props);
      case "project":
        return ProjectTableSchema(cellRender);
      case "item":
        return ItemTableSchema(cellRender);

      case "itemCategory":
        return ItemCategorySchema(cellRender);
      case "claimType":
        return ClaimTypeTableSchema(cellRender);
      case "itemGroup":
        return ItemGroupSchema(cellRender);

      case "itemTranscation":
        return ItemTransactionSchema(cellRender);

      case "projectType":
        return ProjectTypeTableSchema(cellRender);
    }
  };

  return [getTableSchema];
};
