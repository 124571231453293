import {
  Button,
  Container,
  Group,
  Modal,
  NumberInput,
  Stack,
  Flex,
} from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import NewTicketButton from "./newTicketButton";
import { useServerApi } from "../hooks/useServerApi";

import QuotationConfirmButton from "./quotationConfirmButton";
import { FormSectionCard } from "../layout/page";
import { useDisclosure } from "@mantine/hooks";
import { DateInput, DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import JsonViewer from "./common/jsonViewer";
import InvoicePrintButton from "./invoicePrintButton";
import InvoiceAddPaymentButton from "./invoiceAddPaymentButton";

const TerminateButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const terminate = () => {
    if (window.confirm("Are you sure to cancel this invoice?")) {
      mainForm.updateAndSave({ status: "CANCEL" });
    }
  };

  return (
    <Button
      fullWidth
      color={"red"}
      onClick={() => terminate()}
      variant="default"
    >
      Cancel
    </Button>
  );
};

const ReactiveButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const reactive = () => {
    if (window.confirm("Are you sure to reactive this invoice?")) {
      mainForm.updateAndSave({ status: "WAIT" });
    }
  };

  return (
    <Button
      fullWidth
      color={"red"}
      onClick={() => reactive()}
      variant="default"
    >
      Reactive
    </Button>
  );
};

const InvoiceActionList = ({ form, role }) => {
  const { status, code, consultants, _id } = form.values;

  const showConfirm = status !== "CANCEL";
  const showPrint = status !== "CANCEL";
  const showTerminate = status !== "CANCEL";
  const showReactive = status === "CANCEL";
  return (
    <>
      <FormSectionCard title={"Actions"}>
        <Group mt={10}>
          {showPrint && <InvoicePrintButton mainForm={form} />}
          {showConfirm && <InvoiceAddPaymentButton mainForm={form} />}
          {showTerminate && <TerminateButton mainForm={form} />}
          {showReactive && <ReactiveButton mainForm={form} />}
        </Group>
      </FormSectionCard>
      <Group mt={10}></Group>
    </>
  );
};

export default InvoiceActionList;
