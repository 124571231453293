import React, { useState, useEffect } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons-react";

import RemoteSelect2 from "../../components/remoteSelect2";

import FormConfirmation from "../../components/formConfirmation";

import ProductList from "../../components/productList";
import OrderClientSelect from "../../components/orderClientSelect";
import OrderPayment from "../../components/orderPayment";
import OrderDetail from "../../components/orderDetail";
import {
  InputWrapper,
  Select,
  Textarea,
  Text,
  NumberInput,
  Badge,
  Group,
} from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import DummyWidget from "../../components/dummyWidget";

import { currency } from "../../hooks/useFomatter";
import { DateInput } from "@mantine/dates";
import OrderList from "../../components/orderList";
import OrderOperationRow from "../../components/orderOperationRow";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog";
import OrderPaymentDetail from "../../components/orderPaymentDetail";
import OrderCommissionList from "../../components/orderCommissionList";
import OrderPaybackList from "../../components/orderPaybackList";
import OrderCreditList from "../../components/orderCreditList";
import UniqueTextInput from "../../components/uniqueTextInput";
import moment from "moment";

const apiEntity = "order";
const initialValues = {
  paymentDate: new Date(),
  orderDate: new Date(),
  paymentMethod: "PAYME", //remove later
  operationStatus: "COMPLETED", //remove later
};

// const cellRender = useCellRender();

const AddForm = {
  title: "New Order",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },

  closeAfterSave: true,
  showSaveBar: false,
  apiEntity,
  initialValues,

  validate: {},

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Product",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          { key: "t1-1", label: "Product", icon: IconSettings },
          { key: "t1-2", label: "Client", icon: IconSettings },
          { key: "t1-3", label: "Detail", icon: IconSettings },
          { key: "t1-4", label: "Payment", icon: IconSettings },
          { key: "t1-5", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-product",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Product",
          description: "Please choose type for client",
          withBorder: false,
          withPaper: false,
          ml: 10,
        },
      },

      {
        key: "section-client",
        parent: "t1-2",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Product",
          description: "Please choose type for client",
          withBorder: false,
          withPaper: false,
          ml: 10,
        },
      },
      {
        key: "section-detail",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Product",
          description: "Please choose type for client",
          withBorder: false,
          withPaper: false,
          ml: 10,
        },
      },
      {
        key: "section-payment",
        parent: "t1-4",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Product",
          description: "Please choose type for client",
          withBorder: false,
          withPaper: false,
          ml: 10,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-5",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Confirm",
          titleOrder: 3,
          description:
            "Please confirm to add client or you can go back to revise.",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },

      {
        key: "section-address",
        parent: "t1-6",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Business address",
          titleOrder: 3,
          description: "Please fill in the address info",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },
    ],

    fields: [
      {
        name: "product",
        parent: "section-product",
        component: ProductList,
        props: {},
      },
      {
        name: "client",
        component: RemoteSelect2,
        parent: "section-client",
        props: {
          label: "Client",
          apiEntity: "client",
          required: true,
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `${data.phone}  ${data.name}   `,
          onDataChange: (data, form) => {
            form.setFieldValue("clientSnapshot", data);
          },
          searchFields: ["name", "phone", "email"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "client",
        parent: "section-client",
        component: OrderClientSelect,
        props: {},
      },
      {
        name: "detail",
        parent: "section-detail",
        component: OrderDetail,
        props: {},
      },
      {
        name: "payment",
        parent: "section-payment",
        component: OrderPayment,
        props: {
          showDivider: false,
        },
      },
      {
        name: "remark",
        parent: "section-confirm",
        component: Textarea,
        props: {
          label: "Remark",
          placeholder: "Remark",
        },
      },
      // {
      //   name: "confirm",
      //   component: FormConfirmation,
      //   parent: "section-confirm",
      //   props: {},
      // },
    ],
  },
};

const EditForm = {
  title: "Edit Order",
  titleField: "code",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity,
  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Main",
          orientation: "horizontal",
          style: { minHeight: "400px" },
        },
        tab: [
          { key: "tab-main", label: "Main" },
          { key: "tab-commission", label: "Commission" },
          { key: "tab-payback", label: "Payback" },

          { key: "tab-file", label: "File" },
          { key: "tab-log", label: "Log" },
        ],
      },
      {
        key: "section-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,

        parent: "tab-main",
        props: {
          title: "Order Info",
        },
      },
      {
        key: "section-payment",
        parent: "tab-main",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Payment Details",
        },
      },
      {
        key: "section-operation",
        parent: "tab-main",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Operation Logs",
        },
      },

      {
        key: "section-commission",
        parent: "tab-commission",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Commission",
        },
      },
      {
        key: "section-credit",
        parent: "tab-commission",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Credit",
        },
      },
      {
        key: "section-payback",
        parent: "tab-payback",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Payback Orders",
        },
      },
      {
        key: "section-remark",
        parent: "tab-main",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Remark",
        },
      },

      {
        key: "section-file",
        parent: "tab-file",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
        },
      },
      {
        key: "section-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Log",
        },
      },
      {
        key: "grid-basic",
        parent: "section-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          { key: "col1", props: { span: { base: 12, md: 6, lg: 4 } } },
          { key: "col2", props: { span: { base: 12, md: 6, lg: 4 } } },
          { key: "col3", props: { span: { base: 12, md: 6, lg: 4 } } },
        ],
        props: {},
      },
    ],
    fields: [
      // {
      //   name: "code",
      //   parent: "col1",
      //   component: DummyWidget,
      //   props: {
      //     dummyRender: (data) => (
      //       <InputWrapper label="Order Code" mb="xs">
      //         <Group>
      //           <Text>{data.code} </Text>
      //           {data.isBundle && <Badge> {"Bundle"} </Badge>}
      //         </Group>
      //       </InputWrapper>
      //     ),
      //   },
      // },
      {
        name: "code",
        parent: "col1",
        component: UniqueTextInput,
        props: {
          required: true,
          uniqueEntity: "order",
          errorMessage: "Order ID is already exist.",
          label: "Order ID",
        },
      },
      {
        name: "product",
        parent: "col2",
        component: RemoteSelect2,
        props: {
          label: "Product",
          apiEntity: "product",
          required: true,
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `[ ${data.code} ] ${data.name}   `,
          searchFields: ["name", "cname"],
          onDataChange: (data, form) => {
            form.setFieldValue("productSnapshot", data);
          },
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "client",
        parent: "col3",
        component: RemoteSelect2,
        props: {
          label: "Client",
          apiEntity: "client",
          required: true,
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `  ${data.name} ( ${data.phone} )   `,
          searchFields: ["name", "phone", "email"],
          onDataChange: (data, form) => {
            form.setFieldValue("clientSnapshot", data);
            form.setFieldValue("total", data.price);
            form.setFieldValue("netTotal", data.price - form.values.discount);
          },

          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      // {
      //   name: "status",
      //   parent: "col1",
      //   component: Select,
      //   props: {
      //     label: "Payment Status",

      //     data: [
      //       { value: "DFT", label: "Draft" },
      //       { value: "CAN", label: "Cancel" },
      //       { value: "WAIT", label: "Waiting for Payment" },
      //       { value: "PAID", label: "Paid" },
      //     ],
      //   },
      // },
      {
        name: "operationStatus",
        component: Select,
        parent: "col1",
        props: {
          label: "Order Status",
          data: [
            { value: "PENDING", label: "Pending" },
            { value: "PROCESSING", label: "Processing" },
            { value: "COMPLETED", label: "Completed" },
          ],
        },
      },

      {
        name: "payment",
        parent: "section-payment",
        component: OrderPaymentDetail,
        props: {
          title: null,
          rowMb: "5",
          showDivider: false,
          showDiscount: true,
          showPayLater: false,
          showStatus: true,
        },
      },

      {
        name: "paybackOrder",
        parent: "section-payback",
        component: OrderPaybackList,
        props: {
          title: null,
          rowMb: "5",
          showDivider: false,
          showDiscount: true,
          showPayLater: false,
          showStatus: true,
        },
      },
      {
        name: "operationLog",
        parent: "section-operation",
        component: OrderList,
        props: {
          label: "",
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: OrderOperationRow,
          },
        },
      },

      {
        name: "orderDate",
        parent: "col2",
        component: DateInput,
        type: "dateInput",
        props: {
          label: "Order Date",
          clearable: true,
        },
      },
      {
        name: "paymentDate",
        parent: "col3",
        component: DateInput,
        type: "dateInput",
        props: {
          label: "Payment Date",
          clearable: true,
        },
      },

      {
        name: "remark",
        parent: "section-remark",
        component: Textarea,
        props: {
          // label: "Remark",
        },
      },

      {
        name: "files",
        parent: "section-file",
        component: FileList,
        props: {},
      },
      {
        name: "logs",
        parent: "section-log",
        component: ActivityLog,
        props: {},
      },
      {
        name: "commissions",
        parent: "section-commission",
        component: OrderCommissionList,
        props: {},
      },

      {
        name: "credits",
        parent: "section-credit",
        component: OrderCreditList,
        props: {},
      },
    ],
  },
};

export const OrderFormSchema = {
  add: AddForm,
  edit: EditForm,
};
