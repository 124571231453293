import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { Menu, Group, ActionIcon, Text, Button, Checkbox } from "@mantine/core";
import {
  IconDots,
  IconSettings,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconArrowsLeftRight,
  IconCheck,
  IconTrash,
  IconPencil,
} from "@tabler/icons-react";
// const ActionCellRender = (column, rowIndex, data, onActionBtnClick) => {
//   const defaultActionButtons = [
//     {
//       name: "edit",
//       icon: IconPencil,
//       props: {
//         //   color:"red"
//       },
//     },
//     {
//       name: "delete",
//       icon: IconTrash,
//       props: {
//         //   color:"red"
//       },
//     },
//   ];
//   const actionButtons = column.actions ?? defaultActionButtons;

//   return (
//     <Group gap={2}>
//       {/* { JSON.stringify(column)} */}
//       {renderActionButtons(actionButtons, data, onActionBtnClick, rowIndex)}
//       {renderActionMenu(column.actionMenu, data, onActionBtnClick, rowIndex)}
//     </Group>
//   );
// };

export const PromotionTableSchema = (cellRender) => {
  const renderAction = (col, index, d, onActionBtnClick) => {
    const handleOnClick = () => {
      console.log("handleOnClick", index, d);
    };
    return (
      <Button size="xs" variant="gradient" onClick={handleOnClick}>
        Confirm
      </Button>
    );
  };
  return {
    // showActionColumn: true,
    searchableFields: ["name", "code", "status", "client.name"],

    columns: [
      {
        cellRender: (col, data) => <Checkbox />,
      },
      {
        field: "client",
        headerName: "Client",
        sortable: true,
        cellRender: (col, data) => cellRender.Client(data.client),
      },

      {
        field: "toPosition",
        headerName: "To Position",
        sortable: true,
        cellRender: (col, data) => data.toPosition?.name,
      },

      {
        field: "referalCount",
        headerName: "Referal Count",
        sortable: true,
      },
      {
        field: "referalAmount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.referalAmount),
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        headerName: "Action",
        cellRender: (col, data) => renderAction(col, data),
      },
    ],
  };
};
