import _ from "lodash";
import { Group, Select, NumberInput } from "@mantine/core";
import RemoteSelect2 from "./remoteSelect2";
import { useForceUpdate } from "@mantine/hooks";

const CommissionDirectRow = ({ item, form, name, index }) => {
  const forceUpdate = useForceUpdate();

  return (
    <Group wrap="noWrap" grow>
      <RemoteSelect2
        label="Position"
        apiEntity="position"
        labelField="name"
        valueField="_id"
        labelRender={(data) => `${data.name}`}
        multiple={false}
        searchFields={["code name cname"]}
        placeholder="Position"
        clearable={true}
        value={item.content["position"]}
        onDataChange={(data) => {
          item.content["position"] = data?._id;
          forceUpdate();
        }}
        sort={{
          by: "name",
          order: "asc",
        }}
      />

      <NumberInput
        {...form.getInputProps(`${name}.${index}.commission`)}
        label="Commission"
        placeholder="Enter Quantity"
        thousandSeparator=","
        prefix="$"
        required
      />

      <NumberInput
        {...form.getInputProps(`${name}.${index}.credit`)}
        label="Credit"
        placeholder="Enter Quantity"
        thousandSeparator=","
        required
      />
    </Group>
  );
};

export default CommissionDirectRow;
