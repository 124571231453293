import JsonViewer from "./common/jsonViewer";
import { ReactP5Wrapper } from "@p5-wrapper/react";
import "to-color-string";
import _ from "lodash";
import { Badge, Table } from "@mantine/core";
import { GetSessionColorString } from "./itemsFloorPlan";

const TableBody = ({ cat }) => {
  // console.log("Cat", cat);
  // const elements = itemList.map((item, index) => ({
  //   part: index + 1,
  //   code: item.code,
  //   name: item.name,
  //   // layout: `${item.}r x ${}c`
  // }));

  const elements = cat?.map((session, index) => ({
    sessionIndex: index,
    code: session.item?.code,
    name: session.item?.name,
    quantity: session.quantity,
    layout: `${session.layout.row} x ${session.layout.col}`,
    area: session.item.area,
  }));
  const rows = elements.map((element, index) => (
    <Table.Tr key={index}>
      <Table.Td>
        {" "}
        <Badge color={GetSessionColorString(element.sessionIndex)}>
          {element.sessionIndex + 1}
        </Badge>
      </Table.Td>
      <Table.Td>{element.code}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.layout}</Table.Td>
      <Table.Td>{element.quantity}</Table.Td>
      {/* <Table.Td>{element.area}</Table.Td> */}
    </Table.Tr>
  ));

  return <Table.Tbody>{rows}</Table.Tbody>;
};
const FloorPlanLayoutTable = ({ itemGroup = {} }) => {
  const { floorPlan } = itemGroup;

  return (
    <>
      {/* <JsonViewer src={itemGroup} /> */}
      {Object.keys(floorPlan).map((catId, index) => (
        <Table key={index}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Part</Table.Th>
              <Table.Th>Item Code</Table.Th>
              <Table.Th>Item</Table.Th>
              <Table.Th>Layout (Row x Col)</Table.Th>
              <Table.Th>Quantity</Table.Th>
              {/* <Table.Th>Area</Table.Th> */}
            </Table.Tr>
          </Table.Thead>
          <TableBody cat={floorPlan[catId]} />
        </Table>
      ))}
    </>
  );
};

export default FloorPlanLayoutTable;
