import React from "react";
import { PageList } from "../components/pageList";
import { ClientAddForm, ClientEditForm } from "../schema/form/client";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
const Client = ({ title = "Client" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title={title}
      apiEntity={"client"}
      tableSchema={getTableSchema("client")}
      formSchema={getFormSchema("client")}
      showImport={true}
      initSort={{ by: "code", order: "desc" }}
      searchByTextField={true}

      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default Client;
