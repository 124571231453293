import React, { useState } from "react";
import { Button } from "@mantine/core";
import { useServerApi } from "../hooks/useServerApi";

const InvoicePrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);

  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) return;
      await mainForm.save();

      setPrinting(true);
      const result = await api.Invoice.print(_id);
      setPrinting(false);
      console.log(result);

      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      if (result.data.quotation.status)
        mainForm.setFieldValue("status", result.data.invoice.status);
    } catch (error) {
      console.log(error);
      setPrinting(false);
    }
  };

  return (
    <Button
      fullWidth
      onClick={() => print()}
      loading={printing}
      variant="default"
    >
      {printing ? "Printing" : "Print"}
    </Button>
  );
};

export default InvoicePrintButton;
