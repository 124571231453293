import { Button, Text, TextInput, Select, Textarea } from "@mantine/core";
import React from "react";
import { useFormRender } from "../hooks/useFormRender";
import { addFormSchema } from "../schema/form/user";

const UserEditButton = ({ mainForm }) => {
  let formSchema = { ...addFormSchema };
  formSchema.title = "Edit User Details";

  const onSubmit = async ({ values, formMode }) => {
    // mainForm.setValues(values);
    await mainForm.saveValues(values);
    await mainForm.reload();
    formAction.close();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  return (
    <>
      {/* {JSON.stringify(mainForm.values.termination)} */}
      <Button
        fullWidth
        onClick={handleActionClick}
        variant="light"
        color="gray"
      >
        Edit Details
      </Button>
      {renderForm()}
    </>
  );
};

export default UserEditButton;
