import JsonViewer from "./common/jsonViewer";
import { ReactP5Wrapper } from "@p5-wrapper/react";
import "to-color-string";
import _ from "lodash";
import { useComputedColorScheme } from "@mantine/core";

export const GetSessionColorString = (sessionIndex) =>
  ("e" + sessionIndex).toColorString();

const ItemsFloorPlan = ({ itemGroup = {} }) => {
  const canvasSize = { w: 720, d: 400 };
  const paddingX = 20;
  const paddingY = 30;
  const textLineHeight = 12;
  const paddingLegendSection = 20;
  const { floorPlan } = itemGroup;
  const computedColorScheme = useComputedColorScheme("dark");
  let zoomScale = 0.9;
  /**
   *
   * @param {*} p5
   * @param {*} cat
   * @param {*} startY
   */
  const drawLegend = (p5, cat, startY) => {
    p5.fill("black");
    let currentY = startY;

    cat.forEach((session, index) => {
      const { item, quantity, layout } = session;
      p5.fill(index.toColorString());
      p5.text(`${index + 1}) Item : ${item.name}`, paddingX, currentY);
      currentY += textLineHeight;
      p5.text(
        `    Layout : ${layout.row}R x  ${layout.col}C`,
        paddingX,
        currentY
      );
      currentY += textLineHeight;
      p5.text(`    Quantity : ${quantity}`, paddingX, currentY);
      currentY += paddingLegendSection;
    });

    // const items = _.groupBy(cat, "item._id");
    // Object.keys(items)?.forEach((itemKey, index) => {
    //   //   p5.text("name", paddingX, startY + index * paddingY);
    //   //   console.log("items[itemKey][0]", items[itemKey][0].item);
    //   const item = items[itemKey][0].item;
    //   const quantity = items[itemKey].reduce((a, c) => a + c.quantity, 0);

    //   p5.fill(item._id.toColorString());
    //   //   p5.rect(paddingX + item.width, startY, item.width, item.depth);

    //   p5.text(`Item : ${item.name}`, paddingX, currentY);
    //   currentY += textLineHeight;
    //   p5.text(`Quantity : ${quantity}`, paddingX, currentY);
    //   currentY += paddingLegendSection;
    // });
  };

  const normalizeLength = (w, d, ratio) => {
    return {
      w: w * ratio,
      d: d * ratio,
    };
  };

  const getDimensionRatio = (dimension) => {
    const length = Math.max(dimension.width, dimension.depth);
    return Math.min(
      2,
      (dimension.width > dimension.depth ? canvasSize.w : canvasSize.d) /
        (length / zoomScale)
    );
  };

  /**
   *
   * @param {*} p5
   * @param {*} session
   * @param {*} sessionIndex
   * @returns
   */
  const drawFloorPlanSession = (p5, session, sessionIndex, ratio) => {
    const { startX: sx, startY: sy, width, depth, row, col } = session.layout;
    const { width: itemWidth, depth: itemDepth, name } = session.item;
    const category = session.category;

    let { w: borderWidth, d: borderDepth } = normalizeLength(
      width,
      depth,
      ratio
    );
    let { w: itemW, d: itemD } = normalizeLength(itemWidth, itemDepth, ratio);
    const { w: startX, d: startY } = normalizeLength(sx, sy, ratio);

    //Draw Outline
    p5.fill(GetSessionColorString(sessionIndex));
    p5.rect(startX + paddingX, startY + paddingY, borderWidth, borderDepth);

    let endY = 0;
    //Draw division
    for (let c = 0; c < col; c++) {
      for (let r = 0; r < row; r++) {
        p5.rect(
          startX + c * itemW + paddingX,
          startY + r * itemD + paddingY,
          itemW,
          itemD
        );
        endY = startY + r * itemD + paddingY;
      }
    }
    return endY + itemD + paddingY;
  };

  /**
   * @description p5 sketch function
   * @param {*} p5
   */
  const sketch = (p5) => {
    p5.setup = () => {
      p5.createCanvas(canvasSize.w, canvasSize.d);
      p5.noLoop();
    };

    p5.draw = () => {
      p5.background(computedColorScheme === "dark" ? "black" : "#eee");
      p5.push();
      if (floorPlan) {
        // console.log(Object.keys(floorPlan));
        Object.keys(floorPlan).forEach((categoryId) => {
          const cat = floorPlan[categoryId];
          let currentY = 0;
          if (cat) {
            const ratio = getDimensionRatio(itemGroup.dimensions ?? 1);
            cat.forEach((session, sessionIndex) => {
              //   console.log("session=", sessionIndex, session);
              currentY = drawFloorPlanSession(p5, session, sessionIndex, ratio);
            });
          }
          // drawLegend(p5, cat, currentY);
        });
      }
      p5.pop();
    };
  };

  return (
    <>
      {/* <JsonViewer src={itemGroup} /> */}
      <ReactP5Wrapper sketch={sketch} />
    </>
  );
};

export default ItemsFloorPlan;
