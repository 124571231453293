import React, { useEffect } from "react";

import { DatePicker } from "@mantine/dates";
import { useFormRender } from "../hooks/useFormRender";

import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../components/pageList";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons-react";
import {
  Text,
  TextInput,
  Select,
  Checkbox,
  Button,
  Group,
} from "@mantine/core";

import {
  DATA_COUNTRY,
  DATA_EDUCATION_LEVEL,
  DATA_LANGUAGE,
  DATA_NATIONALITY,
  DATA_CLIENT_TYPES,
  DATA_GENDERS,
  DATA_MARIAGE_STATUS,
  DATA_ID_TYPE,
  DATA_SALUATATION,
} from "../data/options";

import AddressList from "../components/addressList";
import RemoteSelect2 from "../components/remoteSelect2";
import ConsultantList from "../components/consultantList";
import FormConfirmation from "../components/formConfirmation";
import UniqueTextInput from "../components/uniqueTextInput";
import Chips from "../components/chips";
import { useAuthUser } from "react-auth-kit";
import { useFormSchema } from "../hooks/useFormSchema";

const OrderClientSelect = ({ form: mainForm, name, showInput = true }) => {
  const auth = useAuthUser();
  const [getFormSchema] = useFormSchema();
  const { client } = mainForm.values;

  const formSchema = getFormSchema("client").add;

  const handleNew = () => {
    formAction.open({ mode: "add" });
  };

  const handleEdit = () => {
    formAction.open({ mode: "edit", id: client._id ?? client });
  };

  const onSubmitSuccess = ({ values }) => {
    if (!values) return;
    // console.log("onSubmitSuccess ", values);
    mainForm.setFieldValue("client", values._id);
    mainForm.setFieldValue("clientSnapshot", values);
    formAction.close();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess
  );

  return (
    <>
      {renderForm()}
      {/* {formStatus.mode} */}
      <Group>
        <Button size="xs" onClick={() => handleNew()}>
          New
        </Button>
        <Button
          size="xs"
          onClick={() => handleEdit()}
          disabled={!client}
          color="green"
        >
          Edit
        </Button>
      </Group>
    </>
  );
};

export default OrderClientSelect;
