import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  geocodeByLatLng,
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

import _ from "lodash";
import JsonViewer from "./common/jsonViewer";
import {
  Text,
  Input,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";

const LocationAutocomplete = ({
  form,
  name,
  label,
  country = ["hk"],
  language = "zh-hk",
  region = "region",
}) => {
  const location = _.get(form.values, name);
  const [value, setValue] = useState(null);
  const colorScheme = useComputedColorScheme();
  const theme = useMantineTheme();

  const handleOnChange = async (e) => {
    // console.log("location change ", e);
    setValue(e);
    if (e && e.value && e.value.place_id) {
      let result = await geocodeByPlaceId(e.value.place_id);
      if (result.length) {
        const location = result[0];
        location.label = e.label;
        location.center = {
          lat: result[0].geometry.location.lat(),
          lng: result[0].geometry.location.lng(),
        };
        delete location.geometry;
        // setValue(location);
        form.setFieldValue(name, location);
        form.setFieldValue("address", location.formatted_address);
        console.log(location);
      }
    }
  };
  return (
    <Input.Wrapper label={label}>
      {/* <JsonViewer src={value} /> */}
      {/* colorScheme :{colorScheme} */}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        apiOptions={{ language, region }}
        selectProps={{
          value,
          onChange: handleOnChange,
          theme: "dark",

          styles: {
            control: (base, state) => ({
              ...base,
              background:
                colorScheme === "dark"
                  ? "#2e2e2e"
                  : "var(--mantine-color-body)",
              borderColor: "var(--mantine-color-default-border)",
              // ? "var(--mantine-color-default-border)"
              // : "var(--mantine-color-default-border)",
            }),
            dropdownIndicator: (base) => ({
              ...base,
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-xs)",
              color: "var(--mantine-font-color)",
            }),
            indicatorSeparator: (base) => ({
              ...base,
              backgroundColor: "var(--mantine-font-color)",
            }),

            menu: (base) => ({
              ...base,
              background:
                colorScheme === "dark"
                  ? "#2e2e2e"
                  : "var(--mantine-color-body)",
              borderColor: "var(--mantine-color-default-border)",
            }),

            valueContainer: (base) => ({
              ...base,
              background:
                colorScheme === "dark"
                  ? "#2e2e2e"
                  : "var(--mantine-color-body)",
              borderColor: "var(--mantine-color-default-border)",
            }),

            input: (provided, state) => ({
              ...provided,
              color: "var(--mantine-font-color)",
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-xs)",
              borderColor: "var(--mantine-color-default-border)",
            }),
            option: (provided) => ({
              ...provided,
              color: "var(--mantine-font-color)",
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-sm)",
              borderColor: "var(--mantine-color-default-border)",
              backgroundColor:
                colorScheme === "dark"
                  ? theme.colors.dark[8]
                  : theme.colors.dark[0],
              "&:hover": {
                ...provided,
                color: "var(--mantine-font-color)",
                fontFamily: "var(--mantine-font-family)",
                fontSize: "var(--mantine-font-size-sm)",
                backgroundColor: "var(--mantine-color-default-border)",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--mantine-font-color)",
              fontFamily: "var(--mantine-font-family)",
              fontSize: "var(--mantine-font-size-sm)",
              borderColor: "var(--mantine-color-default-border)",
            }),
          },
        }}
        autocompletionRequest={{
          componentRestrictions: { country },
        }}
      />
    </Input.Wrapper>
  );
};

export default LocationAutocomplete;
