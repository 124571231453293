import React from "react";
import { Page } from "../layout/page";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { PageList } from "../components/pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";

const CalendarEvent = ({ title = "Calendar Event" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title={title}
      apiEntity={"calendarEvent"}
      tableSchema={getTableSchema("calendarEvent")}
      formSchema={getFormSchema("calendarEvent")}
      showImport={false}
      initSort={{ by: "code", order: "desc" }}
      // searchByTextField={true}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default CalendarEvent;
