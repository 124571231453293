import React from "react";
import OrderList from "./orderList";
import { AssignTeamMemberRow } from "./AssignTeamMemberRow";
import { RundownRow } from "./rundownRow";

export const RundownList = ({ form, name }) => {
  return (
    <OrderList
      form={form}
      name={name}
      row={{
        component: RundownRow,
      }}
      defaultRowValue={{ users: [], quantity: 1 }}
    />
  );
};
